import React from "react";
import { Helmet } from "react-helmet";
import Footertabmt from "../../components/footertabmt";

const Leagueyourcareerpath = () => {
  return (
    <>
      <Helmet>
        <title>Your Career Path | THE LEAGUE</title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
      </Helmet>
      <section className="dev-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 d-none d-lg-block">
              <a href="/the-league/management-trainee/" className="rthome">
                <img src="/images/theleague/innerpage-back-button-arrow.png" />
                Management Trainee
              </a>
            </div>
            <div className="col-lg-4">
              <a href="/the-league">
                <img
                  src="/images/theleague/the-league-logo.png"
                  className="img-fluid d-block mx-auto"
                />
              </a>
            </div>
            <div className="col-lg-4 d-none d-lg-block text-end">
              <a href="/the-league/" className="rthome">
                Return to Home
                <img src="/images/theleague/innerpage-forward-button-arrow.png" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="im-banner text-center">
                <h2>Your Career Path</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>
                The League Management Trainee Programme is an 18 month career
                development framework for incoming graduates. The programme is
                designed to instill in our Trainees the skills, knowledge,
                confidence and experiences needed to take ownership of their
                careers.
              </p>
              <p>
                The following are the core aspects to your development as a part
                of The League Management Trainee Programme. Use the tabs to
                explore what each entails.
              </p>
            </div>
          </div>

          <div className="col mb-5">
            <div className="leagueacc accordion" id="yourroleacc">
              <div className="accordion-item">
                <h2 className="accordion-header" id="ha">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#ca"
                    aria-expanded="false"
                    aria-controls="ca"
                  >
                    Professional Development
                  </button>
                </h2>
                <div
                  id="ca"
                  className="accordion-collapse collapse"
                  aria-labelledby="ha"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      As trainees you will be provided with broad exposure to
                      the operations of the bank, its products and services, and
                      the local banking industry. Within the programme, you will
                      be supported by the bank in obtaining the relevant
                      certifications in Banking and Financial Services for
                      Accredited Banking Practitioners.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hb">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cb"
                    aria-expanded="false"
                    aria-controls="cb"
                  >
                    Customized Learning & Development Plan
                  </button>
                </h2>
                <div
                  id="cb"
                  className="accordion-collapse collapse"
                  aria-labelledby="hb"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      At the end of your classroom assignment, you will receive
                      intensive training in HBL’s core functional units through
                      short-term job attachments/rotations, relevant to the
                      field of your choice.
                    </p>
                    <p>
                      You will be rotated in relevant departments of the bank to
                      gain in-depth business / function knowledge before you are
                      placed in your department of hiring.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cc"
                    aria-expanded="false"
                    aria-controls="cc"
                  >
                    Mentorship Programme
                  </button>
                </h2>
                <div
                  id="cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="hc"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      Management Trainees will be assigned a Mentor from senior
                      management at HBL who will work with them to create
                      self-awareness, help them take steps towards realizing
                      their true potential and fostering support. The aim of the
                      program is that the Mentor and Mentee form a bond which
                      enables the Trainees to function on a level above their
                      own expectations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hd">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cd"
                    aria-expanded="false"
                    aria-controls="cd"
                  >
                    Buddy Programme
                  </button>
                </h2>
                <div
                  id="cd"
                  className="accordion-collapse collapse"
                  aria-labelledby="hd"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      Management Trainees will be connected to MTs from previous
                      years to be able to share experiences, find guidance /
                      advice and to be able to navigate through the program.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="he">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#ce"
                    aria-expanded="false"
                    aria-controls="ce"
                  >
                    Career Orientation Mapping
                  </button>
                </h2>
                <div
                  id="ce"
                  className="accordion-collapse collapse"
                  aria-labelledby="he"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      A career orientation review will be conducted at the end
                      of the Management Trainee Programme to map your strengths
                      with corporate manning needs.
                    </p>
                    <p>
                      Through assessing your aptitude, capabilities and
                      potential, this review will serve as a basis for planning
                      appropriate job/department attachments for you and will
                      pave the way for future job placements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex d-lg-none text-center my-3">
            <div className="col">
              <a href="/the-league/management-trainee/" className="rthome">
                <img src="/images/theleague/innerpage-back-button-arrow.png" />
                Management Trainee
              </a>
            </div>
            <div className="col ">
              <a href="/the-league/" className="rthome">
                Return to Home
                <img src="/images/theleague/innerpage-forward-button-arrow.png" />
              </a>
            </div>
          </div>
        </div>
        <Footertabmt />
      </section>
    </>
  );
};

export default Leagueyourcareerpath;
