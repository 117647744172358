const Campdata = [
    {
        id: "42",
        imgsrc: "/images/theleague/mt/journey/42.jpg",
        title: "IBA Campus Drive 2022",
        link: "/the-league/iba-campus-drive-2022/",
    },
    {
        id: "41",
        imgsrc: "/images/theleague/mt/journey/41.jpg",
        title: "The League 2021 Meet & Greet",
        link: "/the-league/the-league-2021-meet-greet",
    },
    {
        id: "40",
        imgsrc: "/images/theleague/mt/journey/40.jpg",
        title: "Cohort 2021 Karachi Get-together",
        link: "/the-league/cohort-2021-karachi-get-together",
    },
    {
        id: "39",
        imgsrc: "/images/theleague/mt/journey/39.jpg",
        title: "MT and Accelerate Graduate Orientation 2021",
        link: "/the-league/mt-and-accelerate-graduate-orientation-2021",
    },
    {
        id: "38",
        imgsrc: "/images/theleague/mt/journey/38.jpg",
        title: "IoBM campus drive 2020",
        link: "/the-league/iobm-campus-drive-2020",
    },
    {
        id: "37",
        imgsrc: "/images/theleague/mt/journey/37.jpg",
        title: "IBA Campus Drive 2020",
        link: "/the-league/iba-campus-drive-2020",
    },
    {
        id: "36",
        imgsrc: "/images/theleague/mt/journey/36.jpg",
        title: "IoBm Career Fair 2020",
        link: "/the-league/iobm-career-fair-2020",
    },
    {
        id: "35",
        imgsrc: "/images/theleague/mt/journey/35.jpg",
        title: "IBA Career Fair 2020",
        link: "/the-league/iba-career-fair-2020",
    },
    {
        id: "34",
        imgsrc: "/images/theleague/mt/journey/34.jpg",
        title: "NUST Campus Drive 2020",
        link: "/the-league/nust-campus-drive",
    },
    {
        id: "33",
        imgsrc: "/images/theleague/mt/journey/33.jpg",
        title: "LUMS Campus Drive 2020",
        link: "/the-league/lums-campus-drive-2020",
    },
    {
        id: "32",
        imgsrc: "/images/theleague/mt/journey/32.jpg",
        title: "LSE Campus Drive 2020",
        link: "/the-league/lse-campus-drive-2020",
    },
    {
        id: "31",
        imgsrc: "/images/theleague/mt/journey/31.jpg",
        title: "Habib University Campus Drive 2020",
        link: "/the-league/habib-university-campus-drive-2020",
    },
    {
        id: "30",
        imgsrc: "/images/theleague/mt/journey/30.jpg",
        title: "GIK Campus Drive 2020",
        link: "/the-league/gik-campus-drive-2020",
    },
    {
        id: "29",
        imgsrc: "/images/theleague/mt/journey/29.jpg",
        title: "The League Orientation Ceremony 2019",
        link: "/the-league/the-league-orientation-ceremony-2019",
    },
    {
        id: "28",
        imgsrc: "/images/theleague/mt/journey/28.jpg",
        title: "The League Team Building Bootcamp 2019",
        link: "/the-league/the-league-team-building-bootcamp-2019",
    },
    {
        id: "27",
        imgsrc: "/images/theleague/mt/journey/27.jpg",
        title: "LSE Campus Drive 2019",
        link: "/the-league/lse-campus-drive-2019",
    },
    {
        id: "26",
        imgsrc: "/images/theleague/mt/journey/26.jpg",
        title: "NUST Campus Drive 2019",
        link: "/the-league/nust-campus-drive-2019",
    },
    {
        id: "25",
        imgsrc: "/images/theleague/mt/journey/25.jpg",
        title: "LUMS Campus Drive 2019",
        link: "/the-league/lums-campus-drive-2019",
    },
    {
        id: "24",
        imgsrc: "/images/theleague/mt/journey/24.jpg",
        title: "Assessment Centre Karachi 2019",
        link: "/the-league/assessment-centre-karachi-2019",
    },
    {
        id: "23",
        imgsrc: "/images/theleague/mt/journey/23.jpg",
        title: "Assessment Centre Lahore 2019",
        link: "/the-league/assessment-centre-lahore-2019",
    },
];

export default Campdata;