import React from 'react'
import { Helmet } from "react-helmet";
import Testimonails from "../components/Testimonials";
import Functionfilter from "../components/Functionfilter";

const Experienced = () => {
  return (
    <>
    <Helmet>
<title>Experienced Professionals | HBL People</title>
<meta
name="description"
content="Your key to embark on an unparalleled professional journey"
/>
</Helmet>
        <section className="innerpagebanner experienced">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top left 0.3s and move 65px wait .3s">EXPERIENCED PROFESSIONALS</h1>
      <h2 data-sr="enter top left 0.3s and move 65px wait .6s">Your key to embark on an unparalleled professional journey</h2>
    </div>
  </div>
</section>
<section className="innersection experienced">
  <div className="container">
    <div className="text-center">
      <h3 className="web" data-sr="enter top left 0.3s and move 65px wait .3s">TAKE THE NEXT STEP</h3>
      <h2 className="web" data-sr="enter top left 0.3s and move 65px wait .6s">Build your career the way you want</h2>
      <p data-sr="enter top left 0.3s and move 65px wait .9s">If you are truly exceptional, we have the challenges, training & mentoring to hone your skills and polish your strengths. For us OUR team is the most valuable asset. This is the next and most possibly the ultimate milestone in your career journey. HBL is the most distinguished banking institution of Pakistan. We value talent and intellect as much as teamwork. Here you will work with some of the best banking professionals who will help you become part of the next generation of banking. </p>
    </div>
  </div>
</section>
<Testimonails/>
<section className="innersection experienced why-how-section">
  <div className="container">
    <div className="row">
      <div className="col-lg-6">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait .6s"> <img src="images/experienced/icon-why.png" alt=""/>
          <h4 className="text-uppercase">Why Join HBL</h4>
          <p>Whether you are early in your career or a seasoned professional your next career move at HBL ensures that you continue to grow your professional expertise by exploring new challenges every day and apply your skills and knowledge to deliver business value and delightful experience to our customer. With a career at HBL, there are no limitations. You can chisel your current expertise or explore a new business altogether. Here, YOUR impact matters.</p>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait 1s"> <img src="images/experienced/icon-how.png" alt=""/>
          <h4 className="text-uppercase">How will you grow</h4>
          <p>At HBL we make sure that our employees continuously put their best foot forward. Whether it is mentoring from experienced professionals or frequent learning & development opportunities through our blended learning systems, our vast employee base and business operations, both locally and globally ensure that you will learn something new from everyone, everyday!</p>
          <p className="mt-3">Join us to embark on an exciting journey where we help you grow to your potential.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="innersection experienced expect">
  <div className="container">
    <div className="text-center mb-5">
      <h3 className="web" data-sr="enter top left 0.3s and move 65px wait .3s">What to Expect</h3>
      <h2 className="web" data-sr="enter top left 0.3s and move 65px wait .6s">the best Collaborative environment</h2>
    </div>
    <div className="row row-cols-2 row-cols-md-3 row-cols-lg-6 text-center">
      <div className="col">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait .3s">
			<div className="inimage">
			<img src="images/experienced/icon-conducive.png" alt="" className="a"/>
				<img src="images/experienced/icon-conducive-white.png" alt="" className="b"/>
			</div>
          <p>A conducive environment where you will learn and develop</p>
        </div>
      </div>
      <div className="col">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait .6s"> 
			<div className="inimage">
			<img src="images/experienced/icon-constant.png" alt="" className="a"/>
				<img src="images/experienced/icon-constant-white.png" alt="" className="b"/>
			</div>
          <p>Constant support from your co-workers and exposure to significant business projects</p>
        </div>
      </div>
      <div className="col">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait .9s"> 
			<div className="inimage">
			<img src="images/experienced/icon-colloborative.png" alt="" className="a"/>
				<img src="images/experienced/icon-colloborative-white.png" alt="" className="b"/>
			</div>
          <p>Collaborative, innovative and stimulating work environment</p>
        </div>
      </div>
      <div className="col">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait 1.2s"> 
			<div className="inimage">
			<img src="images/experienced/icon-support.png" alt="" className="a"/>
				<img src="images/experienced/icon-support-white.png" alt="" className="b"/>
			</div>
          <p>Support and regular feedback from your supervisor</p>
        </div>
      </div>
      <div className="col">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait 1.5s"> 
			<div className="inimage">
			<img src="images/experienced/icon-opportunity.png" alt="" className="a"/>
				<img src="images/experienced/icon-opportunity-white.png" alt="" className="b"/>
			</div>
          <p>Opportunity to network with and learn from seasoned professionals</p>
        </div>
      </div>
      <div className="col">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait 1.8s"> 
			<div className="inimage">
			<img src="images/experienced/icon-work.png" alt="" className="a"/>
				<img src="images/experienced/icon-work-white.png" alt="" className="b"/>
			</div>
          <p>Work that is full of excitement and challenges</p>
        </div>
      </div>
    </div>
  </div>
</section>
<Functionfilter />
    </>
  )
}

export default Experienced