import React from "react";
const Footertabmt = () => {
  return (
    <>
      <div className="container foo-tab">
        <div className="row  g-3">
          <div className="col-md-3 col-lg-3 fooonecol">
            {" "}
            <a
              href="/the-league/the-hbl-experience/"
              id="foo-one"
            >
              <h4>Why HBL? </h4>
            </a>
          </div>
          <div className="col-md-3 col-lg-3 footwocol">
            <a href="/the-league/your-role/" id="foo-two">
              <div>
                <img src="/images/theleague/mt/footer-tab-blue-icon-original.png" />
              </div>
              <h4>What can you do here?</h4>
            </a>
          </div>
          <div className="col-md-3 col-lg-3 foothreecol">
            <a
              href="/the-league/life-in-the-league/"
              id="foo-three"
            >
              <div>
                <img src="/images/theleague/mt/footer-tab-pruple-icon-original.png" />
              </div>
              <h4>What's it like?</h4>
            </a>
          </div>
          <div className="col-md-3 col-lg-3 foofourcol">
            <a
              href="/the-league/your-career-path/"
              id="foo-four"
            >
              <div>
                <img src="/images/theleague/mt/footer-tab-orange-icon-original.png" />
              </div>
              <h4>How will you develop?</h4>
            </a>
          </div>
          <div className="col-md-3 col-lg-3 foofivecol">
            <a
              href="/the-league/what-do-we-look-for/"
              id="foo-five"
            >
              <div>
                <img src="/images/theleague/mt/footer-tab-yellow-icon-original.png" />
              </div>
              <h4>How can you join?</h4>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footertabmt;
