import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Privacypolicy() {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the external link when component mounts
    window.location.href = 'https://www.hbl.com/privacy-protocol';
  }, []);

  // Return null to prevent rendering any content
  return null;
}

export default Privacypolicy;