import React from "react";
import { Helmet } from "react-helmet";

const Leagueagpprocess = () => {
  return (
    <>
      <Helmet>
        <title>Application Process FAQs | THE LEAGUE</title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
      </Helmet>
      <section className="privacy faq">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 d-none d-lg-block">
              <a href="/the-league/management-trainee/" className="rthome">
                <img src="/images/theleague/innerpage-back-button-arrow.png" />
                Back
              </a>
            </div>
            <div className="col-lg-4">
              <a href="/the-league">
                <img
                  src="/images/theleague/the-league-logo.png"
                  className="img-fluid d-block mx-auto"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="container mt-4">
          <h3>
            What is the eligibility criteria for The League Accelerate Graduate
            Programme?
          </h3>
          <p>
            In order to be eligible for the League Accelerate Graduate
            Programme, you must be a graduate holding a Master’s degree in any
            of the following disciplines.
          </p>
          <ul>
            <li>Accounting</li>
            <li>Bi-Majors Accounting and Finance</li>
            <li>Bi-Majors Economics and Finance</li>
            <li>Bi-Majors Economics and Mathematics</li>
            <li>Bi-Majors Economics and Politics</li>
            <li>Business Management and Administration</li>
            <li>Communications</li>
            <li>Computer Sciences</li>
            <li>Computer Engineering</li>
            <li>Data Science</li>
            <li>Economics</li>
            <li>Entrepreneurship</li>
            <li>Finance</li>
            <li>Human Resources</li>
            <li>Information Technology</li>
            <li>International Business</li>
            <li>Management</li>
            <li>Marketing/Sales</li>
            <li>Mathematics</li>
            <li>Social Sciences</li>
            <li>Supply Chain</li>
          </ul>
          <p>
            Disclaimer: Current full-time permanent employees of HBL are not eligible to apply for this programme.
          </p>
          <hr />
          <h3>
            Which browsers are supported for submitting the online application?
          </h3>
          <p>
            It is recommended that you use Google Chrome or Safari for a smooth experience.
          </p>
          <hr />
          <h3>Do I have to make any attachments to my online application?</h3>
          <p>
            Yes, you will be required to attach a formal and professional
            picture with your application along with your resume.
          </p>
          <hr />
          <h3>What should be the size and format of the picture?</h3>
          <p>
            The picture format should be JPG/PNG. The size shouldn’t exceed 2MB.
          </p>
          <hr />
          <h3>Do you accept applications by mail?</h3>
          <p>
            No, we will only entertain applications submitted online.
            <a
              href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_10017/job/6606"
              rel="noopener"
              target="_blank"
            > Click Here </a>
            to Apply now.
          </p>
          <hr />
          <h3>
          The online application for the Accelerate Graduate Programme does not allow me to select my year of birth before July 1994. What should I do?
          </h3>
          <p>
          The programme is designed for a specific age group. However, we may have other positions for you. For all other vacancies, <a
              href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_10017/job/6464"
              target="_blank"
              rel="noopener noreferrer"
            > Click here </a>

          </p>
          <hr />
          <h3>
            I did not receive a confirmation email after submitting the online application. What should I do?
          </h3>
          <p>
          There is a chance that the mail landed in your spam folder. If it is not there, contact us at theleague@hbl.com
          </p>
          <hr />
          <h3>
          I will be graduating in the Summer instead of Fall semester, am I still eligible for the programme?
          </h3>
          <p>
          Yes, you are eligible. However, you will be asked to submit an undertaking from your university if you are selected for the programme.
          </p>
          <hr />
          <h3>
          I have already done my Bachelors and I’m currently pursuing my Masters on weekends. I am an experienced professional, should I apply for the programme?
          </h3>
          <p>
          The Accelerate Graduate Programme is only open to experienced professionals with a Bachelor’s AND completed Master’s degree with between 2 – 4 years of work experience.
          </p>
          <hr />
          <h3>
          I am an experienced professional, can I apply for the Programme?
          </h3>
          <p>
          Yes, the Accelerate Graduate Programme is open to experienced professionals with a Master’s degree who have between 2 – 4 years of work experience.
          </p>
          <hr />
          <h3>
          I am a Pakistani graduating/recently graduated from a foreign university. Can I apply?
          </h3>
          <p>
          Yes, you can apply. However, please note that all positions are based in Pakistan. The selection process will be the same for you except for the dates of the assessment center and panel interview, which will be communicated to you separately.
          </p>
          <hr />
          <h3>How long does the application process take?</h3>
          <p>
          HBL follows a transparent and competitive recruitment process, therefore we may take some time to assess and respond to your application. The flowchart below shows the different stages of recruitment:
          </p>
          <p>
            <img
             src="/images/theleague/mt/6arrows.png"
              class="img-fluid"
            />
          </p>
          <hr />
          <h3>What is the online assessment like?</h3>
          <p>
          The online assessment has been designed as a gamified simulation. The assessment considers cognitive ability as well as leadership competency factors.
          </p>
          <p>
          Please note: The competencies assessed are not dependent on your degree attained.
          </p>
          <hr />
          <h3>
          How long is the online assessment? How much time do I have to complete it?
          </h3>
          <p>
          The assessment consists of situation judgement questions and a series of mini games. Approximate time to complete the assessment is 30-40 minutes.
          </p>
          <hr />
          <h3>Is there anything I should know about the online assessment?</h3>
          <p>
          While our online assessment is compatible with mobile gameplay, we strongly advise that you use a computer/desktop/laptop device to play and complete the assessment. You must read your email before proceeding for the online assessment. The email will contain further important instructions and details about the online assessment.
          </p>
          <hr />
          <h3>How will I be informed if I clear a stage?</h3>
          <p>
          You will receive an email from us for each stage that you clear.
          </p>
          <hr />
          <h3>
          Who can I contact if I’m having problems with gamified online assessment?
          </h3>
          <p>
          For assistance regarding the gamified online assessment, you may contact support@thetalentgames.com. Make sure you clearly mention your name and HBL “The League” 2024 in your email subject line. It is crucial that you go through all instructions/guidelines provided before you attempt your game to ensure that you are aware of all conditions for a smoother gameplay experience.
          </p>
          <hr />
          <h3>
          After reviewing all of this information, I still have questions. What should I do?
          </h3>
          <p>
          If the FAQs don’t answer your queries regarding the application process, you may write to us at: theleague@hbl.com
          </p>
          <hr />
          <h3>Forgot/lost password?</h3>
          <p>
          Incase you have forgotten or lost your password sent at the time of registration, please use the following link to reset your password
            <a
              href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_10017/my-profile/sign-in"
              target="_blank"
              rel="noopener noreferrer"
            > Click here </a>
          </p>
          <hr />
          <h3>
          What is the last date to register for The League Management Trainee Programme?
          </h3>
          <p>Registration will remain open till February 28, 2024.</p>
          <hr />
          <h3>How can I access the game?</h3>
          <p>
            Registration link will be live on 1<sup>st</sup> February 2024. Upon
            registration you will receive a confirmation email containing your
            username and password.
          </p>
          <hr />
          <h3>Can I play the game on my phone?</h3>
          <p>
            The gamified assessment does support mobile play. However, for a
            smoother and better gameplay experience, we recommend that you use
            your desktop/computer device with a camera to complete this game.
            Please use either Google Chrome or Firefox web browser with a strong
            and stable internet (wired, Wi-Fi/3G or 4G) connection.
          </p>
          <hr />
          <h3>Do I need to have internet access to play the game?</h3>
          <p>
            Yes, you will need to be connected to the internet to play the game.
          </p>
          <hr />
          <h3>
            What if my game crashes in the middle, I lose electricity or
            internet connection?
          </h3>
          <p>
            You will be able to resume the game twice and continue with your
            progress from where you left off in case you lose network
            connectivity, or when your browser is closed. If your game gets
            locked, which will happen in case you have tried to access your game
            more than twice of closing your browser, then please feel free to
            reach out to support@thetalentgames.com. Please make sure you
            clearly mention your name and HBL “The League” 2024 in your email
            subject line.
          </p>
          <hr />
          <h3>Will my responses be recorded if the game crashes in between?</h3>
          <p>Yes.</p>
          <hr />
          <h3>Do I have to be a business student to play the game?</h3>
          <p>
            No. All students who have registered, will be able to play the game.
          </p>
          <hr />
          <h3>
            I live abroad. How will I be contacted if I clear the gameplay?
          </h3>
          <p>All communication will be done through email.</p>
          <hr />
          <h3>
            Do I need anything in&nbsp;specific&nbsp;before I begin playing the
            game?
          </h3>
          <p>
            A PC/Laptop that meets the minimum system requirement:
            <br />
            <strong>For Windows:</strong>&nbsp;Windows 10, 4GB RAM, core i3
            processor – 3rd generation and a separate graphics memory.
            <br />
            <strong>For Mac:</strong>&nbsp;iOS 10 or above. A stable internet
            connection and a compatible browser such as Google Chrome, and/or
            Mozilla Firefox.
            <br />
            You must read your email before proceeding for the online
            assessment. The email will contain further important instructions
            and details about the online assessment.
          </p>
          <hr />
          <h3>
            Can I make changes to my candidate profile once I have played the
            game?
          </h3>
          <p>
            No. However, you will receive a copy of your submitted application
            for your record and information.
          </p>
          <hr />
          <h3>
            Can I apply to both The Management Trainee &amp; Accelerate Graduate
            Programme?
          </h3>
          <p>
            No, you can only apply to one programme of The League as each
            requires a different candidate profile. Please check the application
            criteria carefully and only apply to the Programme which fits your
            candidacy.
          </p>
          <hr />
        </div>
      </section>
    </>
  );
};

export default Leagueagpprocess;
