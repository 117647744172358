import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Campheader from "../../../../components/Campheader";
import Campheadermob from "../../../../components/Campheadermob";

const Campost31 = () => {
  const images = [
    "/images/theleague/mt/posts/31/1.jpg",
    "/images/theleague/mt/posts/31/2.jpg",
    "/images/theleague/mt/posts/31/3.jpg",
    "/images/theleague/mt/posts/31/4.jpg",
    "/images/theleague/mt/posts/31/5.jpg",
    "/images/theleague/mt/posts/31/6.jpg",
    "/images/theleague/mt/posts/31/7.jpg",
    "/images/theleague/mt/posts/31/8.jpg",
    "/images/theleague/mt/posts/31/9.jpg",
    "/images/theleague/mt/posts/31/10.jpg",
    "/images/theleague/mt/posts/31/11.jpg",
    "/images/theleague/mt/posts/31/12.jpg",
    "/images/theleague/mt/posts/31/13.jpg",
    "/images/theleague/mt/posts/31/14.jpg",
    "/images/theleague/mt/posts/31/15.jpg",
    "/images/theleague/mt/posts/31/16.jpg",
    "/images/theleague/mt/posts/31/17.jpg",
    "/images/theleague/mt/posts/31/18.jpg",
    "/images/theleague/mt/posts/31/19.jpg",
    "/images/theleague/mt/posts/31/20.jpg",
  ];
  return (
    <>
      <Helmet>
        <title>
        Habib University Campus Drive 2020 | THE LEAGUE
        </title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
          <Campheader />
          <h1 className="my-3">Habib University Campus Drive 2020</h1>
          <Lightbox images={images} />
          <Campheadermob />
        </div>
      </section>
    </>
  );
};

export default Campost31;
