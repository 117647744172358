import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functionriskmanagement = () => {
  return (
    <>
    <Helmet>
<title>Risk Management | HBL People</title>
<meta
name="description"
content="The Risk Worth Taking"
/>
</Helmet>
<section className="innerpagebanner risk-management">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Risk Management</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">The Risk Worth Taking</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages risk-management">
  <div className="container d-flex flex-wrap gap-3">
    <p>Risk Management is responsible for implementing the overall risk management framework of the Bank and for development and oversight of risk policies. It plays a pivotal role in monitoring the risks associated with various activities of the Bank to ensure that these are carried out within the defined risk appetite of the institution.  Comprises of the following units:</p>
    <div>
      <h4>Credit Risk Management</h4>
      <p>The Credit Risk Function ensures that the Bank is taking credit risk exposure within acceptable risk appetite and Board approved parameters while maximizing Bank's risk-adjusted return. It has further sub-divisions according to business segments. </p>
    </div>
    <p>Corporate/Commercial/Retail/ International Risk are involved in credit approvals jointly with business and proactive credit portfolio management.   International Risk also provides governance and oversight of other risks for international locations in coordination with relevant departments. </p>
    <p>Program Based Lending is responsible for managing credit risk on Consumer, Retail and Agricultural facilities.</p>
    <p>FI &amp; Country Risk manages credit approvals and portfolio management for all global/domestic commercial Banks, local NBFIs/ DFIs and local Insurance companies. The department is also responsible for setting the risk limits for various sovereigns.</p>
    <div>
      <h4>Market &amp; Liquidity Risk Management</h4>
      <p>This department has two subdivisions:</p>
    </div>
    <div>
      <p><strong>Treasury Middle Office &amp; Market Risk</strong> ensures that a sound Market and Liquidity Risk Management Framework is in place including implementation and upgradation of policies, procedures and systems in line with the Bank's Risk Appetite, regulatory guidelines and international best practices. The division is responsible for managing liquidity risk, interest rate risk, equity price risk, and foreign exchange risk of HBL globally.</p>
    </div>
    <div>
      <p><strong>Financial Risk Modelling &amp; Stress Testing</strong> is involved in development and onwards maintenance &amp; updating of various risk models including statistical/empirical and judgment-based models, using various programming tools.</p>
    </div>
    <div>
      <h4>Enterprise Risk Management (ERM)</h4>
      <p>The ERM function enables the Bank to identify, assess, manage, monitor, control and report enterprise-wide risks on a consistent and aggregated basis. ERM is a continuous process that extends the concept of risk management across the entire organization to foster risk aware culture and decision making.  It also includes the Fraud Risk Management (FRMU) unit.</p>
    </div>
    <div>
      <h4>Operational Risk Management (ORM)</h4>
      <p>The unit is primarily responsible for managing the operational risks related to domestic as well as international operations. ORM is managed through Risk and Control Self-Assessment (RCSAs), Incident Reporting and tracking of Key Risk Indicators (KRIs).</p>
    </div>
    <div>
      <h4>Risk Corporate Governance</h4>
      <p>It is responsible for development and maintenance of risk management policies, information systems and coordination of risk and control activities within risk management.</p>
    </div>
    <div>
      <h4>Credit Administration Division (CAD)</h4>
      <p>Credit Administration Division provides back-office support to various business segments in the Bank for completion of pre and post disbursal formalities and monitoring.</p>
    </div>
  </div>
</section>

<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functionriskmanagement
