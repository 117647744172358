import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functioncorporatesecretariat = () => {
  return (
    <>
    <Helmet>
<title>Corporate Secretariat | HBL People</title>
<meta
name="description"
content="Keepers of the Keys"
/>
</Helmet>
<section className="innerpagebanner corporate-secretariat">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Corporate Secretariat</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Keepers of the Keys</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection corporate-secretariat">
  <div className="container">
    <p>Corporate Secretariat department led by Company Secretary is responsible to carry out the statutory duties including ensuring the integrity of the governance framework as per statutory requirements; efficient administration of the Bank’s Board of Directors/Board Committees meetings and Shareholders’ meeting (General meetings; record keeping of meetings’ minutes, coordinating for the Implementation of the decisions of the Board of Directors; managing Bank’s shareholders matters related to transmissions/transfers, arranging dividend payments; TFCs issuance and redemptions, issuance of share capital and observing all legal and regulatory requirements ; Liaising with auditors, lawyers, advisers, regulators and shareholders on the Board governance issues; ensuring compliance obligations under relevant laws and the requirements of regulatory authorities e.g., State Bank of Pakistan (SBP), Securities Exchange Commission of Pakistan (SECP) and Pakistan Stock exchange (PSX); keeping abreast the Board and management about international best Corporate Governance practices & emerging trends and its adaptability.</p>
  </div>
</section>

<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functioncorporatesecretariat
