import React, { useState , useEffect } from "react";
import { Helmet } from "react-helmet";
import JDialog from "../../components/Jdialog";
import Campdata from "../../components/Campdata";
import Journey from "../../components/Journey";

const Managementtrainee = () => {
  const [openDialogId, setOpenDialogId] = useState(null);

  const openDialog = (id) => {
    setOpenDialogId(id);
  };

  const closeDialog = (id) => {
    if (openDialogId === id) {
      setOpenDialogId(null);
    }
  };

  useEffect(() => {
    new window.Swiper(".campslide", {
      slidesPerView: 1,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1550: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Management Trainee | THE LEAGUE</title>
       <link rel="stylesheet" href="/css/font.css"/>
        <link rel="stylesheet" href="/css/jdialog.min.css" /> 
        <link rel="stylesheet" href="/css/theleague.css" />
      </Helmet>
      <section className="home2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 d-none d-lg-block">
              <a href="/the-league" className="rthome">
                <img
                  src="/images/theleague/innerpage-back-button-arrow.png"
                  className="img-fluid"
                />
                Return to Home
              </a>
            </div>
            <div className="col-lg-4">
              <a href="/the-league">
                <img
                  src="/images/theleague/the-league-logo.png"
                  className="img-fluid d-block mx-auto"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="container position-relative">
          <div className="row block">
            <div className="col-sm-6 col-md-4 col-lg-4  text-center">
              <a href="#" id="one" onClick={() => openDialog("dialog-1")}>
                <h2>Why HBL?</h2>
                <p>
                  <img src="/images/theleague/home-small-box-button-arrow.png" />
                  Learn more
                </p>
              </a>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <a href="#" id="two" onClick={() => openDialog("dialog-2")}>
                <img src="/images/theleague/mt/home-blue-icon.png" />
                <h4>What can you do here?</h4>
                <p>
                  <img src="/images/theleague/home-small-box-button-arrow.png" />
                  Learn more
                </p>
              </a>
              <a href="#" id="three" onClick={() => openDialog("dialog-3")}>
                <img src="/images/theleague/mt/home-what-its-like-icon.png" />
                <h4>What's it like?</h4>
                <p>
                  <img src="/images/theleague/home-small-box-button-arrow.png" />
                  Learn more
                </p>
              </a>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <a href="#" id="four" onClick={() => openDialog("dialog-4")}>
                <img src="/images/theleague/mt/home-orange-icon.png" />
                <h4>How will you develop?</h4>
                <p>
                  <img src="/images/theleague/home-small-box-button-arrow.png" />
                  Learn more
                </p>
              </a>
              <a href="#" id="five" onClick={() => openDialog("dialog-5")}>
                <img src="/images/theleague/mt/home-yellow-icon.png" />
                <h4>How can you join?</h4>
                <p>
                  <img src="/images/theleague/home-small-box-button-arrow.png" />
                  Learn more
                </p>
              </a>
            </div>
          </div>
          <div className="row block btwo">
            <div className="pops">
              <JDialog
                id="dialog-1"
                isOpen={openDialogId === "dialog-1"}
                onClose={closeDialog}
                skinClassName="demo"
                animationType="flip"
              >
                <div>
                  <h3>Why HBL?</h3>
                  <p>
                    HBL isn't an office. It's a home. With an unyielding
                    commitment towards developing outstanding leaders, HBL is
                    the place to begin your career. HBL offers a set of
                    experiences beyond the banking norm. With us, you'll receive
                    the training, support and guidance that will enable you to
                    create high quality work for our exciting clients.
                  </p>
                  <a className="link" href="/the-league/the-hbl-experience/">
                    <img src="/images/theleague/mt/popup-green-button-arrow.png" />
                    Find out how we make HBL a great place to work
                  </a>
                </div>
              </JDialog>
              <JDialog
                id="dialog-2"
                isOpen={openDialogId === "dialog-2"}
                onClose={closeDialog}
                skinClassName="demo"
                animationType="flip"
              >
                <div>
                  <h3>What can you do here?</h3>
                  <p>
                    With a passion to learn, the right set of skills and an
                    enterprising mindset, you can choose a rewarding career from
                    our diversified business and support function portfolios.
                  </p>
                  <a className="link" href="/the-league/your-role/">
                    <img src="/images/theleague/mt/popup-blue-button-arrow.png" />
                    Find out about opportunities for graduates at HBL
                  </a>
                </div>
              </JDialog>
              <JDialog
                id="dialog-3"
                isOpen={openDialogId === "dialog-3"}
                onClose={closeDialog}
                skinClassName="demo"
                animationType="flip"
              >
                <div>
                  <h3>What's it like?</h3>
                  <p>
                    As a member of The League, you will receive continued
                    support and guidance from the beginning till the end of your
                    time as a Management Trainee. With a detailed development
                    plan in place and an inclusive approach, The League provides
                    challenges which shall form you into the high quality
                    professional you aspire to be.
                  </p>
                  <a className="link" href="/the-league/life-in-the-league/">
                    <img src="/images/theleague/mt/popup-purple-button-arrow.png" />
                    Find out more about what it's like here
                  </a>
                </div>
              </JDialog>
              <JDialog
                id="dialog-4"
                isOpen={openDialogId === "dialog-4"}
                onClose={closeDialog}
                skinClassName="demo"
                animationType="flip"
              >
                <div>
                  <h3>How will you develop?</h3>
                  <p>
                    Through a detailed development process, you will learn the
                    value of teamwork and task ownership. This process
                    facilitates effective skill development whilst allowing you
                    to learn at every level available at HBL.
                  </p>
                  <a className="link" href="/the-league/your-career-path/">
                    <img src="/images/theleague/mt/popup-orange-button-arrow.png" />
                    Find out how you can learn in a way that works for you
                  </a>
                </div>
              </JDialog>
              <JDialog
                id="dialog-5"
                isOpen={openDialogId === "dialog-5"}
                onClose={closeDialog}
                skinClassName="demo"
                animationType="flip"
              >
                <div>
                  <h3>How can you join?</h3>
                  <p>
                    If you share our values and have unique talents, potential
                    to grow and the enthusiastic personality takes to succeed
                    here, then we want to hear from you.
                  </p>
                  <a className="link" href="/the-league/what-do-we-look-for/">
                    <img src="/images/theleague/mt/popup-yellow-button-arrow.png" />
                    What do we look for?
                  </a>
                  <a
                    className="link"
                    href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_10017/job/6464"
                    target="_blank"
                  >
                    <img src="/images/theleague/mt/popup-yellow-button-arrow.png" />
                    Apply Now
                  </a>
                  <a
                    className="link"
                    href="/the-league/application-process-faqs/"
                    target="_blank"
                  >
                    <img src="/images/theleague/mt/popup-yellow-button-arrow.png" />
                    Application Process FAQs
                  </a>
                </div>
              </JDialog>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="text-center camp-dr">
            <h2>The League's Journey</h2>
            <div className="position-relative">
              <div className="swiper campslide">
                <div className="swiper-wrapper">
                  {Campdata.map((val, index) => {
                    return (
                      <Journey
                        key={val.id}
                        imgsrc={val.imgsrc}
                        title={val.title}
                        link={val.link}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="swiper-button-next"></div>
              <div className="swiper-button-prev"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Managementtrainee;
