import React from 'react';
import { Helmet } from "react-helmet";


const Leaguehome = () => {
    return (
      <>
    <Helmet>
<title>THE LEAGUE</title>
<link rel="stylesheet" href="/css/font.css" />
<link rel="stylesheet" href="/css/theleague.css" />
</Helmet>
<section className="landing-bg">
    <div className="container text-center"> <a href="the-league"><img
                src="/images/theleague/the-league-logo.png" className="img-fluid mx-auto" /></a>
        <h2>About THE LEAGUE</h2>
        <div className="row">
            <div className="col-md-12 col-lg-12">
                <p>The League is a result of HBL’s dedication towards developing and nurturing talent of high potential
                    individuals. As a member of The League, you will face challenges and tasks which shall test your
                    resilience, enabling you to grow as future leaders.</p>
            </div>
        </div>
        <div className="row block">
            <div className="col-md-4 col-lg-4">
                <a href="the-league/internship/" id="intern">
                    <div className="one">
                        <h2>Internships</h2>
                    </div>
                    <div className="two">
                        <h4>Discover Yourself <br /> With HBL Internship Programme</h4>
                        <p> <img
                                src="/images/theleague/home-small-box-button-arrow.png" /> Explore</p>
                    </div>
                </a>

            </div>
            <div className="col-md-4 col-lg-4"> <a href="the-league/management-trainee/"
                    id="member">
                    <div className="one">
                        <h2>Management Trainee Programme</h2>
                    </div>
                    <div className="two">
                        <h4>Empowering ambitious graduates to be tomorrow’s leaders.</h4>
                        <p> <img
                                src="/images/theleague/home-small-box-button-arrow.png" /> Explore</p>
                    </div>
                </a>

            </div>
            <div className="col-md-4 col-lg-4"> <a
                    href="the-league/accelerate-graduate-programme/" id="agp">
                    <div className="one">
                        <h2>Accelerate Graduate Programme</h2>
                    </div>
                    <div className="two">
                        <h4>Empowering ambitious graduates to be tomorrow’s leaders.</h4>
                        <p> <img
                                src="/images/theleague/home-small-box-button-arrow.png" /> Explore</p>
                    </div>
                </a>

            </div>
        </div>
    </div>
</section>

</>
  )
}

export default Leaguehome