import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functionlegalaffairs = () => {
  return (
    <>

<Helmet>
<title>Legal Affairs | HBL People</title>
<meta
name="description"
content="Where law and business meet to achieve success together"
/>
</Helmet>
<section className="innerpagebanner legal-affairs">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Legal Affairs</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Where law and business meet to achieve success together</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages legal-affairs">
  <div className="container d-flex flex-wrap gap-3">
    <p>Welcome to the Legal Affairs Group of HBL, where we are committed to services to the Bank on all legal matters, while maintaining the highest standards of ethical conduct and ensuring compliance with applicable laws.</p>
    <p>The Group works closely with all domestic and international stakeholders within the Bank, to protect the Bank’s interests by identifying and mitigating legal risks, while providing legal guidance and support. The Legal Affairs Group has three departments, the Legal Advisory Department, the Litigation Department, and the Law Enforcement Agencies Department,  comprising senior and experienced lawyers, including an advocate enrolled in the Supreme Court of Pakistan, senior retired officers from law enforcement agencies and other professionals specializing in various areas of law, including banking and finance, litigation management, corporate law, regulatory compliance, and banking fraud is dedicated to providing top-notch legal services to the Bank.</p>
    <p>The Legal Advisory Services provides comprehensive legal support to all departments of the Bank, enabling the Bank to comply with applicable laws and regulations and mitigate legal risks associated with its operations and business. The Litigation Department provides legal expertise in handling all litigation and supports in recovery of bad loans through effective and efficient litigation management. The LEA Department is an important linchpin between different departments of the Bank and law enforcement agencies, ensuring smooth facilitation in different criminal inquiries and investigations across Pakistan.</p>
	  <p style={{color: '#333',fontSize: '24px',fontWeight: '400'}}><em>“I have found competitive but encouraging environment at HBL for professional growth. It is a good platform for enhancing my knowledge and applying it in the manner which is practical and is in accordance with the law. I have always been appreciated for my good work and loyalty with my job."</em><span className="d-block" style={{color:"#197056"}}><em>Waqas Ahmad</em></span></p>
	  <p style={{color: '#333',fontSize: '24px',fontWeight: '400'}}><em>“It is the greatest pleasure to work in such a prestigious organization and even more so, the Litigation Department. As one of the youngest employees in the department, I, over the past 5 years, have become a better lawyer, under the management of the Litigation Department than I had whilst practicing. Gone are the times when you remain stagnant in a field, ever so threatened by boredom when you can have an intellectual discussion with your seniors, albeit in law field or professionally and to learn how the little cogs of law work."</em><span className="d-block" style={{color:"#197056"}}><em>Ata ur Rehman (Manager Litigation)</em></span></p>
	  <p style={{color: '#333',fontSize: '24px',fontWeight: '400'}}><em>“I feel honored to be a part of a wonderful professional team at HBL. As a female, the Bank provides a safe, secure and professional work environment, which motivates me in discharging my duties to the best of my abilities."</em><span className="d-block" style={{color:"#197056"}}><em>Hira Siddiqui (LEA Liaison officer- South)</em></span></p>
  </div>
</section>

<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functionlegalaffairs
