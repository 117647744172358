import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Campheader from "../../../../components/Campheader";
import Campheadermob from "../../../../components/Campheadermob";

const Campost24 = () => {
  const images = [
    "/images/theleague/mt/posts/24/1.jpg",
    "/images/theleague/mt/posts/24/2.jpg",
    "/images/theleague/mt/posts/24/3.jpg",
    "/images/theleague/mt/posts/24/4.jpg",
    "/images/theleague/mt/posts/24/5.jpg",
  ];
  return (
    <>
      <Helmet>
        <title>
        Assessment Centre Lahore 2019 | THE LEAGUE
        </title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
          <Campheader />
          <h1 className="my-3">Assessment Centre Lahore 2019</h1>
          <Lightbox images={images} />
          <Campheadermob />
        </div>
      </section>
    </>
  );
};

export default Campost24;
