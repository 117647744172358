import React, { useState } from 'react';

const Lightbox = ({ images }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const nextImage = () => {
    setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div>
      <div className="image-grid">
        {images.map((image, index) => (
          <div key={index} className="grid-item">
            <img
              src={image}
              alt={`Internship ${index}`}
              className="grid-image"
            />
            <div className="overlay">
              {/* <i className="search-icon" onClick={() => openLightbox(index)}>&#128269;</i> */}
              <div className="search-icon" onClick={() => openLightbox(index)}>+</div>
            </div>
          </div>
        ))}
      </div>

      {isOpen && (
        <div className="lightbox">
          <div className="lightbox-content">
            
            <img
              src={images[currentImage]}
              alt={`Internship ${currentImage}`}
              className="lightbox-image"
            />
            <span className="close" onClick={closeLightbox}>
              &times;
            </span>
            {/* <div className="lightbox-controls">
              <button onClick={prevImage} className="prev-button">
                ❮
              </button>
              <button onClick={nextImage} className="next-button">
                ❯
              </button>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Lightbox;
