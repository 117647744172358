import React from 'react';
import { Helmet } from "react-helmet";

const NoPage = () => {
  return (
    <>
      <Helmet>
<title>Not Found | HBL People</title>
</Helmet>
<>
<section className='my-5 py-5'>
<div className='container text-center'>
      <h2 className='text-black' data-sr="enter bottom over 0.3s and move 65px wait 0.3s">404</h2>
      <h1 className='text-black' data-sr="enter bottom over 0.3s and move 65px wait 0.3s">Page Not Found</h1>
    </div>
</section>

</>
    
    </>
  )
}

export default NoPage
