import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functiondevelopmentfinance = () => {
  return (
    <>
    <Helmet>
<title>Development Finance | HBL People</title>
<meta
name="description"
content="Transforming economy, empowering communities: Inclusive Solutions for Sustainable Growth"
/>
</Helmet>
<section className="innerpagebanner development-finance">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Development Finance</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Transforming economy, empowering communities: Inclusive Solutions for Sustainable Growth </h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages development-finance">
  <div className="container d-flex flex-wrap gap-3">
<p>The Development Finance Group (DFG) is a newly established function that aims to uplift underserved and exploited farmers in Pakistan. DFG's approach focuses on creating sustainable economic value chains that meet customer needs while making efficient use of resources. The group's primary goal is to design solutions that promote economic uplift, food and water security, sustainability, and gender inclusion across all Small, Medium, and Commercial Enterprises.</p>

<p>In the context of agriculture initiatives, DFG's approach centers on providing farmers with high-quality inputs, modern mechanization, and better agronomic advice to improve their economic conditions and enhance productivity. DFG also facilitates direct access to the market for farmers by connecting them with bulk processors of their crop produce. Furthermore, DFG's program aims to secure land tenure rights for farmers and provide a support system to cope with any economic losses due to disasters. The program also provides loans and funding to small-scale food producers to increase productivity and income, leading to increased agricultural sustainability at the grassroots level.</p>

<p>DFG has made significant progress in its agriculture initiatives, covering over 45,000 acres across the country resulting improved overall yields. The initiatives have been endorsed by international economists from Yale and Princeton for its positive impact. Additionally, DFG is profitable for the Bank, creating a double bottom-line impact that benefits both farmers and the Bank.</p>

<p>DFG efforts demonstrate its commitment to drive growth and development in the communities it serves. By providing innovative financial solutions and incorporating technology-driven practices, DFG is creating opportunities for farmers to improve their crop yields and generate greater income. As a result, DFG is contributing to the overall economic development and growth of the region, supporting sustainable agriculture for the population it serves.</p>

  </div>
</section>

<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functiondevelopmentfinance
