const Interndata = [
    {
        id: "13",
        imgsrc: "/images/theleague/internship/journey/13.jpg",
        title: "Internship Orientation 2021",
        link: "/the-league/internship-orientation-2021/",
    },
    {
        id: "12",
        imgsrc: "/images/theleague/internship/journey/12.jpg",
        title: "MT and Accelerate Graduates Orientation & L&D 2021",
        link: "/the-league/mt-and-accelerate-graduates-orientation-ld-2021/",
    },
    {
        id: "11",
        imgsrc: "/images/theleague/internship/journey/11.jpg",
        title: "The League Orientation Ceremony 2019",
        link: "/the-league/the-league-orientation-ceremony-2019/",
    },
    {
        id: "10",
        imgsrc: "/images/theleague/internship/journey/10.jpg",
        title: "The League Internship Programme 2018: Lunch with TED",
        link: "/the-league/lunch-with-ted/",
    },
    {
        id: "9",
        imgsrc: "/images/theleague/internship/journey/9.jpg",
        title: "The League Internship Programme 2018: Closing Ceremony",
        link: "/the-league/closing/",
    },
    {
        id: "8",
        imgsrc: "/images/theleague/internship/journey/8.jpg",
        title: "The League Internship Programme 2018: Islamabad Book Donation Drive",
        link: "/the-league/book-donation-islamabad/",
    },
    {
        id: "7",
        imgsrc: "/images/theleague/internship/journey/7.jpg",
        title: "The League Internship Programme 2018: Karachi Beach Cleaning",
        link: "/the-league/beach-cleaning-karachi/",
    },
    {
        id: "6",
        imgsrc: "/images/theleague/internship/journey/6.jpg",
        title: "The League Internship Programme 2018: Team Bonding Activity",
        link: "/the-league/team-bonding/",
    },
    {
        id: "5",
        imgsrc: "/images/theleague/internship/journey/5.jpg",
        title: "The League Orientation Ceremony 2018",
        link: "/the-league/the-league-orientation-ceremony-2018/",
    },
    {
        id: "4",
        imgsrc: "/images/theleague/internship/journey/4.jpg",
        title: "Summer Internship 2017 Orientation Ceremony",
        link: "/the-league/summer-internship-2017-orientation-ceremony/",
    },
    {
        id: "3",
        imgsrc: "/images/theleague/internship/journey/3.jpg",
        title: "Summer Internship 2017 Closing Ceremony",
        link: "/the-league/summer-internship-2017-closing-ceremony/",
    },
    {
        id: "2",
        imgsrc: "/images/theleague/internship/journey/2.jpg",
        title: "Scavenger Hunt for Summer Interns 2017",
        link: "/the-league/scavenger-hunt-for-summer-interns-2017/",
    },
    {
        id: "1",
        imgsrc: "/images/theleague/internship/journey/1.jpg",
        title: "Lunch & Learn Event for Summer Interns 2017",
        link: "/the-league/lunch-learn-event-for-summer-interns-2017/",
    },
];

export default Interndata;