import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functionislamicbanking = () => {
  return (
    <>
<Helmet>
<title>Islamic Banking | HBL People</title>
<meta
name="description"
content="Be a part of our team of passionate and talented Islamic Banking professionals."
/>
</Helmet>
<section className="innerpagebanner islamic-banking">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Islamic Banking</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Be a part of our team of passionate and talented Islamic Banking professionals</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages islamic-banking">
  <div className="container d-flex flex-wrap gap-3">
    <p>HBL Islamic Banking is a renowned leader in Pakistan's Islamic finance landscape. It offers a comprehensive suite of Shariah-compliant products and services, empowering individuals, families, and businesses to achieve their financial goals while remaining true to their values.</p>
    <p>HBL boasts a long and distinguished history in Islamic banking as one of the first conventional banks in Pakistan to establish a dedicated Islamic Banking division in the year 2000. From a single branch in 2005, the Bank has grown to a vast network of 407 branches by the end of 2023. This growth reflects our commitment to continuous innovation and expanding our offerings to meet customers’ evolving needs. In addition to dedicated branches, HBL Islamic also boasts a network of over 500 Islamic Banking Windows, ensuring convenient access to HBL’s services across Pakistan.</p>
    <p>At HBL Islamic Banking, a deep commitment to Shariah principles is at our core. A dedicated Shariah Compliance team and Shariah Board comprised of renowned Islamic scholars meticulously design and review all the Bank’s products and services. This ensures absolute adherence to Islamic law, eliminating elements like interest (riba) and speculation (gharar) from your financial transactions.</p>
  </div>
</section>
<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functionislamicbanking