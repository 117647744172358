import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Campheader from "../../../../components/Campheader";
import Campheadermob from "../../../../components/Campheadermob";

const Campost42 = () => {
  const images = [
    "/images/theleague/mt/posts/42/1.jpg",
    "/images/theleague/mt/posts/42/2.jpg",
    "/images/theleague/mt/posts/42/3.jpg",
    "/images/theleague/mt/posts/42/4.jpg",
    "/images/theleague/mt/posts/42/5.jpg",
    "/images/theleague/mt/posts/42/6.jpg",
    "/images/theleague/mt/posts/42/7.jpg",
    "/images/theleague/mt/posts/42/8.jpg",
    "/images/theleague/mt/posts/42/9.jpg",
    "/images/theleague/mt/posts/42/10.jpg",
    "/images/theleague/mt/posts/42/11.jpg",
    "/images/theleague/mt/posts/42/12.jpg",
    "/images/theleague/mt/posts/42/13.jpg",
    "/images/theleague/mt/posts/42/14.jpg",
    "/images/theleague/mt/posts/42/15.jpg",
    "/images/theleague/mt/posts/42/16.jpg",
    "/images/theleague/mt/posts/42/17.jpg",
    "/images/theleague/mt/posts/42/18.jpg",
    "/images/theleague/mt/posts/42/19.jpg",
    "/images/theleague/mt/posts/42/20.jpg",
    "/images/theleague/mt/posts/42/21.jpg",
    "/images/theleague/mt/posts/42/22.jpg",
    "/images/theleague/mt/posts/42/23.jpg",
    "/images/theleague/mt/posts/42/24.jpg",
    "/images/theleague/mt/posts/42/25.jpg",
    "/images/theleague/mt/posts/42/26.jpg",
    "/images/theleague/mt/posts/42/27.jpg",
    "/images/theleague/mt/posts/42/28.jpg",
    "/images/theleague/mt/posts/42/29.jpg",
    "/images/theleague/mt/posts/42/30.jpg",
    "/images/theleague/mt/posts/42/31.jpg",
    "/images/theleague/mt/posts/42/32.jpg",
    "/images/theleague/mt/posts/42/33.jpg",
    "/images/theleague/mt/posts/42/34.jpg",
    "/images/theleague/mt/posts/42/35.jpg",
    "/images/theleague/mt/posts/42/36.jpg",
    "/images/theleague/mt/posts/42/37.jpg",
    
  ];
  return (
    <>
      <Helmet>
        <title>IBA Campus Drive 2022 | THE LEAGUE</title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
          <Campheader />
          <h1 className="my-3">IBA Campus Drive 2022</h1>
          <Lightbox images={images} />
          <Campheadermob />
        </div>
      </section>
    </>
  );
};

export default Campost42;
