import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Campheader from "../../../../components/Campheader";
import Campheadermob from "../../../../components/Campheadermob";

const Campost36 = () => {
  const images = [
    "/images/theleague/mt/posts/36/1.jpg",
    "/images/theleague/mt/posts/36/2.jpg",
    "/images/theleague/mt/posts/36/3.jpg",
    "/images/theleague/mt/posts/36/4.jpg",
    "/images/theleague/mt/posts/36/5.jpg",
    "/images/theleague/mt/posts/36/6.jpg",
    "/images/theleague/mt/posts/36/7.jpg",
    "/images/theleague/mt/posts/36/8.jpg",
    "/images/theleague/mt/posts/36/9.jpg",
    "/images/theleague/mt/posts/36/10.jpg",
    "/images/theleague/mt/posts/36/11.jpg",
    "/images/theleague/mt/posts/36/12.jpg",
    "/images/theleague/mt/posts/36/13.jpg",
    
  ];
  return (
    <>
      <Helmet>
        <title>IoBm Career Fair 2020 | THE LEAGUE</title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
          <Campheader />
          <h1 className="my-3">IoBm Career Fair 2020</h1>
          <Lightbox images={images} />
          <Campheadermob />
        </div>
      </section>
    </>
  );
};

export default Campost36;
