import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functioncorporateandinvestmentbanking = () => {
  return (
    <>
    <Helmet>
<title>Corporate Commercial and Investment Banking | HBL People</title>
<meta
name="description"
content="Driving growth to achieve sustainable development of Pakistan’s economy."
/>
</Helmet>
<section className="innerpagebanner corporate-and-investment-banking">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Corporate Commercial and Investment Banking</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Driving growth to achieve sustainable development of Pakistan’s economy. </h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages corporate-and-investment-banking">
  <div className="container d-flex flex-wrap gap-3">
<p>HBL's CCIBG services clients across various segments for all their banking needs. We act as a one window solution for companies through a collaborative model with Treasury, Transaction Banking, Investment Banking, along with offering access to HBL’s suite of consumer and retail banking products to the management and employees of these companies and institutions. </p>
<div>
<h4>Corporate Banking (CBG)</h4>
<p>CBG leverages HBL's extensive onshore and offshore branch network to meet the financial and banking services requirements of top-tier multinational and local clients across all major industries of Pakistan. We lead the way in providing solutions to extend our outreach in the corporate sector by offering transaction-based digitization initiatives, supply chain financing, employee banking, expertise in Shipping, China business, global network along with Investment Banking & Advisory services. We have the largest asset base amongst private commercial banks in Pakistan and our expertise makes us the partner of choice for all corporates operating in Pakistan.</p> 
</div><div>
<h4>Commercial Banking (CB)</h4>
<p>CB targets smaller and medium sized businesses and helps them achieve a robust growth trajectory. Commercial Banking teams provide these clients with personalized services such as structured and customized banking solutions that help enable their businesses to function more efficiently. These solutions encompass various services such as Trade Finance, Long-term debt and Working Capital Finance, Deposit Mobilization, Cash Management and cross-sell of various other banking products. </p>
</div><div>
<h4>Investment Banking Group (IBG)</h4>
<p>IBG acts as a product partner to service clients through its comprehensive and customized corporate finance advisory and distribution arrangement services. During 2022, HBL executed over 20 deals for corporate clients valued for at over PKR 200 billion, across a diverse array of sectors including power, telecom & internet, real estate, road infrastructure & water transportation. We have a strong commitment to uplift Pakistan’s socio-economic environment. Major headway is being made on PPP transactions across water and road infrastructure. Strong ties with multilateral agencies, DFIs and global investment Banks has enabled HBL to be at the forefront of not only domestic but also cross border transactions and allows HBL to have first right of refusal on Project Finance, Capital & Equity Market transactions. </p>
</div><div>
<h4>Global Trade Services (GTS)</h4>
<p>The formation of GTS in 2022 is in line with HBL’s strategic focus to enhance trade and bring efficiencies. We have leveraged our global network along with our strong domestic presence to be recognized amongst the top trade finance banks in the country. We are one of only 3 banks in MENA and South Asia to offer end-to-end RMB intermediation, extending liquidity management and salary remittance services to Chinese clients and expatriate employees.</p> 
</div><div>
<h4>Transaction & Employee Banking (TEB)</h4>
<p>TEB aspires to transform into a global business model by digitizing its customers’ journeys on Receivables Management, Payments, Employee Banking, Trade, Supply Chain and Custody/Security Services; all via a single platform. Groundwork has been laid to rollout our state-of-the-art digital payments’ platform across our international network. Furthermore, TEB offers customized collection solutions such as Book Building services for corporate clients along with automated processes, offering API connectivity for Cash Management Services. TEB holds significant importance in enabling digitalization of Pakistan’s economy. </p> 
</div><div>
<h4>ESG & SEMS</h4>
<p>HBL has a strong commitment to ESG matters pertaining to our lending and business operations. We take measures to protect the environment, reduce our own impact across the Bank’s network and support our clients and communities to enhance their ESG practices. By incorporating ESG and sustainability considerations into our business activities, HBL has become a market leader in the ESG space in Pakistan. This enables us to drive responsible growth and is in line with our strategic initiative of contributing to the sustainable development of Pakistan. SEMS assesses potential impacts of environmental and social risks on financing provided across the Bank’s entire product spectrum, the development of sustainable and green finance and strengthening of resource efficiency within the Bank. It also helps in developing the internal capacity required to support these functions in the bank and create awareness on environment, social and climate change issues and risks associated with it for its external stakeholders.</p> 
</div><div>
<h4>Institutional Sales (I.S.) </h4>
<p>I.S. primarily deals with large public sector organizations which have a national footprint and a diverse array of needs for the organization and its employees. Our capacity as a cross functional unit enables us to increase HBL’s wallet share by cross selling the various products HBL has to offer to these potential customers by customizing solutions to cater to individual requirements.</p>
</div>
</div>
</section>
<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functioncorporateandinvestmentbanking