import React from 'react';
import Heading from "./Heading";
import Images from './Images';

function Journey(props) {
  return (
    <>
    <div className="swiper-slide">
    <div className='camp'>
    <Images imgsrc={props.imgsrc} />
    <Heading title={props.title} link={props.link}/>
    </div>
    </div>
    </>
  )
}

export default Journey