import React from 'react';
import { Helmet } from "react-helmet";
import Footertabagp from '../../components/footertabagp';


const Leagueagphblexperience = () => {
    return (
      <>
    <Helmet>
<title>The HBL Experience | THE LEAGUE</title>
<link rel="stylesheet" href="/css/font.css" />
<link rel="stylesheet" href="/css/theleague.css" />
</Helmet>
<section className="whyhbl-bg">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-4 d-none d-lg-block">
        <a
          href="/the-league/accelerate-graduate-programme"
          className="rthome"
          ><img
            src="/images/theleague/innerpage-back-button-arrow.png"
          /> Accelerate Graduate Programme</a
        >
      </div>
      <div className="col-lg-4">
        <a href="/the-league"
          ><img
          src="/images/theleague/the-league-logo.png"
          className="img-fluid d-block mx-auto"
        /></a>
      </div>
      <div className="col-lg-4 d-none d-lg-block text-end">
        <a href="/the-league/" className="rthome"> Return to Home <img
            src="/images/theleague/innerpage-forward-button-arrow.png"
        /></a>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12 col-lg-12">
        <div className="im-banner text-center">
          <h2>The HBL Experience</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-5 col-lg-5">
        <p>
          <img
            src="/images/theleague/mt/hbl-aboutus-stats.jpg"
            className="img-responsive"
          />
        </p>
      </div>
      <div className="col-md-7 col-lg-7">
        <p>
          We at HBL are committed to building the potential of our employees
          since we first opened our doors in 1941. After moving to Pakistan in
          1947, HBL experienced continuous growth. With the hard work and
          dedication of our employees, HBL has grown to become Pakistan's
          largest private bank.
        </p>
        <p>
          As a member of the Bank, you'll have the chance to build relationships
          with people from all over the country, across a variety of industries.
          The skills you will acquire and the relationships you will foster here
          will enable you to develop and shape your career in the corporate
          world.
        </p>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12 col-lg-12">
        <h3>Banking Beyond Borders</h3>
        <p>
          HBL is a local bank that operates with a global perspective, and this
          is a mindset we encourage in all our employees. Our teams perform a
          variety of banking and non-banking functions that extend beyond
          borders.
        </p>
        <p>
          HBL operates in the following countries, with varying degrees of
          association:
        </p>
        <p>
          <br />
          <img
            src="/images/theleague/mt/Desktopn_1.png"
            className="img-fluid mx-auto d-none d-lg-block "
          />
          <img
            src="/images/theleague/mt/single_colmn.png"
            className="img-fluid mx-auto d-block d-lg-none "
          />
        </p>
      </div>
    </div>
    <div className="row my-4">
      <div className="col-md-12 col-lg-12">
        <p>
          With currently the largest Corporate Banking portfolio in the country
          and an active Investment Banking arm, HBL has developed a reputation
          for working with world class organizations and enabling their
          continued success. HBL is also the leading bank on the consumer front.
          The Bank's Retail &amp; Consumer banking businesses are amongst the
          largest in the market. With a product portfolio spanning business
          accounts, saving accounts, branchless banking accounts, car loans and
          credit cards, HBL has something to offer all its clients. Financial
          technology and innovation is also a core part of the Bank's
          overarching business strategy.
        </p>
      </div>
    </div>
    <div className="row d-flex d-lg-none text-center my-3">
      <div className="col">
      <a
          href="/the-league/accelerate-graduate-programme"
          className="rthome"
          ><img
            src="/images/theleague/innerpage-back-button-arrow.png"
          />  Accelerate Graduate Programme</a
        >
      </div>
      <div className="col ">
        <a href="/the-league/" className="rthome">
          Return to Home <img
            src="/images/theleague/innerpage-forward-button-arrow.png"/></a>
      </div>
    </div>
  </div>
  <Footertabagp/>
</section>

</>
  )
}

export default Leagueagphblexperience;