import React, { useEffect , useLayoutEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const Layout2 = ({ children }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      console.clear();
    }, 5000); 
    // 5000 milliseconds = 5 seconds
    document.body.classList.add('theleague');
    return () => clearTimeout(timeout);
  }, []);

  useLayoutEffect(() => {
    document.body.classList.add('theleague');
    
    return () => {
      document.body.classList.add('theleague');
    };
  }, []);

  return (
    <>
      <Helmet>
      <link rel="stylesheet" href="/css/font.css" defer={false}/>
        <link rel="stylesheet" href="/css/jdialog.min.css" defer={false}/> 
        <link rel="stylesheet" href="/css/theleague.css" defer={false} />
        <script>window.sr = new scrollReveal();</script>
      </Helmet>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout2;
