import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functioninformationsecurity = () => {
  return (
    <>
<Helmet>
<title>Information Security | HBL People</title>
<meta
name="description"
content="Protecting the valuable client assets with critical information"
/>
</Helmet>
<section className="innerpagebanner information-security">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Information Security</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Protecting the valuable client assets with critical information </h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages information-security">
  <div className="container d-flex flex-wrap gap-3">
    <p>Information Security is a critical business function and an essential component of governance affecting all aspects of the business environment. Information Security plays a pivotal role in protecting the Bank’s data assets from both internal and external threats through effective cyber security risk management, supported by preventive and detective controls capable of responding to emerging external threats. In the environment of growing cyber threat, HBL continues to strengthen the Bank’s cyber defense mechanism by utilizing effective preventive and detective information security measures to counter evolving cyber challenges. These mechanisms allow HBL to offer innovative digital solutions to its clients while ensuring them protection, confidence, and peace of mind.</p>
    <p>HBL is the amongst the very few banks to have attained PCI DSS certification which is the de facto security standard for the Payment Card Industry. HBL also achieved ISO27001 international information security related certification.</p>
  </div>
</section>
<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functioninformationsecurity