import React from "react";
import { Helmet } from "react-helmet";

const Faq = () => {
  return (
    <>
      <Helmet>
        <title>Faqs | HBL People</title>
        {/* <meta
name="description"
content="A Global community working towards a Common Purpose."
/> */}
      </Helmet>
      <section className="innerpagebanner culture faq">
        <div className="container">
          <div className="in">
            <h1 data-sr="enter top left 0.3s and move 65px wait .3s">Frequently Asked Questions</h1>
            <h2 data-sr="enter top left 0.3s and move 65px wait .6s">
            Find the Answers You Need
            </h2>
          </div>
        </div>
      </section>
      <section className="innersection footprint faq">
        <div className="container">
        <div className="accordion" id="accordionfaq">
        <div className="accordion-item">
          <h2 className="accordion-header" id="head1">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
              What are the day-to-day tasks of a Cash Officer/Teller?
            </button>
          </h2>
          <div id="collapse1" className="accordion-collapse collapse" aria-labelledby="head1" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
             <p>As a Cash Officer at HBL, your day-to-day may vary depending on the specific branch or location, but they generally encompass the core responsibilities of a Cash Officer at HBL. For further details and to apply, please visit: Cash Officers Careers (oraclecloud.com)</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head2">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
              What are the day-to-day tasks of a Relationship Manager?
            </button>
          </h2>
          <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="head2" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>As a Relationship Manager at HBL, your day-to-day tasks may require strong interpersonal skills, financial acumen, and the ability to tailor banking solutions to meet the diverse needs of clients at HBL. For details and to apply, please visit: https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12004 </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head3">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
              What are the day-to-day tasks of a Branch Manager?
            </button>
          </h2>
          <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="head3" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p> As a Branch Manager at HBL, your day-to-day tasks may require effective leadership, strong communication skills, and financial acumen are essential for success as a Branch Manager at HBL.For details or to apply , please visit https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12003 </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head4">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
              What is a female returnship / Waapsi Program
            </button>
          </h2>
          <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="head4" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p> A female returnship program, also known as a "Waapsi" program, is designed to help women who have taken a break from their careers re-enter the workforce. This program offers structured training, mentorship, networking opportunities, and sometimes flexible work arrangements to support women in transitioning back into their careers after a hiatus. The goal is to provide support, upskilling, and resources to help women regain confidence and advance in their professional lives. For details and to apply, please visit : https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12006 </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head5">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
              Do I need prior banking experience for any role including Cash Officer,  Relationship Manager or Branch Manager role?
            </button>
          </h2>
          <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="head5" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p> For many roles at HBL, prior banking experience is not required. For instance, entry-level positions like Cash Officer are open to fresh graduates with little to no experience. However, some managerial or specialized roles such as Branch Managers , Relationship Managers , may require relevant experience, typically around 2-5 years. It's best to check the specific job descriptions for each position to understand the requirements. </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head6">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
              Is there an age limit for job applicants?
            </button>
          </h2>
          <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="head6" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>"HBL does not have a universally defined age limit for job applicants. However, the specific requirements can vary depending on the role and level of experience required. Generally, entry-level positions may be more open to younger candidates, while senior or managerial roles may expect candidates to have several years of experience, which could imply a higher age range. </p>

             <p> the most accurate information, it is best to refer to the specific job postings on HBL's official careers page. They can provide detailed information regarding any age-related criteria for particular positions. For details please visit: https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head7">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
              Can I apply for multiple roles simultaneously?
            </button>
          </h2>
          <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="head7" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>Yes, you can. </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head8">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
              What happens if I miss the deadline?
            </button>
          </h2>
          <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="head8" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>If you have missed the application deadline for a job at HBL, it may limit your chances of being considered for that specific position. However, you can keep a check if the deadline extends. Meanwhile you can explore other opportunities at https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005 </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head9">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
              How can I track the status of my application?
            </button>
          </h2>
          <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="head9" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>If you have already been contacted by the HBL Team, you can consider contacting the respective HR representative again . Also, keep an eye on your email for any updates from HBL regarding your application status. We may send notifications about the progress of your application or next steps in the hiring process. </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head10">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
              Are there any fees associated with the application or test?
            </button>
          </h2>
          <div id="collapse10" className="accordion-collapse collapse" aria-labelledby="head10" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>No, there arent. </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head11">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
              Can I reapply if I have been rejected before?
            </button>
          </h2>
          <div id="collapse11" className="accordion-collapse collapse" aria-labelledby="head11" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>Ofcourse you can.  Please visit us at : https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005 to explore other opportunities </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head12">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
              What documents do I need to submit?
            </button>
          </h2>
          <div id="collapse12" className="accordion-collapse collapse" aria-labelledby="head12" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>Minimal documents are required which include your identification and educational documents and employment record (if any). In the event of any area or job related requirement, your HR Recruiter will inform you accordingly.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head13">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
              I don’t have a candidate ID. How can I create a candidate ID?
            </button>
          </h2>
          <div id="collapse13" className="accordion-collapse collapse" aria-labelledby="head13" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>It would be very convenient for us to stay in touch if you have a candidate ID. Don’t worry, you can generate one by creating your profile at:  General Profile Creation - HBL People Careers (oraclecloud.com). Once you have successfully submitted your profile, your candidate ID will be communicated to you through an email.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head14">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
              I don’t remember or know my candidate ID. How can I find out my candidate ID?
            </button>
          </h2>
          <div id="collapse14" className="accordion-collapse collapse" aria-labelledby="head14" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>It would be very convenient for us to stay in touch if you have a candidate ID. Don’t worry, you can try logging in at with the email address you have previously created your profile with at Manage Your Profile - HBL People Careers (oraclecloud.com) and you will be guided the steps further on how to manage your profile. You can also check your email inbox for the previous communication with you in this regard.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head15">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapse15">
              What type of questions are included in the assessment?
            </button>
          </h2>
          <div id="collapse15" className="accordion-collapse collapse" aria-labelledby="head15" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>The assessment at HBL typically includes a combination of questions that evaluate various skills, knowledge, and competencies based on the role which you are applying for. Be prepared to demonstrate your skills, knowledge, and fit with HBL's culture and values.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head16">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
              Are calculators or other tools allowed during the test?
            </button>
          </h2>
          <div id="collapse16" className="accordion-collapse collapse" aria-labelledby="head16" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>Before the assessment begins, clear instructions regarding what is and isn’t allowed will be provided. It's essential to read these guidelines carefully to avoid any misunderstandings. Please check any official communications you have received about the assessment.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head17">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapse17">
              How soon will I receive my test results?
            </button>
          </h2>
          <div id="collapse17" className="accordion-collapse collapse" aria-labelledby="head17" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>If you have cleared the assessment you will be contacted by HR as guided at the end of the assessment </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head18">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse18" aria-expanded="false" aria-controls="collapse18">
              Will there be a group discussion round in the selection process?
            </button>
          </h2>
          <div id="collapse18" className="accordion-collapse collapse" aria-labelledby="head18" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>Whether there will be a group discussion round in the selection process at HBL, can depend on the specific role you are applying for and the overall recruitment process followed. </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head19">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse19" aria-expanded="false" aria-controls="collapse19">
              Is the test available in different languages?
            </button>
          </h2>
          <div id="collapse19" className="accordion-collapse collapse" aria-labelledby="head19" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>Yes you can opt to attempt the assessment in either English or Urdu.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head20">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse20" aria-expanded="false" aria-controls="collapse20">
              How to register for an assessment?
            </button>
          </h2>
          <div id="collapse20" className="accordion-collapse collapse" aria-labelledby="head20" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>If you are fresh graduate, once you apply, you will be notified through an email if you need to attend the assessment.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head21">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapse21">
              I am unable to attempt the gamified assessment / test?
            </button>
          </h2>
          <div id="collapse21" className="accordion-collapse collapse" aria-labelledby="head21" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>Please refer to the guidelines mentioned in the email.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head22">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapse22">
              The link of the test / gamified assessment is not working
            </button>
          </h2>
          <div id="collapse22" className="accordion-collapse collapse" aria-labelledby="head22" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>Please reach out to HBL's HR representative whom you have been in touch with or check the communications you received regarding the assessment for detailed information.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head23">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse23" aria-expanded="false" aria-controls="collapse23">
              I am unable to track or find the email of the gamified assessment
            </button>
          </h2>
          <div id="collapse23" className="accordion-collapse collapse" aria-labelledby="head23" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>You can refer to any communications you have received regarding the assessment for detailed information, in some cases, it might have landed into your junk / spam email folder. Please check relevant folders and refer to the communication.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head24">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse24" aria-expanded="false" aria-controls="collapse24">
              What is the process of selection at HBL?
            </button>
          </h2>
          <div id="collapse24" className="accordion-collapse collapse" aria-labelledby="head24" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>At HBL, we have a structured selection process that includes multiple stages, such as an initial screening, assessment tests (for fresh graduates), interviews, and possibly group discussions or panel interviews, which depends on the role you are applying for. Once HR contacts you, they will brief you over the process / next steps.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head25">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse25" aria-expanded="false" aria-controls="collapse25">
              What is the Turnaround time (TAT) for hiring process? How long does the hiring process or entire recruitment process take?
            </button>
          </h2>
          <div id="collapse25" className="accordion-collapse collapse" aria-labelledby="head25" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>The turnaround time (TAT) for the hiring process at HBL varies based on the role which you are applying for. In trainee program or batch hiring, the timelines are specified. However. for any other role, the duration includes the completion of various steps as per the bank's policies and requirements.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head26">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse26" aria-expanded="false" aria-controls="collapse26">
              Can I drop my resume at HBL?
            </button>
          </h2>
          <div id="collapse26" className="accordion-collapse collapse" aria-labelledby="head26" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>The applications are accepted online through our HBLPeople.com. You can explore all the open opportunities at : https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005. </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head27">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse27" aria-expanded="false" aria-controls="collapse27">
              How can I sign my offer letter?
            </button>
          </h2>
          <div id="collapse27" className="accordion-collapse collapse" aria-labelledby="head27" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>If you are selected Congratulations! Our HR will send you an official offer letter. This may be sent via email or through a physical copy, depending on the process. Your Talent Acquisition advisor will inform you.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head28">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse28" aria-expanded="false" aria-controls="collapse28">
              How long is the training period for new hires?
            </button>
          </h2>
          <div id="collapse28" className="accordion-collapse collapse" aria-labelledby="head28" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>"The training period would depend on the specific role and department. During this time, new employees undergo various training programs designed to help them understand the bank's operations, policies, and culture.

                The training may include:
                <ol>
                <li> Orientation Sessions: Introduction to the bank's values, mission, and organizational structure.</li>
                <li> On-the-Job Training: Practical training in specific tasks and responsibilities related to the job.</li>
                <li>Skill Development: Workshops and courses aimed at enhancing professional skills relevant to the role.</li>
                <li>Mentorship: Pairing with experienced employees for guidance and support.</li>
              </ol>
                It’s always a good idea to confirm the exact duration and structure of the training program with the HR Function or your hiring manager, as it may vary based on the position and departmental needs."
                </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head29">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse29" aria-expanded="false" aria-controls="collapse29">
              Where will the training for a new employee be conducted?
            </button>
          </h2>
          <div id="collapse29" className="accordion-collapse collapse" aria-labelledby="head29" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>The training for new hires at HBL is typically conducted at various locations, depending on the nature of the training and the specific role. This can be a a combination of classroom, online / e-learning and on-the-job exposure.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head30">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse30" aria-expanded="false" aria-controls="collapse30">
              Where should I report on my first day?
            </button>
          </h2>
          <div id="collapse30" className="accordion-collapse collapse" aria-labelledby="head30" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>Starting a new job is an exciting experience and being prepared for your first day at HBL can help you make a positive impression. All the requirements are mentioned in the email shared with you with your joining details. All the best!</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head31">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse31" aria-expanded="false" aria-controls="collapse31">
              Can I request a different joining date if needed?
            </button>
          </h2>
          <div id="collapse31" className="accordion-collapse collapse" aria-labelledby="head31" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>Sure, please reach out to the HR Onboarding department or the hiring manager, It’s best to do this as soon as you know you need to change your joining date.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head32">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse32" aria-expanded="false" aria-controls="collapse32">
              What happens if I am unable to join on the given date?
            </button>
          </h2>
          <div id="collapse32" className="accordion-collapse collapse" aria-labelledby="head32" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>Please reach out to the HR Onboarding department or the hiring manager, It’s best to do this as soon as you know you need to change your joining date.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head33">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse33" aria-expanded="false" aria-controls="collapse33">
              What training programs does HBL offer for career growth?
            </button>
          </h2>
          <div id="collapse33" className="accordion-collapse collapse" aria-labelledby="head33" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>HBL strives to create an engaged, high-performing workforce where constant learning and growth are paramount. Visit our Learning and Development page to learn more: https://hblpeople.com/learning </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head34">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse34" aria-expanded="false" aria-controls="collapse34">
              Are there any certifications that HBL provides?
            </button>
          </h2>
          <div id="collapse34" className="accordion-collapse collapse" aria-labelledby="head34" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>HBL recognizes the importance of continuous professional development and may offer various certifications and training programs to enhance the skills and knowledge of its employees. While specific certifications can vary based on the job requirements, do explore our updates at https://hblpeople.com/learning, once you are onboarded. </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head35">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse35" aria-expanded="false" aria-controls="collapse35">
              Does HBL sponsor further education or professional courses?
            </button>
          </h2>
          <div id="collapse35" className="accordion-collapse collapse" aria-labelledby="head35" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>HBL recognizes the importance of continuous professional development and may offer various certifications and training programs to enhance the skills and knowledge of its employees. While specific certifications can vary based on the job requirements, do explore our updates at https://hblpeople.com/learning, once you are onboarded. </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head36">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse36" aria-expanded="false" aria-controls="collapse36">
              Can I choose the branch location I prefer to work at?
            </button>
          </h2>
          <div id="collapse36" className="accordion-collapse collapse" aria-labelledby="head36" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>When applying for a position, you may have the opportunity to submit application for your preferred location. Please do visit : https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005 for relevant opportunities.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head37">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse37" aria-expanded="false" aria-controls="collapse37">
              What should I do if my application portal is not working?
            </button>
          </h2>
          <div id="collapse37" className="accordion-collapse collapse" aria-labelledby="head37" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>"Sometimes we do have planned downtimes due to maintenance and periodic upgrade to enhance the customer experience. However, if you are experiencing issues with the application portal, here are some steps you can take to resolve the problem:

              <ol>
                <li>Check the stability of your internet connection</li>
                <li>Clear browser cache and cookies</li>
                <li> Disable browser extensions</li>
                <li>Try a different device</li>
              </ol>
                
                By following these steps, you should be able to troubleshoot the issue with the application portal and get the assistance you need to complete your application process."
                </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head38">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse38" aria-expanded="false" aria-controls="collapse38">
              Does HBL support part-time, remote, work from home or hybrid work arrangements?
            </button>
          </h2>
          <div id="collapse38" className="accordion-collapse collapse" aria-labelledby="head38" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>HBL recognizes the importance of flexible work arrangements and supports various options, including remote, work from home, and hybrid models, based on the requirments of the role. These arrangements are designed to accommodate the diverse needs of employees while maintaining productivity and collaboration. </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head39">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse39" aria-expanded="false" aria-controls="collapse39">
              What is the retirement age at HBL?
            </button>
          </h2>
          <div id="collapse39" className="accordion-collapse collapse" aria-labelledby="head39" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>The retirement age at HBL is set at 60 years.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="head40">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse40" aria-expanded="false" aria-controls="collapse40">
              What is the difference between Retirement and Service Age?
            </button>
          </h2>
          <div id="collapse40" className="accordion-collapse collapse" aria-labelledby="head40" data-bs-parent="#accordionfaq">
            <div className="accordion-body">
              <p>The retirement age at HBL is set at 60 years, whereas HBL has raised its service age to 65 years. For more detailed information about the benefits offered, you can visit HBL Employee Benefits, https://hblpeople.com/benefits </p>
            </div>
          </div>
        </div>

      </div> 
        </div>
      </section>
    </>
  );
};

export default Faq;
