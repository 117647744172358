import React, { useEffect } from "react";
import "magnific-popup/dist/magnific-popup.css";
import "magnific-popup/dist/jquery.magnific-popup";
import $ from "jquery";
import { Helmet } from "react-helmet";

const Culture = () => {
  useEffect(() => {
  const learning = new window.Swiper(".singleSwiper", {
    slidesPerView: 1,
    spaceBetween: 1,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });
  $('.youtubepopup').magnificPopup({
    type: 'iframe',
    iframe: {
      patterns: {
        wistia: {
          index: 'wistia.com',
          id: function(url) {        
              var m = url.match(/^.+wistia.com\/(medias)\/([^_]+)[^#]*(#medias=([^_&]+))?/);
              if (m !== null) {
                  if(m[4] !== undefined) {
                      return m[4];
                  }
                  return m[2];
              }
              return null;
          },
          src: '//fast.wistia.net/embed/iframe/%id%?autoPlay=0'
        }
      }
    }
  });
}, []);
  return (
    <>
    <Helmet>
<title>Our Culture | HBL People</title>
<meta
name="description"
content="Inspires and breeds excellence"
/>
</Helmet>
      <section className="innerpagebanner culture">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top left 0.3s and move 65px wait .8s">OUR CULTURE</h1>
      <h2 data-sr="enter bottom left 0.3s and move 65px wait 1s">Inspires and breeds excellence</h2>
    </div>
  </div>
</section>
<section className="innersection culture">
  <div className="container">
    <div className="text-center mb-5">
      <h3 className="web" data-sr="enter top left 0.3s and move 65px wait .3s">More than just a Bank! </h3>
      <h2 className="web" data-sr="enter bottom left 0.6s and move 65px wait .3s">Step into a world of possibilities at HBL</h2>
      <p data-sr="enter bottom left 1.2s and move 65px wait .3s">A culture that enables you in reaching your true potential and embarking on new challenges. At HBL you will be driven by our five core values as your guiding light; you will learn, engage, create and thrive.</p>
    </div>
    <div className="swiper singleSwiper">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className="container culturesliderbga">
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-7">
                <div className="in">
                  <h4>Diversity, Equity &amp; Inclusion</h4>
                  <p>DEI has never been a tick in the box for HBL; it is the bedrock of HBL's growth and business outcomes giving us a wider range of perspectives, experiences and skillsets. For us DEI is not limited to gender, but also extends to disability, education and culture, to name a few. We have also been recognized as The Most Inclusive Organization of 2022, having won awards in all 15 categories for Global Diversity, Equity & Inclusion Benchmarks (GDEIB). </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="container culturesliderbg2a">
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-7">
                <div className="in">
                  <h4>Patron of Art</h4>
                  <p>As a patron of Art in Pakistan, we are committed to encouraging opportunities for bridging the gap between art and the nation through our phenomenal art collection, collaboration with Pakistani artists and conversations about art through an interactive series. We believe that art is a phenomenon, which plays a critical role in the lives of people, in society and in shaping civilization.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-button-next"></div>
      <div className="swiper-button-prev"></div>
      <div className="swiper-pagination"></div>
    </div>
  </div>
</section>
<section className="innersection culture heart">
  <div className="container">
    <div className="text-center mb-5">
      <h3 className="web" data-sr="enter top left 0.3s and move 65px wait .3s">OUR VALUES</h3>
      <h2 className="web" data-sr="enter bottom left 0.3s and move 65px wait .3s">HEART OF EVERYTHING THAT WE DO</h2>
      <p data-sr="enter bottom left 0.3s and move 65px wait .3s">Our values are the fundamental principles that define our Culture. We live Our values to the Core; Evidently Our attitudes and behavior are reflection of them to the Finest.</p>
    </div>
    <div className="row row-cols-1 row-cols-md-3 row-cols-lg-5 g-3 text-center g-5">
      <div className="col">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait .3s">
          <div className="inimage"> <img src="images/culture/icon-be-best.png" alt="" className="a"/> <img src="images/culture/icon-be-best-white.png" alt="" className="b"/> </div>
          <h4 className="mt-3 mb-2">Excellence</h4>
          <p>Be Your Best - We lead, own, adhere and relentlessly pursue quality without compromise</p>
        </div>
      </div>
      <div className="col">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait .6s">
          <div className="inimage"> <img src="images/culture/icon-be-ethical.png" alt="" className="a"/> <img src="images/culture/icon-be-ethical-white.png" alt="" className="b"/> </div>
          <h4 className="mt-3 mb-2">Integrity</h4>
          <p>Be Ethical and Fair - We are honest, committed and prudent in our interactions.</p>
        </div>
      </div>
      <div className="col">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait 1.2s">
          <div className="inimage"> <img src="images/culture/icon-customer.png" alt="" className="a"/> <img src="images/culture/icon-customer-white.png" alt="" className="b"/> </div>
          <h4 className="mt-3 mb-2">Customer Centric</h4>
          <p>Deliver Great Experiences - We believe in providing exceptional service to all we serve</p>
        </div>
      </div>
      <div className="col">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait .6s">
          <div className="inimage"> <img src="images/culture/icon-value.png" alt="" className="a"/> <img src="images/culture/icon-value-white.png" alt="" className="b"/> </div>
          <h4 className="mt-3 mb-2">Value People </h4>
          <p>Respect, Empower, Appreciate - We encourage, collaborate, recognize and celebrate success of our people</p>
        </div>
      </div>
      <div className="col">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait .9s">
          <div className="inimage"> <img src="images/culture/icon-innovate.png" alt="" className="a"/> <img src="images/culture/icon-innovate-white.png" alt="" className="b"/> </div>
          <h4 className="mt-3 mb-2">Progressive </h4>
          <p>Innovate and Challenge - We evolve, positively impact and serve the communities in which we live</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="innersection culture empower">
  <div className="container">
    <div className="text-center mb-5">
      <h3 className="web" data-sr="enter top left 0.3s and move 65px wait .3s">LIFE AT HBL</h3>
      <h2 className="web" data-sr="enter top left 0.3s and move 65px wait .6s">Empowering People, Enriching Lives</h2>
    </div>
    <div className="row">
      <div className="col-lg-3">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait .3s"> <img src="images/culture/our-culture.jpg" alt=""/>
          <h4 className="mt-3 mb-2">Our Culture</h4>
          <p>The most inclusive environment fostering positive relationships and opportunities to excel based on performance exclusively. </p>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait .6s"> <img src="images/culture/workplace.jpg" alt=""/>
          <h4 className="mt-3 mb-2">Dynamic Workplace</h4>
          <p>Ever evolving and agile environment to invent, create and set new standards yet with a flexibility to unwind and recharge at ease.</p>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait .9s"> <img src="images/culture/lighter-side.jpg" alt=""/>
          <h4 className="mt-3 mb-2">The Lighter Side</h4>
          <p>At HBL, we understand that it is not always work, it is about wellbeing, exchanging smiles and growing together. </p>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait 1.2s"> <img src="images/culture/not-all-work.jpg" alt=""/>
          <h4 className="mt-3 mb-2">Coaching & Mentoring</h4>
          <p>At HBL we take pride in ourselves for creating a safe space and closely knitted community in helping each other achieve their goals and make dreams come true with continued guidance and support.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="innersection culture hblraabta">
  <div className="container">
    <div className="text-center mb-5">
      <h3 className="web" data-sr="enter top left 0.3s and move 65px wait .3s">HBL RAABTA</h3>
      <h2 className="web" data-sr="enter top left 0.3s and move 65px wait .6s">WE PRIORITIZE YOUR WELLBEING</h2>
      <p data-sr="enter top left 0.3s and move 65px wait .9s">We invest in our people to build resilience and inculcate a state of safety, health and happiness. At HBL, you will have access to a comprehensive Employee Assistance Program to cater to all your needs because we treat your well-being as an indispensable business input. In order to spread awareness and emphasize on the importance, a comprehensive campaign has been launched to cement HBL Raabta’s existence through;</p>

            

    </div>
    <div className="row">
		<div className="col-lg-6">
		  <div data-sr="enter top left 0.3s and move 65px wait .6s">
		  <ul className="my-3">
            <li>Leaders personally endorsing ‘Wellbeing at HBL’ through an impactful video </li>
            <li>Informative posts for the Bank’s employees on available offerings</li>
            <li>Marking #WorldMentalHealthDay bringing counselling, wellness and health services under one roof through regular counselling sessions, well-being workshops, in-house medical advisory services along with access to online health services as well.</li>
          </ul>
			  <a href="https://hblpeople.wistia.com/medias/4jb4b2x10d" className="link youtubepopup"><img src="images/testimonial-vidicon.png" alt=""/> Well Being at Work</a>
		  </div>
        
      </div>
		<div className="col-lg-6">
        <div data-sr="enter top left 0.3s and move 65px wait .6s">
			<img src="images/culture/info-01.png" className="img-fluid" alt=""/>         
			
        </div>
      </div>
      
      
    </div>
  </div>
</section>
<section className="innersection culture hblraabta">
  <div className="container">
    <div className="text-center mb-5">
      <h3 className="web" data-sr="enter top left 0.3s and move 65px wait .3s">EMPLOYEE VOLUNTEERING PROGRAM</h3>
      <h2 className="web" data-sr="enter top left 0.3s and move 65px wait .6s">Giving Back to the Community </h2>
      <p data-sr="enter top left 0.3s and move 65px wait .9s">While we undoubtedly have a strong standing as a market leader in the industry; we are also a #BankWithASoul. Upon joining us, you become a torch-bearer of this commitment as well. The Bank’s Employee Volunteering Program does more than just motivate and enable our people in creating value for the community, It goes beyond and fosters a deeper sense of connection with the Bank. We encourage the social contact aspect of helping and working with others as it helps our people develop a solid support system. </p>
    </div>
	  <div style={{maxWidth: '800px'}} className="d-block mx-auto">
	<img src="images/culture/info-02.png" className="img-fluid mb-4" alt="" data-sr="enter top left 0.3s and move 65px wait .6s"/>
	</div>
    <div className="row">
      <div className="col-lg-6">
       <div style={{maxWidth: '550px'}} className="d-block mx-auto"><img src="images/culture/collage.png" className="img-fluid" alt=""/></div>
      </div>
      <div className="col-lg-6">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait .6s">
			<p className="mt-3 mb-2">Several initiatives have been taken and efforts have been put together to extended support through;</p>
          <ul className="mb-3">
            <li>Blood and other donation drives</li>
            <li>Clean-up drives</li>
            <li>Eye Surgical Camps</li>
            <li>Collaborative employee Volunteering Sessions at schools, orphanages, hospitals such as SOS Village , DIL, FixIt schools and TCF.</li>
            <li>Tree Plantation </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<div className="container my-5">
<div className="d-block mx-auto" style={{maxWidth: '800px'}}>
	<img src="images/culture/info-03.png" className="img-fluid" alt="" data-sr="enter top left 0.3s and move 65px wait .9s"/>
	</div>
</div>
    </>
  )
}

export default Culture
