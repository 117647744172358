import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Campheader from "../../../../components/Campheader";
import Campheadermob from "../../../../components/Campheadermob";

const Campost38 = () => {
  const images = [
    "/images/theleague/mt/posts/38/1.jpg",
    "/images/theleague/mt/posts/38/2.jpg",
    "/images/theleague/mt/posts/38/3.jpg",
    "/images/theleague/mt/posts/38/4.jpg",
    "/images/theleague/mt/posts/38/5.jpg",
    "/images/theleague/mt/posts/38/6.jpg",
    "/images/theleague/mt/posts/38/7.jpg",
    "/images/theleague/mt/posts/38/8.jpg",
    "/images/theleague/mt/posts/38/9.jpg",
    "/images/theleague/mt/posts/38/10.jpg",
    "/images/theleague/mt/posts/38/11.jpg",
    "/images/theleague/mt/posts/38/12.jpg",
    "/images/theleague/mt/posts/38/13.jpg",
    "/images/theleague/mt/posts/38/14.jpg",
    "/images/theleague/mt/posts/38/15.jpg",
    "/images/theleague/mt/posts/38/16.jpg",
    "/images/theleague/mt/posts/38/17.jpg",
    "/images/theleague/mt/posts/38/18.jpg",
    "/images/theleague/mt/posts/38/19.jpg",
    "/images/theleague/mt/posts/38/20.jpg",
    "/images/theleague/mt/posts/38/21.jpg",
    "/images/theleague/mt/posts/38/22.jpg",
    "/images/theleague/mt/posts/38/23.jpg",
    "/images/theleague/mt/posts/38/24.jpg",
    "/images/theleague/mt/posts/38/25.jpg",
    "/images/theleague/mt/posts/38/26.jpg",
    "/images/theleague/mt/posts/38/27.jpg",
    "/images/theleague/mt/posts/38/28.jpg",
    "/images/theleague/mt/posts/38/29.jpg",
    "/images/theleague/mt/posts/38/30.jpg",
    "/images/theleague/mt/posts/38/31.jpg",
    "/images/theleague/mt/posts/38/32.jpg",
    "/images/theleague/mt/posts/38/33.jpg",
    "/images/theleague/mt/posts/38/34.jpg",
    "/images/theleague/mt/posts/38/35.jpg",
    "/images/theleague/mt/posts/38/36.jpg",
    "/images/theleague/mt/posts/38/37.jpg",
    
  ];
  return (
    <>
      <Helmet>
        <title>IoBM campus drive 2020 | THE LEAGUE</title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
          <Campheader />
          <h1 className="my-3">IoBM campus drive 2020</h1>
          <Lightbox images={images} />
          <Campheadermob />
        </div>
      </section>
    </>
  );
};

export default Campost38;
