import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Learning = () => {
    useEffect(() => {
        const learning = new window.Swiper(".singleSwiper", {
            slidesPerView: 1,
            spaceBetween: 1,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
          });
    
      }, []);
  return (
    <>
    <Helmet>
<title>Learning &amp; Development | HBL People</title>
<meta
name="description"
content="Enabling Talent Development"
/>
</Helmet>
      <section className="innerpagebanner learning">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">LEARNING &amp; DEVELOPMENT</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Enabling Talent Development</h2>
    </div>
  </div>
</section>
<section className="innersection learning">
  <div className="container">
    <div className="text-center mb-5">
      <h3 className="web" data-sr="enter top over 0.3s and move 65px wait 0.3s">Providing world class training </h3>
      <h2 className="web" data-sr="enter top over 0.3s and move 65px wait 0.6s">tools to grow and excel</h2>
      <p data-sr="enter top over 0.3s and move 65px wait 0.9s">At HBL, we believe each employee has their own unique potential to offer. HBL, therefore, strives to create an engaged, high-performing workforce where constant learning and growth are paramount. To this end, HBL has developed its own Learning and Development department; a space for creative collaborative trainings backed by industry’s best practices to upskill and reskill staff, and to provide open opportunities and facilities for enhanced performance and productivity.</p>
    </div>
    <div className="swiper singleSwiper">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className="container learningsliderbga">
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-7">
                <div className="in">
                  <h4>Truly World Class Interventions</h4>
                  <p>Training that is need based and customized to HBL employees, developed in partnership with local and international world-class industry experts. At HBL, we set the bar high by meeting international standards for learning and development. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="container learningsliderbga bb">
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-7">
                <div className="in">
                  <h4>Immersive Onboarding</h4>
                  <p>HBL is the first bank in Pakistan to provide an exciting immersive onboarding experience. Every new employee embarks on a virtual tour journey of discovering HBL. It is a virtual meet and greet to connect with the HBL community and to understand HBL’s vision, mission and values. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
		  <div className="swiper-slide">
          <div className="container learningsliderbga hh">
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-7">
                <div className="in">
                  <h4>HBL Nexus, Digital Learning Academy</h4>
                  <p>HBL Nexus, our cutting-edge digital learning academy, is designed to propel employees into the forefront of the digital era. At HBL, we prioritize staying ahead in an ever-evolving digital landscape, and HBL Nexus stands as a testament to our dedication. HBL Nexus provides diverse training sessions, covering foundational to advanced digital domains like robotics, AI, programming, and digital currency. These comprehensive programs ensure active participation in shaping our digital future for every individual. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="container learningsliderbga cc">
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-7">
                <div className="in">
                  <h4>Embedded Education</h4>
                  <p>Facilitating learning on the job and in the flow of work with hands on and practical tools to deepen the understanding with high intent and opportunity to practice. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
		  
        
		  <div className="swiper-slide">
          <div className="container learningsliderbga ii">
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-7">
                <div className="in">
                  <h4>Learning &amp; Development Impact</h4>
                  <p>Learning never stops at HBL. All employees have access to the knowledge bank and resources available at Learning and Development, giving them room and opportunities to grow on their own and with their teammates. </p>
                  <div className="aspire">
					  <div>- ASPIRE Certification Programme</div>
                    <div>- Trade Academy</div>
                    <div>- Credit School</div>
                    <div>- Leadership Academy </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
		  
		  <div className="swiper-slide">
          <div className="container learningsliderbga dd">
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-7">
                <div className="in">
                  <h4>ASPIRE Certification Programme</h4>
                  <p>HBL has developed its own certification programmes for the branch staff, the ‘ASPIRE’ series. All Branch Managers, Operation Managers, Relationship Managers and Cash Officers are required to undergo an extensive, training, enabling them to give a stellar performance in each area of their job. </p>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="container learningsliderbga ee">
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-7">
                <div className="in">
                  <h4>Trade Academy</h4>
                  <p>Capacity building approach for International Trade. We strengthen knowledge based skills through innovative approaches and techniques. Offering practical courses, workshops, case studies and variety of learning materials to polish skills for field functionaries.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="container learningsliderbga ff">
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-7">
                <div className="in">
                  <h4>Credit School</h4>
                  <p>HBL Credit School has been launched to provide our early and mid-career resources the skills, tools and mindset that will help them to take on future roles as Credit Officers and Senior Credit Officers in Business and Risk functions.  The training modules are completely developed in-house and are delivered by HBL's own senior resources, thereby drawing on expertise and experience available within the bank.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="container learningsliderbga gg">
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-7">
                <div className="in">
                  <h4>Leadership Academy</h4>
                  <p>HBL is committed to upholding its banking leadership, acknowledging the role of strong leadership in its legacy. In today's swiftly changing landscape, continuous skill enhancement is essential. To prepare for future challenges, HBL invests in its human capital through "The Leadership Academy. Partnering with Korn Ferry (KF), a renowned global consulting firm with extensive leadership expertise, HBL tailors leadership programs for various levels, ensuring readiness for the evolving landscape and pioneering tomorrow's banking frontier. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-button-next"></div>
      <div className="swiper-button-prev"></div>
      <div className="swiper-pagination"></div>
    </div>
  </div>
</section>
<section className="innersection learning forhover">
  <div className="container">
    <div className="text-center mb-5">
      <h3 className="web" data-sr="enter top over 0.3s and move 65px wait 0.3s">Leveraging Technology</h3>
      <h2 className="web" data-sr="enter top over 0.3s and move 65px wait 0.6s">to Create Conducive Learning Experiences</h2>
    </div>
    <div className="row">
      <div className="col-lg-3">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 0.3s"> <img src="images/learning/blended.jpg" alt=""/>
          <h4 className="mt-3 mb-2">Blended Learning</h4>
          <p>Our learning content is designed on the basis of industry standards making use of effective instructional design, interactive digital engagement tools, subject experts, delivered through blended learning methodologies. </p>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 0.6s"> <img src="images/learning/cultural.jpg" alt=""/>
          <h4 className="mt-3 mb-2">Cultural Shift in Learning using Technology</h4>
          <p>We extensively use online learning tools such as eLearning modules, gamification, webinars, podcasts and micro-learning videos to reach audiences in different locations. </p>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 0.9s"> <img src="images/learning/digital-library.jpg" alt=""/>
          <h4 className="mt-3 mb-2">Digital Library</h4>
          <p>At HBL, employees have open access to the growing digital library, its catalog includes numerous videos, webcasts and eLearning modules which are focused on but not limited to digital financial tools that are used around the globe. </p>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 1.2s"> <img src="images/learning/digital-development.jpg" alt=""/>
          <h4 className="mt-3 mb-2">Digital Development Hubs </h4>
          <p>HBL has established Digital Development Hubs in different cities, enabling employees to learn using a variety of online learning tools.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="innersection learning erbg">
  <div className="container">
    <div className="row">
      <div className="col-lg-6">
        <div className="in" data-sr="enter left over 0.3s and move 65px wait 0.3s"> <img src="images/learning/icon-educational.png" alt="" className="d-block mb-3"/>
          <h3 className="web white my-3">Educational Support </h3>
          <p className="white">Learning is everything at HBL. Want to learn a new skill or get a certification to excel at your job? We endorse that! Employees have ample opportunities to opt for professional development and leadership courses. </p>
          <p className="white mt-3">Starting at HBL is just the beginning. </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="innersection learning forhover">
  <div className="container">
    <div className="text-center mb-5">
      <h3 className="web" data-sr="enter top over 0.3s and move 65px wait 0.3s">PERSONAL DEVELOPMENT</h3>
      <h2 className="web" data-sr="enter top over 0.3s and move 65px wait 0.6s">Developing Responsive Communication Skills</h2>
    </div>
    <div className="row">
      <div className="col-lg-3">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 0.3s"> <img src="images/learning/building-passion.jpg" alt=""/>
          <h4 className="mt-3 mb-2">Building Passion and Engagement </h4>
          <p>We believe that work goes hand-in-hand with employee engagement, as it increases productivity and morale. Our employees learn in a fun environment, away from the stress of everyday life! </p>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 0.6s"> <img src="images/learning/developing.jpg" alt=""/>
          <h4 className="mt-3 mb-2">Developing and Strengthening Links with Industry</h4>
          <p>HBL also maintains strong, progressive links with the industry by organizing trainings that benefits the banking and non- banking industry at large. </p>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 0.9s"> <img src="images/learning/empowering.jpg" alt=""/>
          <h4 className="mt-3 mb-2">Empowering Employees through Wellness &amp; Health</h4>
          <p>HBL L&amp;D also helps the staff relieve stress and restore balance in their lives through meditation and mindfulness activities that help them focus on their physical and mental health. </p>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 1.2s"> <img src="images/learning/continued.jpg" alt=""/>
          <h4 className="mt-3 mb-2">Continued Personal &amp; Professional Development</h4>
          <p>We also focus on the team’s personal development in different areas that will benefit them not only professionally but also personally including their linguistic capabilities. </p>
        </div>
      </div>
    </div>
  </div>
</section>
    </>
  )
}

export default Learning
