import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functioncorporatestrategy = () => {
  return (
    <>
    <Helmet>
<title>Corporate Strategy | HBL People</title>
<meta
name="description"
content="Change is the only constant"
/>
</Helmet>
<section className="innerpagebanner corporate-strategy">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Corporate Strategy</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Change is the only constant </h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages corporate-strategy">
  <div className="container d-flex flex-wrap gap-3">
<p data-sr="enter top over 0.3s and move 65px wait 0.3s">Corporate Strategy Function’s role is to develop and consolidate enterprise-wide strategy and drive its execution under a robust governance framework. The Function develops strategies to create measurable business value, operating performance, and bottom-line improvements, while promoting overall corporate ambitions. Additionally, the Function works in collaboration with stakeholders across the Bank to ensure that business level strategies are in line with the Bank’s aspirations of becoming customer-centric, technology led and agile, and creating a positive impact in the geographies HBL operates in. This is achieved by focusing on the Bank’s unique assets and leveraging opportunities that fit the overall strategy of the Bank and help distinguish it from existing and potential competition.</p>


  </div>
</section>

<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functioncorporatestrategy