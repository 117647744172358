import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Intheader from "../../../../components/Intheader";
import Intheadermob from "../../../../components/Intheadermob";

const Intpost8 = () => {
  const images = [
    "/images/theleague/internship/posts/8/1.jpg",
    "/images/theleague/internship/posts/8/2.jpg",
    "/images/theleague/internship/posts/8/3.jpg",
    "/images/theleague/internship/posts/8/4.jpg",
    "/images/theleague/internship/posts/8/5.jpg",
    "/images/theleague/internship/posts/8/6.jpg",
    "/images/theleague/internship/posts/8/7.jpg",
  ];

  return (
    <>
      <Helmet>
        <title>
          The League Internship Programme 2018: Islamabad Book Donation Drive |
          THE LEAGUE
        </title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
          <Intheader />
          <h1 className="my-3">
            The League Internship Programme 2018: Islamabad Book Donation Drive
          </h1>
          <p>
            As an initiative to pay back to the society, our Islamabad-based
            interns organized a book drive at our HBL Regional Headquarter to
            collect and donate 400+ books to Al-Firdous Community Model School,
            where underprivileged children are provided with free education.
          </p>
          <Lightbox images={images} />
          <Intheadermob />
        </div>
      </section>
    </>
  );
};

export default Intpost8;
