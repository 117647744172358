import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functioncoofunctions = () => {
  return (
    <>
<Helmet>
<title>Operations Services | HBL People</title>
<meta
name="description"
content="PROVIDING SUPPORT TO FRONTLINE CULMINATING IN QUALITY CUSTOMER SERVICE."
/>
</Helmet>
<section className="innerpagebanner operation-services">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Operations Services</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">PROVIDING SUPPORT TO FRONTLINE CULMINATING IN QUALITY CUSTOMER SERVICE</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages operation-services">
  <div className="container d-flex flex-wrap flex-column gap-3">
    <p>Operations Services provides foundational support to HBL’s dynamic Operating Model. The broad spectrum of areas it covers, impacts domains across the bank, and is vital to seamless delivery of Products & Services to the customers. 
      </p>
<p>Through the extensive spread of its scope, it renders relentless support to the frontline Business teams by administering Business processes and procedures, some of which run round the clock, culminating in quality customer service.
  </p>
<p>By offering a wide range of activities and depth of information, these functions presents huge opportunities to individuals who are looking to enhance their core banking skills & knowledge. </p>
<p>The functions that collectively fall under the extensive umbrella of ‘Operations Services’ are:</p>
<div>
<ul>
<li>Trade, Treasury & GRB Operations</li>
<li>Payment Services</li>
<li>ADC Operations</li>
<li>Asset Operations</li>
<li>Branch Operations International</li>
<li>Business Policies & Procedures</li>
<li>Shared Services</li>
<li>COO Controls, Governance & Special Projects</li>
</ul>
</div>
    <div>
      <h4>Trade , Treasury & GRB Operations:</h4>
      <p>‘Trade operations’ is responsible for efficient processing of all trade related transactions of bank’s valued customers i.e., Exports, Imports. Letters of credit and guarantees, while also ensuring that the regulatory requirements are strictly adhered to at all times. Trade operations provides critical support to the trade business by retaining the customer base through delivery of swift and error-free services leading to heightened customer satisfaction.  </p>
    </div>
    <div>
      <h4>Payment Services, ADC & Asset Operations:</h4>
      <p>Payment Services, ADC & Asset Operations provides a unified and centralized platform for processing of payments pertaining to Salaries, Pensions, Remittances, Clearing, Consumer Loans, Debit & Credit Cards, including Treasury transactions initiated by Treasury Front Office. It provides round the clock services by ensuring that the ATMs across the Bank’s network always remain fully functional. Several wings of payment services operate in harmony to create an enhanced service experience for internal and external customers. These include, Client Onboarding and management through Account Services, Consumer Loans disbursements and payments, end to end Remittance processes, Alternate Delivery Channels (ADC) for Branchless Banking, I & FI Business, individual customers, Merchant and Agent Onboarding, Cash Management Functions, Supply Chain Financing & SBP Refinancing related payments and activities.</p>
    </div>
    <div>
      <h4>Branch Operations International:</h4>
      <p>Branch Operations International acts as a focal point for the International Branch Network in matters relating to operations. It keeps vigilance on control issues and creates a bridge between international locations and the Head Office, while monitoring key operational aspects through alignment of policies and procedures. It ensures compliance of International Operating Model with the respective regulatory environment while it also aligns international policies with HBL’s global policies. </p>
    </div>
    <div>
      <h4>Business Policies & Procedures: </h4>
      <p>‘Business Policies & Procedures’ renders support to units in developing Policies, Procedures, SOPs, Circulars and Product Programs. It collaborates with the ‘Operations Services’ functions to assist in addressing audit observations and identifies opportunities for achieving cost efficiency in processes of ‘Operation Services’.  </p>
    </div>
    <div>
      <h4>Shared Services: </h4>
      <p>An integral part of Operations Services, ‘Shared Services’ provides efficient support to entire network of HBL by managing the evolving needs of stakeholders with optimal utilization of resources.  ‘Shared Services’ assists the Bank to implement cost effective, sustainable, transparent & progressive solutions for Bank’s Procurement, Properties, Construction / Renovation, Business Continuity Planning and Administrative affairs, ultimately contributing towards profitability, efficiency & enhanced Brand Image of HBL.</p>
    </div>
    <div>
      <h4>Security Services:</h4>
      <p>HBL Security Services is focused on safeguarding the HBL assets, clients and staff with centralized control and decentralized execution.
It is enforced through compliant security mechanism's aggressive deployment with 3rd party vendors at external and internal perimeters forming interdependent multiple layered systems (where possible). With deployment of contemporary active and passive measures at branches / CPCs / sub branches / booths / HOK high density buildings, RHQ, FCE, DCE and Agri Dehras. Concurrently, provides security to Chairman HBL and  President & CEO along with Airport protocol management. 
</p>
    </div>
    <div>
      <h4>COO Controls, Governance & Special Projects:</h4>
      <p>Governance unit is responsible for ensuring improved Governance, Risk and Compliance for Operations Services. Focusing on cascading timely information to functional leads enabling them to take prompt actions and well -informed decisions, design and implement robust process of risk management, starting from risk identification to monitoring and closure of risk responses and ensuring compliance with Regulatory, Internal and External action items.</p>
    </div>
  </div>
</section>
<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functioncoofunctions