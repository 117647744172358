import React from "react";


const Heading =(props)=>{
    return (<>
    <h4><a href={props.link}>{props.title}</a></h4>
    <a class="camp-but" href={props.link}>See More</a>
    </>);
}

export default Heading ;