import React from "react";
import { Helmet } from "react-helmet";
import Footertabagp from "../../components/footertabagp";

const Leagueagplifeintheleague = () => {
  return (
    <>
      <Helmet>
        <title>Life in the League | THE LEAGUE</title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
      </Helmet>
      <section className="itlike-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 d-none d-lg-block">
            <a
          href="/the-league/accelerate-graduate-programme"
          className="rthome"
          ><img
            src="/images/theleague/innerpage-back-button-arrow.png"
          />  Accelerate Graduate Programme</a
        >
            </div>
            <div className="col-lg-4">
              <a href="/the-league">
                <img
                  src="/images/theleague/the-league-logo.png"
                  className="img-fluid d-block mx-auto"
                />
              </a>
            </div>
            <div className="col-lg-4 d-none d-lg-block text-end">
              <a href="/the-league/" className="rthome">
                Return to Home <img src="/images/theleague/innerpage-forward-button-arrow.png" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="im-banner text-center">
                <h2>Life in The League</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>
                As any of the current members of the League can tell you, HBL is
                a mosaic of bright minds and outgoing personalities. Unique
                people are our target employees, with each one adding value to
                our organization. As you bring your skills and talents to the
                table, we bring our commitment to shaping you into a high
                caliber professional.
              </p>
            </div>
          </div>

          <div className="row align-items-center mt-3 mb-4">
            <div className="col-md-7 col-lg-6">
              <div
                id="carousel-example-generic"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <ol class="carousel-indicators cusci">
                  <li
                    data-bs-target="#carousel-example-generic"
                    data-bs-slide-to="0" className="active"
                  ></li>
                  <li
                    data-bs-target="#carousel-example-generic"
                    data-bs-slide-to="1"
                  ></li>
                  <li
                    data-bs-target="#carousel-example-generic"
                    data-bs-slide-to="2"
                  ></li>
                  <li
                    data-bs-target="#carousel-example-generic"
                    data-bs-slide-to="3"
                  ></li>
                  <li
                    data-bs-target="#carousel-example-generic"
                    data-bs-slide-to="4"
                  ></li>
                  <li
                    data-bs-target="#carousel-example-generic"
                    data-bs-slide-to="5"
                  ></li>
                  <li
                    data-bs-target="#carousel-example-generic"
                    data-bs-slide-to="6"
                  ></li>
                </ol>
                <div class="carousel-inner" role="listbox">
                  <div class="carousel-item active">
                    <img
                      src="/images/theleague/agp/Daniyal-Naveed.jpg"
                      class="img-fluid"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="/images/theleague/agp/Muhammad-Bilal-Rashid.jpg"
                      class="img-fluid"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="/images/theleague/agp/Navaera-Sulaiman.jpg"
                      class="img-fluid"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="/images/theleague/agp/Syed-Abdullah-Tauha.jpg"
                      class="img-fluid"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="/images/theleague/agp/mahrukh.jpg"
                      class="img-fluid"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="/images/theleague/agp/rabseh.jpg"
                      class="img-fluid"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="/images/theleague/agp/syed.jpg"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-lg-6">
              <p>
                At the League you will find an exciting and stimulating
                environment. You will work hand-in-hand with your counterparts
                from some of the best institutions in the country.
              </p>
              <p>
                You will face challenges and be assigned tasks which shall test
                and enable you to grow into a high potential executive. Teamwork
                and ownership are characteristics you shall pick up on quickly
                as a part of The League and through the support of your seniors,
                you will one day go on to become a professional who shall play a
                part in leading HBL into the future.
              </p>
            </div>
          </div>
          <div className="row d-flex d-lg-none text-center my-3">
            <div className="col">
            <a
          href="/the-league/accelerate-graduate-programme"
          className="rthome"
          ><img
            src="/images/theleague/innerpage-back-button-arrow.png"
          />  Accelerate Graduate Programme</a
        >
            </div>
            <div className="col ">
              <a href="/the-league/" className="rthome">
                Return to Home
                <img src="/images/theleague/innerpage-forward-button-arrow.png" />
              </a>
            </div>
          </div>
        </div>
        <Footertabagp />
      </section>
    </>
  );
};

export default Leagueagplifeintheleague;
