import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functioninformationtechnology = () => {
  return (
    <>
<Helmet>
<title>Information Technology | HBL People</title>
<meta
name="description"
content="Revolutionize the World with Your Tech Skills"
/>
</Helmet>
<section className="innerpagebanner information-technology">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Information Technology</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Revolutionize the World with Your Tech Skills</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages information-technology">
  <div className="container d-flex flex-wrap gap-3">
    <p>HBL’s vision is to embed technology within the very fabric of the Bank. We see technology as a force multiplier and enabler and recognize it as a crucial, but not the only, component of our drive towards increasing revenues, reducing costs and enhancing customer experience. We also are fully aware of the fact that technology in itself is not the solution, it is the complex interplay of technology enabled services with empowered employees that will allow the full potential of the bank to be unleashed. Regardless of all technology improvement the most important ingredient for us are the humans behind it. Technology will be used by people and driven by people. We want and foster Tec-people to create value. Technology must provide value.<br/>
<a style={{color:'#197056'}}>So #revolutionizetheworldwithyourskills.</a></p>


  </div>
</section>

<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functioninformationtechnology