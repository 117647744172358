import React from "react";

const Images =(props)=>{
    return(
    <>
    <div
      className="post-image"
      style={{
        backgroundImage: `url(${props.imgsrc})`,
      }}
    ></div>
        </>)
}
export default Images;