import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Campheader from "../../../../components/Campheader";
import Campheadermob from "../../../../components/Campheadermob";

const Campost30 = () => {
  const images = [
    "/images/theleague/mt/posts/30/1.jpg",
    "/images/theleague/mt/posts/30/2.jpg",
    "/images/theleague/mt/posts/30/3.jpg",
    "/images/theleague/mt/posts/30/4.jpg",
    "/images/theleague/mt/posts/30/5.jpg",
    "/images/theleague/mt/posts/30/6.jpg",
    "/images/theleague/mt/posts/30/7.jpg",
    "/images/theleague/mt/posts/30/8.jpg",
    "/images/theleague/mt/posts/30/9.jpg",
    "/images/theleague/mt/posts/30/10.jpg",
    "/images/theleague/mt/posts/30/11.jpg",
  ];
  return (
    <>
      <Helmet>
        <title>
        GIK Campus Drive 2020 | THE LEAGUE
        </title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
          <Campheader />
          <h1 className="my-3">GIK Campus Drive 2020</h1>
          <Lightbox images={images} />
          <Campheadermob />
        </div>
      </section>
    </>
  );
};

export default Campost30;
