import React from "react";
import { Helmet } from "react-helmet";
import Footertabmt from "../../components/footertabmt";

const Leagueyourrole = () => {
  return (
    <>
      <Helmet>
        <title>Your Role | THE LEAGUE</title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
      </Helmet>
      <section className="dohere-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 d-none d-lg-block">
              <a href="/the-league/management-trainee/" className="rthome">
                <img src="/images/theleague/innerpage-back-button-arrow.png" />{" "}
                Management Trainee
              </a>
            </div>
            <div className="col-lg-4">
              <a href="/the-league">
                <img
                  src="/images/theleague/the-league-logo.png"
                  className="img-fluid d-block mx-auto"
                />
              </a>
            </div>
            <div className="col-lg-4 d-none d-lg-block text-end">
              <a href="/the-league/" className="rthome">
                {" "}
                Return to Home{" "}
                <img src="/images/theleague/innerpage-forward-button-arrow.png" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="im-banner text-center">
                <h2>Your Role</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>
                As the largest bank in Pakistan, HBL’s operations are spread
                across a wide variety of fields. Whether you become a part of
                Operations, Innovation & Financial Inclusion, Technology,
                Consumer or Corporate Banking, you’ll enjoy project ownership,
                rewarding challenges and exposure to a variety of businesses
                across a wide industrial spectrum.
              </p>
              <p>
                Along with technical training, you will also be able to develop
                critical business relationship skills. Teaming or collaboration
                across cultures are skills that will allow you to become a
                valuable asset to our organization.
              </p>
              <p>
                As someone who is beginning their career at HBL, you will find
                yourself immersed in some of the most exciting financial and
                economic projects in the country today, all of which are spread
                across a variety of industries. You will be provided with the
                opportunity to connect with and be mentored by senior business
                leaders who can help guide your career. Here you will build your
                professional network and interact with colleagues across
                different divisions and regions.
              </p>
              <p>
                Choosing the right department can be tough, and the truth is
                there are no easy answers, but there are lots of great
                questions. Let us help you figure out what type of role,
                business and industry is right for you.
              </p>
            </div>
          </div>

          <div className="col mb-5">
            <div className="leagueacc accordion" id="yourroleacc">
              <div className="accordion-item">
                <h2 className="accordion-header" id="ha">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#ca"
                    aria-expanded="false"
                    aria-controls="ca"
                  >
                    Branch Banking
                  </button>
                </h2>
                <div
                  id="ca"
                  className="accordion-collapse collapse"
                  aria-labelledby="ha"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      HBL Branch Banking is the largest business segment of the
                      bank spread across Pakistan through its vast network of
                      1,450+ branches and 2,000+ ATMs. The group is divided into
                      7 different functions; Business Planning and Strategy,
                      Retail Products, National Sales, Analytics &amp; Process
                      Engineering, Governance, Prestige Banking, and
                      Distribution (commonly referred to as the sales wing of
                      the branch network).
                    </p>
                    <h3>Analytics and Process Engineering</h3>
                    <p>
                      Branch banking analytics is packed with infinite
                      opportunities where we focus on creating tools to enable
                      data led business decisions, identify business
                      opportunities through data models and state of the art
                      technology used globally. Our dedicated advance analytics
                      program helps towards sales management initiatives; driven
                      through artificial intelligence, spatial analytics, power
                      BI tools and propensity models among others contemporary
                      data science tools.
                    </p>
                    <p>
                      Process improvement program is the cornerstone of our
                      renewed strategy at branch banking whereby we strive to
                      remove procedural niceties and dovetailing any change with
                      technology and automation. We look for opportunities to
                      remove inefficiencies of doing business and collectively
                      improve them using policy changes / cognitive sciences /
                      AI / Innovation / big data and much more. In its entirety,
                      the process improvement program reaches the idlest of the
                      banking processes and seek to transform / optimize these
                      with the rudimentary approach of design thinking i.e.,
                      understanding customer needs, generating creative ideas
                      for the good of customer experience.
                    </p>
                    <h3>Retail Products</h3>
                    <p>
                      Retail Products function provides strategic product
                      leadership, by understanding the consumer and delivering
                      the best suited products. Conducting market research to
                      understand consumer needs and strategically managing
                      competition. Bringing a product or proposition from a
                      concept through to market release and beyond. At HBL,
                      Retail Products has 5 sub-functions including HBL
                      Prestige, Premium Strategy, Liability Products, Wealth
                      Management &amp; Women Marketing Program &amp;
                      Non-Resident Pakistani Banking.
                    </p>
                    <h3>HBL Prestige</h3>
                    <p>
                      HBL Prestige is a value proposition designed to provide
                      HBL’s HNW clients with a world class banking experience.
                      HBL Prestige offers high level of personalized services,
                      more rewarding in-branch experience and a wide array of
                      products that offer unique lifestyle experiences, tailored
                      to client requirements.
                    </p>
                    <h3>Wealth Management</h3>
                    <p>
                      A systematic marketing, distribution, and servicing of a
                      range of insurance and investment products (3rd party
                      products) to the right customer and at the right time.
                      Using a variety of distribution channels within the Bank
                      to provide the customers all financial solutions under one
                      roof.
                    </p>
                    <h3>National Sales</h3>
                    <p>
                      The recently created function of National Sales is
                      mandated to lead business development and sales
                      mobilization strategy through Distribution in addition to
                      driving Digital Initiatives for the group. Another
                      critical sub-function of National Sales is Network
                      Optimization responsible for the entire network’s
                      footprint management.
                    </p>
                    <h3>Distribution</h3>
                    <p>
                      The Distribution network of Branch Banking consists of
                      1,700+ branches, 140 Areas, 19 Regions, 6 HUBs, and 3
                      Clusters (South/Central/North). Each region is managed by
                      a Regional Chief, HUB by a Senior Regional Chief, and
                      Cluster by Distribution Head. Regional teams also consist
                      of Regional Business Managers, Analytics Officers, and
                      Business Development Managers – Wealth Management.
                      Distribution plays a fundamental role in delivering the
                      business and non-business objectives of the bank. Sales of
                      all Deposit, Wealth, Consumer, Trade, SME, and Agri
                      products is channeled through Distribution.
                    </p>
                    <h3>Governance</h3>
                    <p>
                      A Business support team dedicated to managing governance
                      aspects of Branch Banking, enabling core business teams
                      to:
                    </p>
                    <ul>
                      <li>
                        Conduct business in the utmost regulatory compliance
                        manner.
                      </li>
                      <li>
                        Keeping overall internal control environment intact.
                      </li>
                      <li>
                        Managing identified risks and emerging risk for
                        mitigation.
                      </li>
                      <li>
                        Facilitating business to thrive in continuously evolving
                        regulatory, controls, and risk dimensions.
                      </li>
                      <li>
                        Bringing efficiencies in processes, product programs,
                        and procedures.
                      </li>
                      <li>
                        Facilitating representation in management and board
                        committees.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hb">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cb"
                    aria-expanded="false"
                    aria-controls="cb"
                  >
                    Consumer, Agriculture and SME Banking
                  </button>
                </h2>
                <div
                  id="cb"
                  className="accordion-collapse collapse"
                  aria-labelledby="hb"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      As the leading bank in Pakistan, we strive to provide
                      banking that is convenient, rewarding, and trustworthy.
                      HBL’s Consumer Agriculture and SME Banking business is
                      focused on providing value to customers while enabling
                      them to achieve their financial aspirations. We help
                      people prosper, enable businesses to grow and play a role
                      in the development of Pakistan.
                    </p>
                    <h3>Consumer Banking</h3>
                    <p>
                      HBL Consumer Banking is a market leader and our diverse
                      range include credit cards, debit cards, personal loans,
                      auto loans and merchant acquiring solutions. At HBL
                      Consumer Banking we impact the lives of individuals, from
                      mass market to affluent. We are at the forefront of new
                      technology and payment solutions. What sets us apart is
                      our focus on customer-centric product propositions, data
                      driven digital lending capabilities and digitization of
                      conventional channels to meet the evolving financial needs
                      of our customers.
                    </p>
                    <h3>Agriculture Banking</h3>
                    <p>
                      HBL is committed to cater to the banking needs of farmers
                      and Agriculture communities. It continues to play a vital
                      role in reaching out to Agriculture Pakistan providing
                      tailor made agricultural financial services through its
                      vast network of Agriculture branches and a dedicated field
                      force of rural Banking. The bank is a market leader in
                      farmer financing and continues to innovate and digitalize
                      the customer journey for enhanced outreach with
                      qualitative service.
                    </p>
                    <h3>SME Banking</h3>
                    <p>
                      Being the largest lender, we support small and medium
                      businesses with conventional and seasonal leading
                      products, program-based lending with small business
                      finance and point-of-sale (POS) finance. HBL also supports
                      SMEs with SBP targeted schemes and trade needs of the
                      sector.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cc"
                    aria-expanded="false"
                    aria-controls="cc"
                  >
                    Corporate, Commercial and Investment Banking
                  </button>
                </h2>
                <div
                  id="cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="hc"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      HBL has the largest Corporate Banking portfolio in
                      Pakistan coupled with the most active and largest
                      Investment Banking Division in Pakistan. Our Corporate
                      &amp; Investment Banking Group is a leading provider of
                      financial services to top-tier multi-national and local
                      clients across the country, serving the financial needs of
                      the nation’s preeminent domestic and multinational
                      corporate customers.
                    </p>
                    <h3>Corporate &amp; Investment Banking</h3>
                    <p>
                      Corporate banking’s relationship managers partner with
                      product specialists to provide a full array of corporate
                      banking solutions, from cash management, foreign exchange,
                      trade finance, custody, clearing and loans, to capital
                      markets, derivatives, and structured products. They also
                      partner with our investment banking arm to deliver
                      investment banking expertise in project finance, mergers
                      &amp; acquisitions, debt syndications and capital market
                      solutions to the banks’ clients.
                    </p>
                    <h3>Commercial Banking</h3>
                    <p>
                      The Commercial Banking division is a vital area within the
                      group that primarily targets smaller and medium sized
                      businesses. Commercial Banking teams provide these clients
                      with personalized services such as structured and
                      customized banking solutions that help enable their
                      businesses to function more efficiently. These solutions
                      encompass various services such as Trade Finance (covering
                      imports, exports, remittances &amp; guarantees), Long-term
                      debt and Working Capital Finance, Deposit Mobilization,
                      Cash Management and cross-sell of various other banking
                      products.
                    </p>
                    <h3>Transaction and Employee Banking</h3>
                    <p>
                      The Transaction and Employee Banking (TEB) business spans
                      across business segments by providing innovative solutions
                      for customers to run their businesses. TEB offers
                      customized digital products to enhance efficiencies within
                      customer business through cash management, payment
                      solutions, and payroll delivery services.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hd">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cd"
                    aria-expanded="false"
                    aria-controls="cd"
                  >
                    Treasury & Global Markets
                  </button>
                </h2>
                <div
                  id="cd"
                  className="accordion-collapse collapse"
                  aria-labelledby="hd"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      HBL Treasury &amp; Global Markets (TGM) is a core part of
                      a bank’s structure and of Pakistan’s financial landscape.
                      The Bank is an authorized dealer in foreign exchange, one
                      of 12 Primary Dealers nominated for the sale &amp;
                      distribution of government debt, and an authorized dealer
                      for Derivatives, all functions that fall within the
                      purview of the TGM Group.
                    </p>
                    <p>
                      While the mainstay of TGM is the domestic TGM business,
                      the Group also houses the International Treasury function
                      which has oversight of Treasury functions in international
                      locations. The function encompasses all aspects of the
                      International Treasuries, including liquidity management,
                      investments, and sales &amp; trading, and provides an
                      important bridge between the countries and Head Office.
                    </p>
                    <h3>Asset &amp; Liability Management</h3>
                    <p>
                      The Asset &amp; Liability Management (ALM) function
                      manages the Liquidity &amp; Interest Rate risks of the
                      entire Bank, with a view to optimize the Bank’s
                      profitability from its large, liquid investment portfolio
                      (known as the Banking Book, which constitutes more than
                      half the Bank’s balance sheet), within a framework
                      sanctioned by the Bank’s ALCO Committee. Pursuant to these
                      objectives, the Treasury ALM function carries out reserve
                      management activities as well as analyses and scenario
                      planning of balance sheets.
                    </p>
                    <h3>Treasury Sales</h3>
                    <p>
                      TGM is the “face-to-the-market” for the Bank, encompassing
                      activities in various financial markets including FX
                      (Foreign Exchange), Money Markets, Fixed Income
                      Securities, Equities &amp; Derivatives. The Treasury Sales
                      function is responsible for the Sale &amp; Distribution of
                      financial markets products to all client segments of the
                      Bank, and works closely with relationship businesses to
                      maximize the volume and income stream from these
                      activities. The structuring desk is tasked with the
                      overall management of derivatives and structured / complex
                      products, and in developing bespoke solutions for clients,
                      as well as acting as the new product engine for the Group.
                    </p>
                    <h3>Fixed Income &amp; FX</h3>
                    <p>
                      Trading desks in fixed income and FX manage deal flow and
                      exposures in these products with a view to providing
                      market liquidity to client segments as well as other Bank
                      counterparties, and to proactively position the Bank to
                      take advantage of market movements. The Bank has been the
                      largest market-maker in these products for several years.
                      The equity desk manages the liquid equities portfolio of
                      the Bank.
                    </p>
                    <h3>Research</h3>
                    <p>
                      The Group also houses the Research function, which
                      provides economic and market research to senior
                      management, to clients, and for internal use in the
                      Treasury.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hq">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cq"
                    aria-expanded="false"
                    aria-controls="cq"
                  >
                    Islamic Banking
                  </button>
                </h2>
                <div
                  id="cq"
                  className="accordion-collapse collapse"
                  aria-labelledby="hq"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      HBL Islamic Banking is one of the largest
                      Shariah-compliant service providers in the country. We
                      provide end to end Islamic banking solutions to our varied
                      clientele including account services, financial products,
                      cash management and alternate delivery channels.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="he">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#ce"
                    aria-expanded="false"
                    aria-controls="ce"
                  >
                    Innovation and Financial Inclusion
                  </button>
                </h2>
                <div
                  id="ce"
                  className="accordion-collapse collapse"
                  aria-labelledby="he"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      The Innovation and Financial Inclusion Group focuses on
                      overall digital transformation of HBL, including enabling
                      the drive towards becoming a Data Driven organization. It
                      aims to transform our customer’s banking experience using
                      data for in-the-moment engagement and real-time /
                      predictive analytics and decision-making capabilities,
                      innovation, business model redesign and development of new
                      channels to tap the unbanked segments. The emergence of
                      digital age has served to revolutionize the current bank
                      offerings using concepts like straight-through processing,
                      product and process redesign, data analytics, machine
                      learning, branchless ecosystem, and loT (internet of
                      things).
                    </p>
                    <p>
                      Konnect by HBL remains the primary vehicle for offering
                      easy access to the unbanked and under-banked segments of
                      society. Leveraging on our strong brand name, along with
                      the existing consumer and corporate business product
                      suite, Konnect by HBL has the privilege to partner with
                      the Government on a variety of G2P initiatives such as
                      Ehsaas Kafalat Program, Ehsaas Emergency Cash Program,
                      Punjab &amp; KPK Agriculture division. With enhanced
                      features and financial services offering, Konnect is now
                      looking forward to expanding its value propositions and
                      services. HBL has always intended to invest in this
                      initiative with the objectives of enhanced financial
                      inclusion and digitization of payment methods.
                    </p>
                    <p>The group consists of following three departments:</p>
                    <ul>
                      <li>Branchless Banking – Konnect by HBL</li>
                      <li>
                        Data Strategy &amp; Organizational Impacts – Including
                        Data Lake, Analytics, Al/ML
                      </li>
                      <li>
                        Enterprise Solution and Digital Channels – HBL Mobile,
                        Internet Banking, HBL Pay, Billing Aggregator
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hf">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cf"
                    aria-expanded="false"
                    aria-controls="cf"
                  >
                    International Banking
                  </button>
                </h2>
                <div
                  id="cf"
                  className="accordion-collapse collapse"
                  aria-labelledby="hf"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      International Banking is a key business unit at HBL that
                      is responsible for all aspects of the Bank’s relationships
                      with domestic and international financial institutions. It
                      provides the infrastructure which facilitates the
                      efficient functioning of our global trade, payments, and
                      treasury businesses. From an international perspective,
                      the department also assesses individual country risk by
                      analyzing counter party credit risks through the macro and
                      micro environment analysis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hg">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cg"
                    aria-expanded="false"
                    aria-controls="cg"
                  >
                    Finance
                  </button>
                </h2>
                <div
                  id="cg"
                  className="accordion-collapse collapse"
                  aria-labelledby="hg"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      The Finance department works in collaboration with all the
                      other functions of the Bank to track, analyze and report
                      the financial information that measures the Bank’s
                      performance in order to guide the Bank in achieving its
                      strategic objectives.
                    </p>
                    <p>
                      The department also manages the Bank’s tax reporting,
                      compliance and payment obligations and provides advisory
                      services to departments on tax matters.
                    </p>
                    <p>
                      The team also strives to maintain the integrity of
                      controls around the financial reporting process to ensure
                      accuracy in accordance with the requirements set out under
                      the various laws and regulations applicable on Banks in
                      Pakistan, and other jurisdictions where the Bank operates.
                    </p>
                    <p>
                      The Finance department is the primary interface to the
                      investor community and presents HBL and its performance
                      through regular interactions with other stakeholders.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hh">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#ch"
                    aria-expanded="false"
                    aria-controls="ch"
                  >
                    Global Compliance
                  </button>
                </h2>
                <div
                  id="ch"
                  className="accordion-collapse collapse"
                  aria-labelledby="hh"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      Global Compliance Group (GCG) is the Bank-wide gatekeeper
                      for all regulatory and internal regulations covering all
                      Business/Product segments across the organization. It
                      partners with all HBL businesses and functions striving to
                      ensure that the Bank’s Policies and Procedures and
                      regulatory guidelines are implemented in letter and spirit
                      throughout the organization.
                    </p>
                    <p>
                      GCG consists of the following six&nbsp;departments at Head
                      Office:
                    </p>
                    <ul>
                      <li>Financial Crime Compliance (FCC)</li>
                      <li>International Compliance (IC)</li>
                      <li>
                        Regulatory Examination Management &amp; Liason
                        (REM&amp;L)
                      </li>
                      <li>
                        Compliance Training, Assurance &amp; Projects (CTAP)
                      </li>
                      <li>
                        Compliance Risk &amp; Corporate Governance (CR&amp;CG)
                      </li>
                      <li>Advisory &amp; Policy Management (A&amp;PM)</li>
                    </ul>
                    <p>
                      Additionally GCG has Compliance Officers in various
                      overseas locations&nbsp;implementing the Compliance
                      Program in their respective geographies. These staff
                      report to the International Compliance division.
                    </p>
                    <p>
                      Global Compliance’s endeavors to have HBL recognized as an
                      institution with the ‘best in class’ compliance
                      environment, with the following specific Mission goals:
                    </p>
                    <ul>
                      <li>
                        Inculcate a strong culture of compliance with the slogan
                        “Compliance is my responsibility”
                      </li>
                      <li>Strive towards a ‘zero’ tolerance</li>
                      <li>
                        Institute strong controls to minimize&nbsp;compliance
                        risk&nbsp;
                      </li>
                      <li>Identify compliance issues for timely action</li>
                      <li>Ensure compliance at ‘entry-points’, and</li>
                      <li>
                        Achieve compliance with ‘best practices’, local and
                        international standards and guidelines as laid down by
                        regulators
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hi">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#ci"
                    aria-expanded="false"
                    aria-controls="ci"
                  >
                    Human Resources
                  </button>
                </h2>
                <div
                  id="ci"
                  className="accordion-collapse collapse"
                  aria-labelledby="hi"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      HR at HBL operates as a strategic partner to our
                      businesses and focuses on driving performance excellence
                      by hiring, developing and retaining the best talent. With
                      a long-term focus on aligning our people strategy with the
                      overall organizational strategy, we strive to build a
                      flexible, engaging and progressive culture. Structured
                      into nine key roles, HR at HBL contributes through the
                      following units:
                    </p>
                    <ul>
                      <li>
                        Talent Acquisition: Sources, screens and recruits the
                        best talent in the market, while ensuring adherence to
                        inclusive hiring policies.
                      </li>
                      <li>
                        Relationship and Talent Management: Prioritizes employee
                        engagement and development in partnership with business
                        units and line managers to drive business results,
                        promote career growth and maximize employees’ potential.
                      </li>
                      <li>
                        Learning and Development: Designs and develops
                        need-based interventions to enhance capabilities and
                        skillsets to cater to the dynamic business environment.
                      </li>
                      <li>
                        HR Operations: Ensures seamless execution of daily HR
                        transactions essential to maintaining a workforce.
                      </li>
                      <li>
                        Rewards and Policies: Develops and implements policies
                        and reward structures to motivate and engage employees
                        to consistently drive performance and achieve
                        organization’s goals.
                      </li>
                      <li>
                        Organizational Development: Undertakes a systematic
                        approach to assessing and improving all employee-related
                        processes and structures to enhance organizational
                        efficiency.
                      </li>
                      <li>
                        HR Technology and Data Analytics: Focuses on
                        digitization of HR processes through design and
                        implementation of tech-related projects and solutions to
                        support evidence-based decision making.
                      </li>
                      <li>
                        Ethics and Conduct: Identifies, measures, monitors and
                        implements the ethics and conduct framework at HBL in
                        line with the State Bank of Pakistan guidelines.
                      </li>
                      <li>
                        Employee Relations: Acts as an arbitrator and oversees
                        the resolution of grievances, misconduct and/or
                        disciplinary violations by employees in a fair and
                        impartial manner.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hj">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cj"
                    aria-expanded="false"
                    aria-controls="cj"
                  >
                    Information Technology
                  </button>
                </h2>
                <div
                  id="cj"
                  className="accordion-collapse collapse"
                  aria-labelledby="hj"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      HBL’s vision is to embed technology within the very fabric
                      of the Bank. We see technology as a force multiplier and
                      enabler and recognize it as a crucial, but not the only,
                      component of our drive towards increasing revenues,
                      reducing costs and enhancing customer experience. We also
                      are fully aware of the fact that technology in itself is
                      not the solution, it is the complex interplay of
                      technology enabled services with empowered employees that
                      will allow the full potential of the bank to be unleashed.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hk">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#ck"
                    aria-expanded="false"
                    aria-controls="ck"
                  >
                    Internal Audit
                  </button>
                </h2>
                <div
                  id="ck"
                  className="accordion-collapse collapse"
                  aria-labelledby="hk"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      HBL Internal Audit Function through a risk-based approach,
                      provides reasonable and objective assurance on the
                      adequacy of design and effectiveness of the systems of
                      internal control in mitigating significant risks and in
                      enhancing the effectiveness of risk management, governance
                      processes and systems in a holistic manner. The end to end
                      coverage of areas include head office, branches,
                      international locations, credit risk cycle, technology
                      risks, Islamic banking areas and bank wide investigations
                      duly supported by dedicated audit digitalization team and
                      monitored by an internal quality assurance mechanism.
                    </p>
                    <p>
                      HBL internal audit is well positioned to deliver
                      consistent best in class assurance and advisory services
                      to its stakeholders based on the following five pillars:
                    </p>
                    <ul>
                      <li>
                        Continuous Risk focus: To continuously benchmark
                        ourselves against the dynamic regulatory requirements
                        stakeholder expectations and industry best practices for
                        provision of value added assurance and advisory
                        services.
                      </li>
                      <li>
                        Technology: Subject matter IT and digital expertise for
                        robust coverage of core technology and digital areas.
                      </li>
                      <li>
                        Agility: Flexible, future looking and continuously
                        evolving in the dynamic landscape.
                      </li>
                      <li>
                        Stakeholder management: Continuous engagement with
                        internal and external stakeholders for better insight
                        into emerging risks of the Bank and industry.
                      </li>
                      <li>
                        People &amp; Culture: Based on the principles of
                        diversity and open communication we implement ongoing
                        development, progression, fair treatment and performance
                        reward.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hl">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cl"
                    aria-expanded="false"
                    aria-controls="cl"
                  >
                    Information Security
                  </button>
                </h2>
                <div
                  id="cl"
                  className="accordion-collapse collapse"
                  aria-labelledby="hl"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      Information Security is a critical business function and
                      an essential component of governance affecting all aspects
                      of the business environment. HBL Board &amp; management is
                      committed to providing ‘first-class business in a
                      first-class way’ to our customers while protecting our
                      clients and their information.
                    </p>
                    <p>
                      In the environment of growing cyber threat, HBL continues
                      to strengthen the Bank’s cyber defense mechanism by
                      utilizing effective preventive and detective information
                      security measures to counter evolving cyber challenges.
                      These mechanisms allow HBL to offer innovative digital
                      solutions to its clients while ensuring them protection,
                      confidence, and peace of mind.
                    </p>
                    <p>
                      HBL is the amongst the very few banks to have attained PCI
                      DSS certification which is the de facto security standard
                      for the Payment Card Industry. HBL also achieved ISO27001
                      international information security related certification.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hl">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cl"
                    aria-expanded="false"
                    aria-controls="cl"
                  >
                    Risk Management
                  </button>
                </h2>
                <div
                  id="cl"
                  className="accordion-collapse collapse"
                  aria-labelledby="hl"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      Risk Management ensures exposures are kept within
                      acceptable risk limits while maintaining a robust and
                      sustainable earnings stream. The group’s activities
                      include approval of credit and market limits, portfolio
                      management, development of scorecards and rating models,
                      capital management, risk taxonomy, credit administration
                      and fraud risk management. Banks are subject to a wide
                      array of risks including those arising from lending,
                      market fluctuations and operational activities. Based on
                      this diverse nature, Risk Management Group at HBL requires
                      individuals from broad range of expertise including
                      credit, actuarial sciences, finance, accounting, business
                      management and other quantitative disciplines.
                    </p>
                    <p>
                      An Enterprise Risk Management (ERM) division has been set
                      up within Risk Management Group. ERM will provide the bank
                      with the tools and the mindset to strengthen the
                      connectivity and governance of the enterprise-wide risks,
                      including credit, market, liquidity, operational,
                      compliance, legal, reputational &amp; strategic risks.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hm">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cm"
                    aria-expanded="false"
                    aria-controls="cm"
                  >
                    Development Finance
                  </button>
                </h2>
                <div
                  id="cm"
                  className="accordion-collapse collapse"
                  aria-labelledby="hm"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      The Development Finance Group (DFG) is a new function
                      initiated by HBL. It is mandated to design and implement
                      interventions targeting Pakistan’s Agriculture and SME
                      sectors, and in the process, uplift the underserved and
                      exploited farmers of the country.
                    </p>
                    <p>
                      DFG facilitates the farmers through in-kind loans, and
                      help them become better producers and efficient sellers.
                      This is achieved by engaging the complete agriculture
                      value chain: by providing the farmers with high quality
                      inputs, modern mechanization and better agronomic
                      advisory; and connecting the farmers with the bulk
                      processor of their crop produce. In parallel, along with
                      improving the economic conditions of the financed farmers,
                      DFG also makes profits for the Bank, thus creating a
                      double bottom-line impact.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hn">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cn"
                    aria-expanded="false"
                    aria-controls="cn"
                  >
                    Branch Operations and Account Services
                  </button>
                </h2>
                <div
                  id="cn"
                  className="accordion-collapse collapse"
                  aria-labelledby="hn"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      Branch Operations &amp; Account Services is an integral
                      part of the bank and is mostly considered ‘the face of the
                      bank’. At HBL, it is our aim to provide extraordinary
                      customer experiences, by bridging client needs and demands
                      with the internal processes keeping in line with SBP
                      guidelines. This ensures implementation of regulatory
                      compliance to mitigate risk. The function remains engaged
                      in process improvement to meet customer expectations, to
                      implement regulatory changes and adapting best industry
                      practices.
                    </p>
                    <p>It has further divided into four verticals:</p>
                    <ol>
                      <li>
                        <strong>Branch Operations:</strong> Being first point of
                        contact for the customers, with their deep industry
                        experiences provides end to end assistance and guidance
                        against customer transactional needs.
                      </li>
                      <li>
                        <strong>
                          Branch Operations Support &amp; Governance:
                        </strong>{" "}
                        Acts as a support unit, assists in branch operations in
                        simplifying the processes, create synergies, and provide
                        governance to create control environment.
                      </li>
                      <li>
                        <strong>Account Services:</strong> Most critical part of
                        the bank as it facilitates customer on-boarding and
                        provides account maintenance services to new and
                        existing customers.
                      </li>
                      <li>
                        <strong>Data and Analytics:</strong> Inculcates change
                        management culture, leads different campaigns to improve
                        staff awareness, and develops and disseminates analytics
                        against staff performance. Hence inculcating change
                        management culture by improving staff awareness at all
                        levels.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="ho">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#co"
                    aria-expanded="false"
                    aria-controls="co"
                  >
                    Operations Services
                  </button>
                </h2>
                <div
                  id="co"
                  className="accordion-collapse collapse"
                  aria-labelledby="ho"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      <strong>‘Operations Services’</strong> provides
                      foundational support to HBL’s dynamic Operating Model.
                      Through the extensive spread of its scope, it renders
                      relentless support to the frontline Business teams by
                      administering Business processes and procedures, some of
                      which run round the clock, culminating in quality customer
                      service.
                    </p>
                    <p>
                      The areas that collectively fall under the extensive
                      umbrella of ‘Operation Services’ are:
                    </p>
                    <h3>Digital Customer Experience</h3>
                    <p>
                      ‘Digital Customer Experience’ (DCE) is about creating
                      positive memories for customers through the delivery of
                      superior service, ensuring a continuous journey of
                      transformation. DCE’s multi-channel team provides robust
                      support to the Bank’s various segments (i.e. Retail,
                      Consumer, Branchless Banking, etc.) through Voice, email,
                      Social media, WhatsApp and IVR (self-service).
                    </p>
                    <h3>Trade Operations</h3>
                    <p>
                      ‘Trade operations’ is responsible for efficient processing
                      of all trade related transactions (Exports, Imports,
                      Letters of credit and Guarantees), while also ensuring
                      that the regulatory requirements are strictly adhered to
                      at all times. Trade operations provides critical support
                      to the trade business through delivery of swift and
                      error-free services leading to heightened customer
                      satisfaction.
                    </p>
                    <h3>Payment Services</h3>
                    <p>
                      ‘Payment Services’ provides a unified and centralized
                      platform for processing of payments pertaining to
                      Salaries, Pensions, Remittances, Clearing, Consumer Loans,
                      Debit &amp; Credit Cards, including Treasury transactions
                      initiated by Treasury Front Office. It provides round the
                      clock services by ensuring that the ATMs across the Bank’s
                      network remain fully functional at all times. Several
                      wings of payment services operate in harmony to create an
                      enhanced service experience for internal and external
                      customers.
                    </p>
                    <h3>International Operations</h3>
                    <p>
                      ‘International Operations’ acts as a focal point for the
                      International Branch Network in matters relating to
                      operations. It keeps vigilance on control issues and
                      creates a bridge between International locations and the
                      Head Office, while monitoring key operational aspects
                      through alignment of policies and procedures. It ensures
                      compliance of International Operating Model with the
                      respective regulatory environment while it also aligns
                      international policies with HBL’s global policies.
                    </p>
                    <h3>Business Policies &amp; Procedures</h3>
                    <p>
                      ‘Business Policies &amp; Procedures’ renders support to
                      units in developing Policies, Procedures, SOPs, Circulars
                      and Product Programs. It assists in addressing audit
                      observations and identifies opportunities for achieving
                      cost efficiency in processes of ‘Operation Services’.
                    </p>
                    <h3>Shared Services</h3>
                    <p>
                      An integral part of Operations Services, ‘Shared Services’
                      provides efficient support to entire network of HBL by
                      managing the evolving needs of stakeholders with optimal
                      utilization of resources. ‘Shared Services’ assists the
                      Bank to implement cost effective, sustainable, transparent
                      &amp; progressive solutions for Bank’s Procurement,
                      Properties, Construction / Renovation, Business Continuity
                      Planning, Security and Administrative affairs, ultimately
                      contributing towards profitability, efficiency &amp;
                      enhanced Brand Image of HBL.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hp">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cp"
                    aria-expanded="false"
                    aria-controls="cp"
                  >
                    Corporate Strategy
                  </button>
                </h2>
                <div
                  id="cp"
                  className="accordion-collapse collapse"
                  aria-labelledby="hp"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      Corporate Strategy Function’s primary role is to maintain
                      oversight and to drive the execution of the various
                      aspects of the Bank’s Strategy. The Function acting as a
                      lead, develops strategy and plans its implementation while
                      promoting the overall corporate goals. As a stabilizing
                      force, the Function gives direction to organizational
                      action and provides a common frame of reference in a
                      constantly evolving environment.
                    </p>
                    <p>
                      Working as a team across the Bank, the Function will
                      manage cross-business initiatives to create measurable
                      business value in terms of customer experiences, operating
                      performance, improved controls and generation of
                      bottom-line improvements.
                    </p>
                    <p>
                      The Function achieves this by focusing on the Bank’s
                      unique assets and taking advantage of them in a meaningful
                      way such that the Bank is distinguishable from competing
                      organizations and on course to becoming a client-centric
                      Technology Company with a Banking License.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex d-lg-none text-center my-3">
            <div className="col">
              <a href="/the-league/management-trainee/" className="rthome">
                <img src="/images/theleague/innerpage-back-button-arrow.png" />{" "}
                Management Trainee
              </a>
            </div>
            <div className="col ">
              <a href="/the-league/" className="rthome">
                Return to Home{" "}
                <img src="/images/theleague/innerpage-forward-button-arrow.png" />
              </a>
            </div>
          </div>
        </div>
        <Footertabmt />
      </section>
    </>
  );
};

export default Leagueyourrole;
