import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functionmarketingcorporateaffairs = () => {
  return (
    <>
<Helmet>
<title>Marketing and Corporate Affairs | HBL People</title>
<meta
name="description"
content="Jahan Khawab Wahan HBL"
/>
</Helmet>
<section className="innerpagebanner marketing-corporate-affairs">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Marketing and Corporate Affairs</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Jahan Khawab Wahan HBL</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages marketing-corporate-affairs">
  <div className="container d-flex flex-wrap gap-3">
    <p>Marketing &amp; Corporate Affairs, drives and protects the HBL Brand through brand building activities and communications via conventional and digital channels. It also provides guidance on the Bank’s strategic narrative to the various business functions across the franchise.</p>
    <p>M&amp;CA structure and areas of specialization include:</p>
    <div>
      <h4>Brand Management</h4>
      <p>Responsible for creating public awareness and image building for the Bank’s Corporate brand and Product &amp; Services via diverse ATL & BTL marketing channels. Drive the brand equity of HBL as a corporate brand and ensure consistent market presence.</p>
    </div>
    <div>
      <h4>Digital Marketing</h4>
      <p>Responsible for creating awareness, building brand equity and acquiring new customers by reaching out to relevant audiences on digital platforms. This team leads all digital marketing initiatives from campaign ideation to execution, measurement and optimization, and ensuring all digital efforts are integrated with relevant functions within the Bank to achieve business objectives. Digital Marketing at HBL focuses on 4 core areas: Social Media Effectiveness, Digital Marketing Acquisition, Digital Experience Design, and augmenting HBL’s Digital Credentials.</p>
    </div>
    <div>
      <h4>Media</h4>
      <p>Oversees HBL's media presence across various channels, including television, print, and radio to develop and implement effective media strategies for brands' awareness, visibility, and recognition.</p>
      <p>Brand Experience: Responsible for the following:</p>
      <ul>
        <li>Development, planning and executions of all Activations and events (internal &amp; external) for the Bank. </li>
        <li> Manages all out of home media including Branches and HBL premises. </li>
        <li> Manage giveaways (customers and internal) for the entire Bank. </li>
        <li>Manages all sponsorships across the Bank, including the success of Karachi Literature Festival, She Loves Tech (A Women Entrepreneurial Competition), The Future Summit, and 092 Disrupt. One of the Bank's most significant sponsorships is the HBL Pakistan Super League, for which HBL has title sponsorship.</li>
      </ul>
    </div>
    <div>
      <h4>Corporate Affairs</h4>
      <p>Responsible for strategically planning, implementing and monitoring communication initiatives to ensure that the organization’s narrative is effectively conveyed to internal and external audiences. Plays a pivotal role in reputation management and crisis communication for the organization. The function also works with stakeholders for employee engagement and managing Donations for the Bank. </p>
    </div>
  </div>
</section>
<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functionmarketingcorporateaffairs
