import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functionfinancialinstitutionsandglobalremittancesbusiness  = () => {
  return (
    <>
    <Helmet>
<title>Financial Institutions and Global Remittances Business (FI-GRB) | HBL People</title>
<meta
name="description"
content="EXPANDING FINANCIAL OPPORTUNITIES FOR HBL'S CLIENTS GLOBALLY"
/>
</Helmet>
<section className="innerpagebanner china-business">
  <div className="container">
    <div className="in" style={{maxWidth: '746px'}}>
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Financial Institutions and Global Remittances Business (FI-GRB)</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">EXPANDING FINANCIAL OPPORTUNITIES FOR HBL'S CLIENTS GLOBALLY</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages china-business">
  <div className="container d-flex flex-wrap gap-3">
    <p>FI-GRB is a vital component of HBL, responsible for managing all aspects of the bank’s relationships with both domestic and international financial institutions, non-banking financial institutions and money service providers. </p>

<p>Key features of the department encompass establishing and nurturing FI relationships domestically and across international locations, acting as a conduit for other banks facilitating the structuring of trade-related transactions within HBL’s extensive network, promoting trade finance endeavors and home remittances from across the globe. Presently, FI-GRB covers correspondent banking relationships in over 80 international countries including arrangements with over 2,000 banks for HBL network globally. This allows the bank to cater to retail, commercial and corporate clients’ diverse requirements in an efficient manner. </p> 

<p>FI-GRB also offers a comprehensive suite of banking solutions tailored to non-banking financial institutions, encompassing long-term and short-term lending facilities, customized deposit products under both conventional and Islamic modes, innovative digital transaction and employee banking solutions for seamless transactions, consumer banking products, and structured derivatives for hedging market risk.</p>

  </div>
</section>

    <Testimonials />
    <Functionfilter />
        </>
  )
}

export default Functionfinancialinstitutionsandglobalremittancesbusiness
