import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functionconsumeragriculture = () => {
  return (
    <>
    <Helmet>
<title>Consumer Agriculture and SME Banking | HBL People</title>
<meta
name="description"
content="Breaking Barriers, leading from the Front & Fueling the Economy"
/>
</Helmet>
<section className="innerpagebanner consumer-agriculture">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Consumer Agriculture and SME Banking</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Breaking Barriers, leading from the Front & Fueling the Economy </h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages consumer-agriculture">
  <div className="container d-flex flex-wrap gap-3">
    <p>HBL’s Consumer Agriculture and SME Banking business is focused on providing value to customers while enabling them to achieve their financial aspirations. HBL consumer banking is a market leader and provides Credit Cards, Personal Loans, Auto Loans, Debit Cards and Merchant Acquiring products to our customers.</p>
    <div>
      <h4>Agriculture Banking</h4>
      <p>HBL is committed to cater to the banking needs of farmers and agricultural communities. It continues to play a vital role for providing tailor made agricultural financial services through its vast network of agri branches and a dedicated field force of Agriculture Banking. The bank is a market leader in farmer financing and continues to innovate and digitalize the customer journey for enhanced outreach with qualitative service.</p>
    </div>
    <div>
      <h4>SME Banking</h4>
      <p>Being the largest lender, we support small and medium businesses with program-based, conventional and seasonal lending products. Over the years we have focused towards small enterprises by offering unsecured cash-flow based lending products such as Asaan Finance. HBL offers SMEs whole array of financial products which includes lending and trade facilities, while we are also one of the lead Banks in promoting SBP scheme-based financing to promote this sector as a growth engine for the national economy.</p>
    </div>
  </div>
</section>

    <Testimonials />
    <Functionfilter />
        </>
  )
}

export default Functionconsumeragriculture
