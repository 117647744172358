import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Intheader from "../../../../components/Intheader";
import Intheadermob from "../../../../components/Intheadermob";

const Intpost6 = () => {
  const images = [
    "/images/theleague/internship/posts/6/1.jpg",
    "/images/theleague/internship/posts/6/2.jpg",
    "/images/theleague/internship/posts/6/3.jpg",
    "/images/theleague/internship/posts/6/4.jpg",
    "/images/theleague/internship/posts/6/5.jpg",
    "/images/theleague/internship/posts/6/6.jpg",
    "/images/theleague/internship/posts/6/7.jpg",
  ];


  return (
    <>
      <Helmet>
        <title>
          The League Internship Programme 2018: Team Bonding Activity | THE
          LEAGUE
        </title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
          <Intheader />
          <h1 className="my-3">
            The League Internship Programme 2018: Team Bonding Activity
          </h1>
          <p>
            The interns attended a full-day training session on Team Bonding.
            Through interactive discussions and hands-on exercises, they learnt
            about building team competitiveness, creating synergies and managing
            team conflicts.
          </p>
          <Lightbox images={images} />
          <Intheadermob />
        </div>
      </section>
    </>
  );
};

export default Intpost6;
