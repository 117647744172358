import React,{useEffect} from 'react'
import { Helmet } from "react-helmet";
const Diversity = () => {
    useEffect(() => {
        const mySwiper = new window.Swiper(".mySwiper", {
          slidesPerView: 1,
          spaceBetween: 1,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
    
      }, []);
  return (
    <>
    <Helmet>
<title>Diversity, Equity & Inclusion | HBL People</title>
<meta
name="description"
content="Together We Are Strong"
/>
</Helmet>
      <section className="innerpagebanner diversity">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top left 0.3s and move 65px wait .3s">DIVERSITY, EQUITY & INCLUSION</h1>
      <h2 data-sr="enter bottom left 0.3s and move 65px wait .3s">Together We Are Strong</h2>
    </div>
  </div>
</section>
<section className="innersection diversity">
  <div className="container">
    <div className="text-center">
      <h3 className="web" data-sr="enter top left 0.3s and move 65px wait .3s">CELEBRATING DIFFERENCES</h3>
      <h2 className="web" data-sr="enter top left 0.3s and move 65px wait .6s">Gender Diversity &amp; Inclusion</h2>
      <p data-sr="enter top left 0.3s and move 65px wait .9s">HBL strongly believes that furthering diversity and inclusion will benefit the culture of the organization, provide employment opportunities for an untapped market along with supporting the agenda of financial inclusion. HBL’s Diversity and Inclusion agenda emphasizes the importance of being inclusive at all levels and there is no discrimination based on gender, ethnicity, or religious affiliations. </p>
      <p className="mt-3" data-sr="enter top left 0.3s and move 65px wait 1.2s">We are building a gender inclusive workplace where we celebrate and leverage the strength of our rich and diverse. At HBL, women are becoming a progressive part of the workforce and trust the bank with their wellbeing and safety. HBL has carefully crafted and implemented several policies that ensure women’s safety and encourage their retention at the Bank. </p>
      <h4 className="mt-4" data-sr="enter top left 0.3s and move 65px wait 1.5s">Milestones on the path to financial and workforce inclusion</h4>
    </div>
    <img src="images/diversity/timeline-new.png" className="img-fluid d-block mt-5 mx-auto" alt=""/> </div>
</section>
<section className="innersection diversity commitment">
  <div className="container">
    <div className="text-center mb-5">
      <h3 className="web" data-sr="enter top left 0.3s and move 65px wait .3s">Our Commitment</h3>
      <h2 className="web" data-sr="enter top left 0.3s and move 65px wait .6s">BUILDING A DIVERSE ECO SYSTEM</h2>
      <p className="mb-3" data-sr="enter top left 0.3s and move 65px wait .9s">HBL is acutely aware of the key challenges which require our immediate attention to generate sustainable outcomes. </p>
      <p data-sr="enter top left 0.3s and move 65px wait 1.2s">We understand that we need to increase the engagement of women in financial services not just as customers but also as employees. With this objective, we have consciously been recruiting an increasing number of women while ensuring they are provided equal opportunities through initiatives such as HBL Waapsi – Female Returnship Program for advancement and success as we are intrinsically linked with the nation’s fabric and its people. At HBL, we also understand that a functional civil society is predicated on economic progress and social development, which can work towards mitigating climate change challenges. All these factors are required for ensuring sustainable development. </p>
    </div>
    <div className="row">
      <div className="col-lg-3">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait .3s"><a href="https://www.hbl.com/assets/documents/Impact__Sustainability_Report_-_2022.pdf"  target="_blank"  rel="noreferrer noopener"> <img src="images/diversity/enriching-lives.jpg" alt=""/>
          <h4 className="mt-3 mb-2">Enriching Lives</h4>
          <p>HBL is inculcating sustainability and impact measurement as a cross-sectional element in its essence of ‘Enriching Lives’. We have contributed over Rs 4 billion, spread over a decade, for the social uplift of Pakistan </p>
          <div className="link"><img src="images/arrowgreen.png" alt=""/> View Impact Sustainability Report</div>
          </a> </div>
      </div>
      <div className="col-lg-3">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait .6s"> <img src="images/diversity/corporate.jpg" alt=""/>
          <h4 className="mt-3 mb-2">Corporate Social Responsibility</h4>
          <p>HBL believes in giving back to the community. Our Employee Volunteering Program is playing an instrumental role in enabling HBL Family to create value for the society through different initiatives.</p>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait .9s"> <img src="images/diversity/increasing.jpg" alt=""/>
          <h4 className="mt-3 mb-2">Increasing Opportunities for Women</h4>
          <p>To foster a more inclusive and equitable environment, HBL has launched a Female Return-ship Program, which will provide opportunities to women, who have had a career break, to restart their professional journey.</p>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="in" data-sr="enter top left 0.3s and move 65px wait 1.2s"> <img src="images/diversity/building.jpg" alt=""/>
          <h4 className="mt-3 mb-2">Building a Diverse Ethnic and Geographic Culture</h4>
          <p>Building a workplace where everyone can belong at 12 locations with over 25 different nationalities</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="innersection diversity disability">
  <div className="container">
    <div className="in pb-0" data-sr="enter left 0.3s and move 65px wait .3s"> <img src="images/diversity/icon-disability.png" alt=""/>
      <h4 className="text-uppercase">Disability Inclusion</h4>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <div className="in pt-0">
          <p data-sr="enter top left 0.3s and move 65px wait .4s">HBL's diverse workspace is one of our most valuable assets.  We are a disability confident organization and aim to create an inclusive environment that embraces and enables all employees to reach their full potential. HBL partnered with NOWPDP (Network of Organizations working for Persons with Disabilities) and with STEP (Special Talent Exchange Program) for inclusion of PWDs as part of the workforce and sensitization of staff. Leveraging technology in times of COVID, HBL also designed and launched a comprehensive e-learning module on Disability Inclusion which is mandatory for all staff. We have zero-tolerance for discrimination against PWDs. </p>
          <p data-sr="enter top left 0.3s and move 65px wait 1.2s">For year-round awareness and emphasis on inclusion, extensive internal and external communication campaigns are designed with focus on disability inclusion and the concept of equity. An annual internship program has also been launched that hires, trains and places PWDs across diverse functions.</p>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="in pt-0">
          <p data-sr="enter top left 0.3s and move 65px wait .8s"> For integration of PWD employees into the bank, a Buddy program attaches an employee with incoming PWDs to support them for a 3-month period. We now have 80 Persons with Disabilities working for HBL and also have an approved Disability Inclusion framework (as mandated by SBP) to direct a concerted approach for the inclusion of PWDs as employees as well as customers.</p>
          <p data-sr="enter top left 0.3s and move 65px wait 1.2s">Identifying accessibility as a major challenge for PWDs, HBL is continually focusing on infrastructural upgrades across its network for our differently-abled employees and customers, and has converted 35 of its branches across Pakistan into model branches offering complete access to PWDs. Additionally, Bank has been building enabling infrastructure and taken initiatives such as installing ramps in multiple branches, introducing off-site ATMs, set-up talking ATMs and has made Braille forms available in all our regional headquarters.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="home-three">
  <div className="container">
    <div className="text-center">
      <h3 className="web" data-sr="enter top left 0.3s and move 65px wait .3s">HBL STORIES</h3>
      <h2 className="web" data-sr="enter top left 0.3s and move 65px wait .6s">Leadership matters. <br/>
        For this generation and the next </h2>
    </div>
  </div>
  <div className="swiper mySwiper">
    <div className="swiper-wrapper">
      <div className="swiper-slide">
        <div className="container">
          <div className="row align-items-center my-5 gx-xl-1">
            <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
              <p>I grew up in Multan, a city which offers fewer opportunites for women. From an early age I saw my father struggle to give us a comfortable life and as the eldest child I was determined to support him. </p>
            </div>
            <div className="col-lg-12 col-xl-4 order-1 order-xl-2"><img src="images/diversity/testimonials/ifrah.png" alt="" className="img-fluid d-block mx-auto"/></div>
            <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
              <h5>#IronLady</h5>
              <h4>Ifrah Magsi <span>Program Manager<br/>
                L&amp;D</span></h4>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className="container">
          <div className="row align-items-center my-5 gx-xl-1">
            <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
              <p> As the eldest child in my family, I always had to shoulder some responsibilities. Since a very early age, I saw that my father, despite the hardship of life, continued to struggle with a quiet dignity. He taught me a verse of Quran that says each person will have what they endeavor towards. This verse changed my life completely as it made me more eager to overcome challenges and be dedicated to my goals. </p>
            </div>
            <div className="col-lg-12 col-xl-4 order-1 order-xl-2"><img src="images/diversity/testimonials/zunairah.png" alt="" className="img-fluid d-block mx-auto"/></div>
            <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
              <h5>#IronLady</h5>
              <h4>Zunaira Ali Siddiqui <span>Assistant Manager <br/>
                Budgeting Analysis and Accurals</span></h4>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className="container">
          <div className="row align-items-center my-5 gx-xl-1">
            <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
              <p> As a child I was a very timid person,however, I was excellent in my academic performance. My accomplishment started from earning a distinction in the entire primary section of my school and continued till I graduated. </p>
            </div>
            <div className="col-lg-12 col-xl-4 order-1 order-xl-2"><img src="images/diversity/testimonials/sumaira.png" alt="" className="img-fluid d-block mx-auto"/></div>
            <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
              <h5>#IronLady</h5>
              <h4>Sumaira Jalil <span>Analyst <br/>
                Finance International</span></h4>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className="container">
          <div className="row align-items-center my-5 gx-xl-1">
            <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
              <p> Working with HBL for 42 years has been nothing short of a blessing. Throughout my tenure, the Bank has empowered me to provide quality life and good education to my family. With the New Service Age, I will be able to continue to elevate our living for an additional 5 years. </p>
            </div>
            <div className="col-lg-12 col-xl-4 order-1 order-xl-2"><img src="images/diversity/testimonials/mumraz-ahmed.png" alt="" className="img-fluid d-block mx-auto"/></div>
            <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
              <h5>#ActivelyEmployed</h5>
              <h4>Mumtaz Ahmed Khan </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className="container">
          <div className="row align-items-center my-5 gx-xl-1">
            <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
              <p> I feel proud to be part of HBL, a bank that takes care of its employees like its own family. Increasing the service age to 65 years is an excellent step for employees like myself who were approaching the retirement age. With my husband already having retired, I am the sole earner for my family and these 5 extra years make our future look brighter than before. Thank you. </p>
            </div>
            <div className="col-lg-12 col-xl-4 order-1 order-xl-2"><img src="images/diversity/testimonials/attiya.png" alt="" className="img-fluid d-block mx-auto"/></div>
            <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
              <h5>#ActivelyEmployed</h5>
              <h4>Attya Sultana </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className="container">
          <div className="row align-items-center my-5 gx-xl-1">
            <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
              <p>Being a single parent was never easy. Particularly now that I was approaching my retirement age, my son’s education expenses were starting to worry me. Announcement of the New Service Age by HBL however relieved me of all my stresses and I feel secure in my child’s future. HBL is truly a #BankWithASoul; caring for its employees like family. </p>
            </div>
            <div className="col-lg-12 col-xl-4 order-1 order-xl-2"><img src="images/diversity/testimonials/kehkasha.png" alt="" className="img-fluid d-block mx-auto"/></div>
            <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
              <h5>#ActivelyEmployed</h5>
              <h4>Kehkeshan Habib </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className="container">
          <div className="row align-items-center my-5 gx-xl-1">
            <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
              <p>Raising the New Service Age is an unparalleled initiative, specifically in light of today’s economic conditions. It will give me an additional 5 years to support my family. HBL has just raised the bar really high with this step and I feel immense pride to have witnessed the announcement first hand. Way to go HBL.</p>
            </div>
            <div className="col-lg-12 col-xl-4 order-1 order-xl-2"><img src="images/diversity/testimonials/colin.png" alt="" className="img-fluid d-block mx-auto"/></div>
            <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
              <h5>#ActivelyEmployed</h5>
              <h4>Colin Anthony </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className="container">
          <div className="row align-items-center my-5 gx-xl-1">
            <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
              <p>HBL has instilled in me an exceptional sense of problem-solving, business ethics, effective communication, time-management and the importance of being an accountable professional. I feel I am at my best when I am at work - a place where professionalism is at its finest!</p>
            </div>
            <div className="col-lg-12 col-xl-4 order-1 order-xl-2"><img src="images/diversity/testimonials/mert.png" alt="" className="img-fluid d-block mx-auto"/></div>
            <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
              <h5>#IAmHBL</h5>
              <h4>Mert Kilicoglu </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className="container">
          <div className="row align-items-center my-5 gx-xl-1">
            <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
              <p>At HBL, you learn from the best individuals in the industry and bring purpose to life through your work. I am ecstatic to be part of the transformation journey aimed at digitizing the economy, a step towards our vision of becoming a Technology Company with a Banking License.</p>
            </div>
            <div className="col-lg-12 col-xl-4 order-1 order-xl-2"><img src="images/diversity/testimonials/umer.png" alt="" className="img-fluid d-block mx-auto"/></div>
            <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
              <h5>#IAmHBL</h5>
              <h4>Umer Mehmood </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className="container">
          <div className="row align-items-center my-5 gx-xl-1">
            <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
              <p>I always wanted to be part of a culture where I feel less restricted and more autonomous; HBL is exactly that. It provides me the platform where I enjoy absolute liberty in my work and I continuously get better at what I do through the multiple coaching and mentorship programs.</p>
            </div>
            <div className="col-lg-12 col-xl-4 order-1 order-xl-2"><img src="images/diversity/testimonials/bina.png" alt="" className="img-fluid d-block mx-auto"/></div>
            <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
              <h5>#IAmHBL</h5>
              <h4>Bina Abbas </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className="container">
          <div className="row align-items-center my-5 gx-xl-1">
            <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
              <p>You might not believe this - but I'm an architect, working as an architect at HBL! Sounds confusing? It does, to many - but that's exactly the depth and breadth of work exposure this Bank offers to us and hones our expertise. I feel valued and blessed to be doing what I do best.</p>
            </div>
            <div className="col-lg-12 col-xl-4 order-1 order-xl-2"><img src="images/diversity/testimonials/qurat.png" alt="" className="img-fluid d-block mx-auto"/></div>
            <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
              <h5>#IAmHBL</h5>
              <h4>Qurat Ul Ain Zaidi </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className="container">
          <div className="row align-items-center my-5 gx-xl-1">
            <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
              <p>HBL is a place which has taught me that everything is possible if you are motivated, passionate and focused on achieving the goals every single day. Trust in yourself and see the best unveil itself.</p>
            </div>
            <div className="col-lg-12 col-xl-4 order-1 order-xl-2"><img src="images/diversity/testimonials/saif.png" alt="" className="img-fluid d-block mx-auto"/></div>
            <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
              <h5>#IAmHBL</h5>
              <h4>Saif Ali Bokhari </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-slide">
        <div className="container">
          <div className="row align-items-center my-5 gx-xl-1">
            <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
              <p>This Bank provides me with sheer determination and a strong sense of self-belief. It has polished and nurtured my skills to the extent that I tackle challenges head-on and make the best out of every situation.</p>
            </div>
            <div className="col-lg-12 col-xl-4 order-1 order-xl-2"><img src="images/diversity/testimonials/syeda-zehra.png" alt="" className="img-fluid d-block mx-auto"/></div>
            <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
              <h5>#IAmHBL</h5>
              <h4>Syeda Zehra Sajjad </h4>
            </div>
          </div>
        </div>
      </div>
		<div className="swiper-slide">
        <div className="container">
          <div className="row align-items-center my-5 gx-xl-1">
            <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
              <p>"My team's encouragement and my sup- ervisor's unwavering support propelled me from an intern to a permanent position within the organization. Their guidance helped me discover new skills and personality traits that have become a stepping stone for my development."</p>
            </div>
            <div className="col-lg-12 col-xl-4 order-1 order-xl-2"><img src="images/diversity/testimonials/muhammad-naveed.png" alt="" className="img-fluid d-block mx-auto"/></div>
            <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
              <h5>#PWD</h5>
              <h4>Muhammad Naveed Iqbal </h4>
            </div>
          </div>
        </div>
      </div>
		<div className="swiper-slide">
        <div className="container">
          <div className="row align-items-center my-5 gx-xl-1">
            <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
              <p>"My supervisor and team cultivated a diverse and inclusive environment. Their support throughout the process was un- wavering. It motivated me to work at my full potential, and even before completing the internship I was able to secure a permanent role outside the Bank."</p>
            </div>
            <div className="col-lg-12 col-xl-4 order-1 order-xl-2"><img src="images/diversity/testimonials/muhammad-atif.png" alt="" className="img-fluid d-block mx-auto"/></div>
            <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
              <h5>#PWD</h5>
              <h4>Muhammad Atif Jilani </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="swiper-button-next"></div>
    <div className="swiper-button-prev"></div>
  </div>
</section>
    </>
  )
}

export default Diversity
