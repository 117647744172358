import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Intheader from "../../../../components/Intheader";
import Intheadermob from "../../../../components/Intheadermob";

const Intpost10 = () => {
  const images = [
    "/images/theleague/internship/posts/10/1.jpg",
    "/images/theleague/internship/posts/10/2.jpg",
    "/images/theleague/internship/posts/10/3.jpg",
    "/images/theleague/internship/posts/10/4.jpg",
    "/images/theleague/internship/posts/10/5.jpg",
    "/images/theleague/internship/posts/10/6.jpg",
    "/images/theleague/internship/posts/10/7.jpg",
    "/images/theleague/internship/posts/10/8.jpg",
    "/images/theleague/internship/posts/10/9.jpg",
    "/images/theleague/internship/posts/10/10.jpg",
    "/images/theleague/internship/posts/10/11.jpg",
    "/images/theleague/internship/posts/10/12.jpg",
  ];

  return (
    <>
      <Helmet>
        <title>
          The League Internship Programme 2018: Lunch with TED | THE LEAGUE
        </title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
          <Intheader />
          <h1 className="my-3">
            The League Internship Programme 2018: Lunch with TED
          </h1>
          <p>
            The League interns learnt about design thinking over a Lunch with
            TED session scheduled for Karachi, Lahore and Islamabad based
            interns. Through a TED talk by Tim Brown and some engaging
            activities, they explored how design thinking principles can be
            applied to our everyday life.
          </p>
          <Lightbox images={images} />
          <Intheadermob />
        </div>
      </section>
    </>
  );
};

export default Intpost10;
