import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Campheader from "../../../../components/Campheader";
import Campheadermob from "../../../../components/Campheadermob";

const Campost26 = () => {
  const images = [
    "/images/theleague/mt/posts/26/1.jpg",
    "/images/theleague/mt/posts/26/2.jpg",
    "/images/theleague/mt/posts/26/3.jpg",
    "/images/theleague/mt/posts/26/4.jpg",
    "/images/theleague/mt/posts/26/5.jpg",
    "/images/theleague/mt/posts/26/6.jpg",
    "/images/theleague/mt/posts/26/7.jpg",
    "/images/theleague/mt/posts/26/8.jpg",
    "/images/theleague/mt/posts/26/9.jpg",
    "/images/theleague/mt/posts/26/10.jpg",
    "/images/theleague/mt/posts/26/11.jpg",
    "/images/theleague/mt/posts/26/12.jpg",
    "/images/theleague/mt/posts/26/13.jpg",
    "/images/theleague/mt/posts/26/14.jpg",
    "/images/theleague/mt/posts/26/15.jpg",
    "/images/theleague/mt/posts/26/16.jpg",
    "/images/theleague/mt/posts/26/17.jpg",
    "/images/theleague/mt/posts/26/18.jpg",
    "/images/theleague/mt/posts/26/19.jpg",
    "/images/theleague/mt/posts/26/20.jpg",
    "/images/theleague/mt/posts/26/21.jpg",
    "/images/theleague/mt/posts/26/22.jpg",
    "/images/theleague/mt/posts/26/23.jpg",
    "/images/theleague/mt/posts/26/24.jpg",
    "/images/theleague/mt/posts/26/25.jpg",
    "/images/theleague/mt/posts/26/26.jpg",
    "/images/theleague/mt/posts/26/27.jpg",
    "/images/theleague/mt/posts/26/28.jpg",
    "/images/theleague/mt/posts/26/29.jpg",
    "/images/theleague/mt/posts/26/30.jpg",
    "/images/theleague/mt/posts/26/31.jpg",
  ];
  return (
    <>
      <Helmet>
        <title>
        NUST Campus Drive 2019 | THE LEAGUE
        </title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
          <Campheader />
          <h1 className="my-3">NUST Campus Drive 2019</h1>
          <Lightbox images={images} />
          <Campheadermob />
        </div>
      </section>
    </>
  );
};

export default Campost26;
