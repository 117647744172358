import React, { useEffect } from "react";
import "magnific-popup/dist/magnific-popup.css";
import "magnific-popup/dist/jquery.magnific-popup";
import $ from "jquery";
import { Helmet } from "react-helmet";

const Impact = () => {
    useEffect(() => {
   
      $('.youtubepopup').magnificPopup({
        type: 'iframe',
        iframe: {
          patterns: {
            wistia: {
              index: 'wistia.com',
              id: function(url) {        
                  var m = url.match(/^.+wistia.com\/(medias)\/([^_]+)[^#]*(#medias=([^_&]+))?/);
                  if (m !== null) {
                      if(m[4] !== undefined) {
                          return m[4];
                      }
                      return m[2];
                  }
                  return null;
              },
              src: '//fast.wistia.net/embed/iframe/%id%?autoPlay=0'
            }
          }
        }
      });
      }, []);
  return (
    <>
    <Helmet>
<title>Impact through Innovation | HBL People</title>
<meta
name="description"
content="Stirring disruption with an Edge"
/>
</Helmet>
      <section className="innerpagebanner impact">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">IMPACT THROUGH INNOVATION</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Stirring disruption with an Edge</h2>
    </div>
  </div>
</section>
<section className="innersection impact">
  <div className="container">
    <div className="text-center">
      <h3 className="web" data-sr="enter top over 0.3s and move 65px wait 0.3s">Initiate, Innovate, &amp; Accelerate </h3>
      <h2 className="web" data-sr="enter top over 0.3s and move 65px wait 0.6s">MAKING AN IMPACT THROUGH TECHNOLOGY</h2>
      <p data-sr="enter top over 0.3s and move 65px wait 0.9s">At HBL, advancing through innovation is our main priority. We constantly undergoing a sustainable move of being a fully digitized bank. We strive to continue to put forth efforts to advance and impact further through innovation collectively with pioneering and unparalleled solutions. This can be witnessed by the ground-breaking initiatives taken by our teams on ground. Gamification of candidates, digital signing of letters etc. </p>
    </div>
  </div>
</section>
<section className="innersection impact process">
  <div className="container">
    <div className="text-center mb-5">
      <h3 className="web" data-sr="enter top over 0.3s and move 65px wait 0.3s">HBL Induction Process </h3>
      <h2 className="web" data-sr="enter top over 0.3s and move 65px wait 0.6s">we deliver what we believe in! </h2>
      <p data-sr="enter top over 0.3s and move 65px wait 0.9s">We at HBL are proud to be the first organization in the Banking industry to implement concrete measures to support paperless environment by introducing digital solutions for our recruitment activities. This includes but not limited to, remote facilitation of Interviews, meetings and Digital documentation. </p>
    </div>
  </div>
  <div className="container text-center" style={{ maxWidth: '1100px' }}>
    <div className="row">
      <div className="col-lg-3">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 0.3s"> <img src="images/impact/icon-candidate-applies.png" alt=""/>
          <h5>Apply at Job</h5>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 0.6s"> <img src="images/impact/icon-candidate-experiances.png" alt=""/>
          <h5>Experience gamified assessment</h5>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 0.9s"> <img src="images/impact/icon-paperless.png" alt=""/>
          <h5>Attend online interview</h5>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 1.2s"> <img src="images/impact/icon-onboarding.png" alt=""/>
          <h5>Digital Documentation and onboarding process upon selection</h5>
        </div>
      </div>
    </div>
	  <div className="text-center">
		  <p className="my-4">HBL Family’s contributions play an instrumental role in our consistent growth and their testament speaks volumes of how innovation has been setting new eff benchmarks at HBL:</p>
		  <p style={{color: '#333' , fontSize: '24px' , fontWeight: '400'}}><em>“I would like to thank the HBL team for answering my queries/concerns and for setting me up with this great job opportunity. I can definitely see myself making a great career here”</em><span className="d-block" style={{color:'#197056'}}><em>Kashif Anas – Business Analyst</em></span></p>
	  </div>
  </div>
</section>
<a  href="https://hblpeople.wistia.com/medias/edvajgxeoq"  className="link youtubepopup">
<section className="innersection impact redefining">
  <div className="container">
    <div className="row">
		<div className="col-lg-7">
        <div className="in" data-sr="enter left over 0.3s and move 65px wait 0.3s"> <img src="images/impact/icon-redefining.png" alt=""/>
          <h4 className="text-uppercase">Redefining the Learning Landscape</h4>
          <p>Fostering a Technology Driven Learning Culture. It is of utmost importance at HBL to provide an environment of continuous learning and growth. Utilizing the power of digitization, innovative tools and a robust learning platform, employees are empowered to become the best versions of themselves. Enabling them to become more agile in developing and learning new set of skills that will subsequently impact the progress of organization for good.</p>
        </div>
      </div>
      
    </div>
  </div>
</section>
</a>
<section className="innersection culture">
  <div className="container">
    <div className="text-center mb-5">
      <h3 className="web" data-sr="enter top over 0.3s and move 65px wait 0.3s">GAMIFICATION </h3>
      <h2 className="web" data-sr="enter top over 0.3s and move 65px wait 0.6s">HIRING THROUGH EXPERIENCES</h2>
      <p data-sr="enter top over 0.3s and move 65px wait 0.9s"> Here at HBL we are constantly evolving through innovative ideas and motives to ensure customer and employee satisfaction. Working at HBL is an enlightening experience due to our vast state-of-the-art projects constant pursuit of excellence. Our main priority is to enhance the overall experience of working at HBL.</p>
    </div>
	    </div>
 <div className="container singleSwiper impactsliderbga">
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-7">
                <div className="in">
                  <h4>We also have the honor of introducing the gamified assessments to facilitate faster and phenomenal experience to those willing to explore opportunities at HBL.</h4>
                  <p>“The recruitment process at HBL was seamless. I was contacted frequently at every stage from the recruitment team and the responses were quick. Overall the experience was efficient &amp; prompt and received satisfactory answers to my queries.” <br/>
- Ms. Rafia Riaz – Regional Quality Manager</p>
					<a  href="https://hblpeople.wistia.com/medias/jeszvg9hjj"  className="link youtubepopup fw-bold mt-3 d-block">Watch our Gamified Assessment Walkthrough Video</a>
                </div>
              </div>
            </div>
          </div>

</section>
    </>
  )
}

export default Impact
