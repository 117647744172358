import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Testimonails from "../components/Testimonials";
import Functionfilter from "../components/Functionfilter";



const Home = () => {
  useEffect(() => {
    let menu = ["Video", "EXPERIENCED", "The League", "FRESH GRADUATEs"];
    // let swiper = 
    new window.Swiper(".swiper-container", {
      effect: "fade",
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return (
           `<span class="${className}">${menu[index]}</span>`
          );
        },
      },
    });

    //let swiper2 = 
    new window.Swiper(".text-container", {
      effect: "fade",
      noSwiping: false,
      autoplay: {
        delay: 5000,
      },
    });
    //let swipermob = 
    new window.Swiper(".mobbanner", {
      effect: "fade",
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
       renderBullet: function (index, className) {
          return (
            `<span class="${className}">${menu[index]}</span>`
          );
        },
      },
    });
    //-----------------------------------
    function setupVideoTextOverlay(videoClass, textOverlayClass, videoSource) {
      const video = document.querySelector(`.${videoClass}`);
      const textOverlay = document.querySelector(`.${textOverlayClass}`);

      var sa =
        "<h2> Pakistan's First Bank, <br/> HBL has enabled the dreams of <br/> countless pakistanis </h2>";
      var sb =
        "<h2> Our People </h2> <p>are a diverse collection of talent</p>";
      var sc =
        "<p>operating in a</p><h2> World- Class Environment </h2> <p>enabling them to create value for our clients</p>";
      var sd =
        "<h2> Our Inclusive Culture </h2> <p>encourages growth and fuels excellence</p>";
      var se =
        "<p>our transformational journey to becoming</p><h2> A Technology company with a banking License </h2>";
      var sf =
        "<p>is powered by</p><h2> State-of-the-art Infrastructure</h2> <p>putting us at the forefront of innovation</p>";
      var sg =
        "<h2> Our Global Footprint </h2> <p>helps us showcase the best of pakistan to the world</p>";
      var sh =
        "<p>as we continue our mission of</p><h2> Enabling Dreams & Enriching Lives </h2> ";
      var s1 = "<h1>Let's Create the NEW</h1>";

      const textData = [
        { text: sa, time: 0 },
        { text: sb, time: 6 },
        { text: sc, time: 15 },
        { text: sd, time: 24 },
        { text: se, time: 30 },
        { text: sf, time: 36 },
        { text: sg, time: 43 },
        { text: sh, time: 48 },
        { text: s1, time: 53 },
      ];

      // Display text on video timer
      video.addEventListener("timeupdate", function () {
        const currentTime = Math.floor(video.currentTime % video.duration);
        const currentText = textData.find((item) => currentTime === item.time);

        if (currentText) {
          textOverlay.innerHTML = currentText.text;
        }
      });
    }

    setupVideoTextOverlay(
      "desktop-video",
      "desktop-text-overlay",
      "video1.mp4"
    );

    setupVideoTextOverlay("mobile-video", "mobile-text-overlay", "video1.mp4");

    //----------------------------------

    //let swiper4 = 
    new window.Swiper(".whyhbl", {
      slidesPerView: 1,
      spaceBetween: 10,
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 0,
        },
        1350: {
          slidesPerView: 5,
          spaceBetween: 0,
        },
        1550: {
          slidesPerView: 6,
          spaceBetween: 0,
        },
      },
    });

    //------------------------

    //let swiper5 = 
    new window.Swiper(".lifehbl", {
      slidesPerView: 1,
      spaceBetween: 10,
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1550: {
          slidesPerView: 4,
          spaceBetween: 0,
        },
      },
    });

    //-----------------------------------

    //const mySwiper = 
    new window.Swiper(".mySwiper", {
      slidesPerView: 1,
      spaceBetween: 1,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    //------------ filter
    // $("#myInput").on("keyup", function () {
    //   var value = $(this).val().toLowerCase();
    //   $(".grid a").filter(function () {
    //     $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    //   });
    // });

    //------------------------

    //-- home video section
    document.getElementById("myVid").addEventListener("mouseover", function () {
      this.play();
    });
    document
      .getElementById("myVid")
      .addEventListener("mouseleave", function () {
        // this.pause();
        this.load();
      });
    document
      .getElementById("myVid2")
      .addEventListener("mouseover", function () {
        this.play();
      });
    document
      .getElementById("myVid2")
      .addEventListener("mouseleave", function () {
        // this.pause();
        this.load();
      });
    document
      .getElementById("myVid3")
      .addEventListener("mouseover", function () {
        this.play();
      });
    document
      .getElementById("myVid3")
      .addEventListener("mouseleave", function () {
        // this.pause();
        this.load();
      });
    document
      .getElementById("myVid4")
      .addEventListener("mouseover", function () {
        this.play();
      });
    document
      .getElementById("myVid4")
      .addEventListener("mouseleave", function () {
        // this.pause();
        this.load();
      });
  }, []); // Run this effect once when the component mounts

  // const handleInputChange = (event) => {
  //   setSearchValue(event.target.value);
  // };

  return (
    <div>
      <Helmet>
      <title>HBL People | Careers</title>
      <meta name="description" content="HBL&#039;s core values define how we treat our clients, frame how we work with our colleagues, and are a key part of our ongoing success." />
      </Helmet>
      {/* Desktop Slider */}
      {/*  Desktop Slider */}
      <section className="swiper-container homebanner d-none d-lg-block">
        <div className="swiper-wrapper">
          <section className="swiper-slide one">
            <video
              loop
              autoPlay
              muted
              playsInline
              src="vid/video1.mp4"
              className="cvid desktop-video"
              id="banvid"
            ></video>
            <div className="in">
              <div className="container">
                <div className="textOverlay desktop-text-overlay"></div>
              </div>
            </div>
          </section>
          <section className="swiper-slide two">
            <div className="in">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-xl-6"></div>
                  <div className="col-lg-8 col-xl-6">
                    <h3>EXPERIENCED PROFESSIONALS</h3>
                    <div className="text-container">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <h2>
                            ENRICH YOUR PROFESSIONAL JOURNEY WITH HBL, AN
                            EXCEPTIONAL EXPERIENCE
                          </h2>
                          <a href="/experienced-professionals" className="link">
                            <img src="images/arrow.png" alt="" /> Explore
                            opportunities for experienced professionals
                          </a>
                        </div>
                        <div className="swiper-slide">
                          <h4>
                            At HBL, our mission is for our staff to excel in
                            their career. We focus on providing a progressive
                            and an enabling work environment to support our
                            staff in reaching to their best potential.
                          </h4>
                          <a href="/experienced-professionals" className="link">
                            <img src="images/arrow.png" alt="" /> Explore
                            opportunities for experienced professionals
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="swiper-slide two three">
            <div className="in">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-xl-6"></div>
                  <div className="col-lg-8 col-xl-6">
                    <h3>The League</h3>
                    <div className="text-container">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <h2>
                            KICK-START YOUR CAREER WITH HBL THROUGH OUR FLAGSHIP
                            PROGRAM.
                          </h2>
                          <p>
                            Applications are invited in January-March Every year
                          </p>
                          <a
                            href="/the-league"
                            className="link"
                          >
                            <img src="images/arrow.png" alt="" /> Explore more
                            about The League Programme
                          </a>
                        </div>
                        <div className="swiper-slide">
                          <h4>
                            With an unyielding commitment towards developing
                            outstanding leaders, HBL is the place to begin your
                            career. HBL offers a set of experiences beyond the
                            banking norm.
                          </h4>
                          <a
                            href="/the-league"
                            className="link"
                          >
                            <img src="images/arrow.png" alt="" /> Explore more
                            about The League Programme
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="swiper-slide two four">
            <div className="in">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-xl-6"></div>
                  <div className="col-lg-8 col-xl-6">
                    <h3>Fresh Graduate</h3>
                    <div className="text-container">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <h2>Look forward to a Great future…</h2>
                          <a href="/fresh-graduates" className="link">
                            <img src="images/arrow.png" alt="" /> Explore
                            opportunities for Fresh Graduates
                          </a>
                        </div>
                        <div className="swiper-slide">
                          <h4>
                            If you are a recent graduate seeking an environment
                            which harbors growth and learning, then HBL’s
                            graduate programs are the right match that offer you
                            the gateway to achieve your dreams.
                          </h4>
                          <a href="/fresh-graduates" className="link">
                            <img src="images/arrow.png" alt="" /> Explore
                            opportunities for Fresh Graduates
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="abbannercontent">
          <h5>Define Your Career Path!</h5>
          <div className="btncgroup">
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>
      {/*  Desktop Slider */}
      {/*  Mob Tab Slider */}
      <section className="mobbanner homebanner d-block d-lg-none">
        <div className="abbannercontent">
          {/*  <h5>Define Your Career Path!</h5> */}
          <div className="btncgroup">
            <div className="swiper-pagination"></div>
          </div>
        </div>
        <div className="swiper-wrapper">
          <section className="swiper-slide one">
            <video
              src="vid/video1.mp4"
              className="cvid mobile-video"
              id=""
              loop
              autoPlay
              muted
              playsInline
            ></video>
            <div className="in">
              <div className="container">
                {/*           <h1>Let’s Create the NEW</h1>*/}
                <div className="textOverlay mobile-text-overlay"></div>
              </div>
            </div>
          </section>
          <section className="swiper-slide two">
            <div className="in">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-xl-6"></div>
                  <div className="col-lg-8 col-xl-6">
                    <h3>EXPERIENCED PROFESSIONALS</h3>
                    <h2>
                      ENRICH YOUR PROFESSIONAL JOURNEY WITH HBL, AN EXCEPTIONAL
                      EXPERIENCE
                    </h2>
                    <a href="/experienced-professionals" className="link">
                      <img src="images/arrow.png" alt="" /> Explore
                      opportunities for experienced professionals
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="swiper-slide two three">
            <div className="in">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-xl-6"></div>
                  <div className="col-lg-8 col-xl-6">
                    <h3>The League</h3>
                    <h2>
                      KICK-START YOUR CAREER WITH HBL THROUGH OUR FLAGSHIP
                      PROGRAM.
                    </h2>
                    <p>Applications are invited in January-March Every year </p>
                    <a href="/the-league" className="link">
                      <img src="images/arrow.png" alt="" /> Explore more about
                      The League Programme
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="swiper-slide two four">
            <div className="in">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-xl-6"></div>
                  <div className="col-lg-8 col-xl-6">
                    <h3>Fresh Graduate</h3>
                    <h2>Look forward to a Great future…</h2>
                    <a href="/fresh-graduates" className="link">
                      <img src="images/arrow.png" alt="" /> Explore
                      opportunities for Fresh Graduates
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/*  Mob Tab Slider */}
      <section className="home-one">
        <div className="container">
          <div className="text-center">
            <h3 className="web">WHY HBL</h3>
            <h2 className="web"> Here You Stand Out </h2>
          </div>
          <div className="swiper whyhbl">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <a href="/experienced-professionals">
                  <div
                    className="in"
                    data-sr="enter top over 0.3s and move 65px wait 0.0.3s"
                  >
                    <div className="inimage">
                      <img src="images/icon1.png" alt="" className="a" />
                      <img src="images/icon1h.png" alt="" className="b" />
                    </div>
                    <h5>
                      Sustainable <br />
                      Growth
                    </h5>
                    <p> Redefining Future </p>
                  </div>
                </a>
              </div>
              <div className="swiper-slide">
                <a href="learning">
                  <div
                    className="in"
                    data-sr="enter top over 0.3s and move 65px wait 0.2s"
                  >
                    <div className="inimage">
                      <img src="images/icon2.png" alt="" className="a" />
                      <img src="images/icon2h.png" alt="" className="b" />
                    </div>
                    <h5>
                      Learning & <br />
                      Development
                    </h5>
                    <p> Enabling Talent Development </p>
                  </div>
                </a>
              </div>
              <div className="swiper-slide">
                <a href="impact">
                  <div
                    className="in"
                    data-sr="enter top over 0.3s and move 65px wait 0.3s"
                  >
                    <div className="inimage">
                      <img src="images/icon3.png" alt="" className="a" />
                      <img src="images/icon3h.png" alt="" className="b" />
                    </div>
                    <h5>
                      Technological <br />
                      Transformation
                    </h5>
                    <p> Initiate, Innovate, & Accelerate to make an impact </p>
                  </div>
                </a>
              </div>
              <div className="swiper-slide">
                <a href="diversity">
                  <div
                    className="in"
                    data-sr="enter top over 0.3s and move 65px wait 0.4s"
                  >
                    <div className="inimage">
                      <img src="images/icon4.png" alt="" className="a" />
                      <img src="images/icon4h.png" alt="" className="b" />
                    </div>
                    <h5>
                      Being Socially <br />
                      Equitable
                    </h5>
                    <p>
                      Celebrating differences and building diverse Ecosystem
                    </p>
                  </div>
                </a>
              </div>
              <div className="swiper-slide">
                <a href="fresh-graduates">
                  <div
                    className="in"
                    data-sr="enter top over 0.3s and move 65px wait 0.5s"
                  >
                    <div className="inimage">
                      <img src="images/icon5.png" alt="" className="a" />
                      <img src="images/icon5h.png" alt="" className="b" />
                    </div>
                    <h5>
                      Guidance & <br />
                      Mentorship
                    </h5>
                    <p>Providing platform and tools to enrich your career</p>
                  </div>
                </a>
              </div>
              <div className="swiper-slide">
                <a href="footprint">
                  <div
                    className="in"
                    data-sr="enter top over 0.3s and move 65px wait 0.6s"
                  >
                    <div className="inimage">
                      <img src="images/icon6.png" alt="" className="a" />
                      <img src="images/icon6h.png" alt="" className="b" />
                    </div>
                    <h5>
                      Accredited <br />
                      Best Bank
                    </h5>
                    <p> Setting the new benchmarks </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-two">
        <div className="container">
          <div className="text-center">
            <h3 className="web">LIFE AT HBL</h3>
            <h2 className="web"> Raise the Bar </h2>
          </div>
          <div className="swiper lifehbl">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div
                  className="in"
                  data-sr="enter top over 0.3s and move 65px wait 0.2s"
                >
                  <video
                    src="vid/vid1.mp4"
                    poster="images/image1.jpg"
                    id="myVid"
                    height="320"
                    width="320"
                    muted
                    playsInline
                  ></video>
                  <h5>Building a More Diverse Culture</h5>
                  <a href="diversity" className="link">
                    <img src="images/arrowgreen.png" alt="" /> Learn more about
                    Diversity & Inclusion
                  </a>
                </div>
              </div>
              <div className="swiper-slide">
                <div
                  className="in"
                  data-sr="enter top over 0.3s and move 65px wait 0.4s"
                >
                  <video
                    src="vid/vid2.mp4"
                    poster="images/image2.jpg"
                    id="myVid2"
                    muted
                    playsInline
                  ></video>
                  <h5>Flexible Work Arrangements</h5>
                  <a href="benefits" className="link">
                    <img src="images/arrowgreen.png" alt="" /> Explore more
                    benefits working at HBL
                  </a>
                </div>
              </div>
              <div className="swiper-slide">
                <div
                  className="in"
                  data-sr="enter top over 0.3s and move 65px wait 0.6s"
                >
                  <video
                    src="vid/vid3.mp4"
                    poster="images/image3.jpg"
                    id="myVid3"
                    muted
                    playsInline
                  ></video>
                  <h5>Cherishing Contributions</h5>
                  <a href="culture" className="link">
                    <img src="images/arrowgreen.png" alt="" /> See our past
                    events and highlights
                  </a>
                </div>
              </div>
              <div className="swiper-slide">
                <div
                  className="in"
                  data-sr="enter top over 0.3s and move 65px wait 0.8s"
                >
                  <video
                    src="vid/vid4.mp4"
                    poster="images/image4.jpg"
                    id="myVid4"
                    muted
                    playsInline
                  ></video>
                  <h5>It's Not Always Work</h5>
                  <a href="benefits" className="link">
                    <img src="images/arrowgreen.png" alt="" /> See what's it
                    like to work at HBL
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonails />
      <section
        className="home-four"
        data-sr="enter top over 0.3s and move 65px"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8">
              <div className="text-center">
                <h3 className="web">REWARDING PEOPLE</h3>
                <h2 className="web">
                  
                  To Do What You Love While Creating the Future You Want
                </h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center my-5">
            <div
              className="col-xxl-6 col-xl-5"
              data-sr="enter top over 0.3s and move 65px"
            >
              <p>
                
                We provide our people with a variety of tools enriching
                professional experiences, continued guidance and support,
                productive feedback to realize their true potential.
              </p>
              <a href="benefits" className="link">
                
                <img src="images/arrowgreen.png" alt="" /> Learn more about
                benefits of working at HBL
              </a>
            </div>
            <div className="col-xxl-6 col-xl-7">
              <div className="row gx-0 cmob">
                <div className="col-6 col-md-3">
                  <div
                    className="in"
                    data-sr="enter top over 0.3s and move 65px wait 0.2s"
                  >
                    <div>
                      <img
                        src="images/image7.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div
                    className="in"
                    data-sr="enter top over 0.4s and move 65px wait 0.8s"
                  >
                    <div>
                      Scholarships & <br />
                      Development <br />
                      Opportunities
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div
                    className="in black"
                    data-sr="enter top over 0.3s and move 65px wait 0.6s"
                  >
                    <div>
                      Coaching & <br />
                      Career <br />
                      Progression
                    </div>
                  </div>
                  <div
                    className="in"
                    data-sr="enter top over 0.4s and move 65px wait 0.4s"
                  >
                    <div>
                      <img
                        src="images/image8.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div
                    className="in"
                    data-sr="enter top over 0.3s and move 65px wait 0.2s"
                  >
                    <div>
                      <img
                        src="images/image9.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div
                    className="in"
                    data-sr="enter top over 0.4s and move 65px wait 0.8s"
                  >
                    <div>
                      Insurance & <br />
                      Paid Leaves
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <div
                    className="in black"
                    data-sr="enter top over 0.3s and move 65px wait 0.6s"
                  >
                    
                    <div>
                      Health & <br />
                      Wellbeing <br />
                      Tools
                    </div>
                  </div>
                  <div
                    className="in"
                    data-sr="enter top over 0.4s and move 65px wait 0.4s"
                  >
                    <div>
                      <img
                        src="images/image10.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Functionfilter />
    </div>
  );
};

export default Home;
