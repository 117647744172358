import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functioninnovationfinancialinclusion = () => {
  return (
    <>
<Helmet>
<title>Innovation and Financial Inclusion | HBL People</title>
<meta
name="description"
content="Digitization, Automation, Customer Empowerment and Inclusion Scale"
/>
</Helmet>
<section className="innerpagebanner innovation-financial-inclusion">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Innovation and Financial Inclusion</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Digitization, Automation, Customer Empowerment and Inclusion Scale</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages innovation-financial-inclusion">
  <div className="container d-flex flex-wrap gap-3">
<p>The Innovation and Financial Inclusion Group focusses primarily on Digital Transformation, Robotics & delivering Inclusion @ scale. The main Goal is to enable the Bank to become data and insights driven, customer centric, focused on creating partnerships by leveraging technology to deliver value at scale and to build new business models as the opportunity arises.</p> 

<p>Digital robotics and automation enhance the efficiency and scalability of our front and backend processes, improving risk management and audit capabilities.</p>

<p>The Innovation and Financial Inclusion Group is organized into three distinct departments.</p>  

	<div>
<h4>Branchless Banking - Konnect by HBL</h4>
<p>This department develops and implements branchless banking solutions, enabling customers to access financial services anywhere, anytime, regardless of location or demographics. This department also runs eco system partnerships, Engagements with FinTech, Freelancers, Digital Lending and Marketplace strategies for the Bank.</p>
	</div>	<div>
<h4>Data Strategy & Robotics</h4>
<p>This department is responsible to enable transformation of Bank into a data and insights driven entity by enabling single source pf data, empowering users to do analytics on their own and providing AI & ML capabilities for the organization. They also run the Data Lake at Bank. The Robotics team is working to deliver Robotics Process automation @ scale to enable straight through processing, significantly enhanced customer experience, better controls & standardization and consistency of processes and experiences.</p>
	</div><div>
<h4>Enterprise Solution and Digital Channels </h4>
<p>This department focuses on developing digital channels that allow customers to access financial services through their mobile devices, providing a seamless, user-friendly experience to manage their finances conveniently. This department consists of Software developers and engineers that lead the effort. Some of the products / services built and managed by this unit include HBL Mobile app enabling more than 3 million Bank customers, HBL Billing aggregator, Konnect Branchless Banking Platform, WhatsApp banking & E commerce payment Gateway empowering a few hundred e commerce players.</p>
	</div>



  </div>
</section>
<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functioninnovationfinancialinclusion
