import React,{useEffect} from 'react'
import {Helmet} from "react-helmet";

const Graduates = () => {

    useEffect(() => {
        let learning = new window.Swiper(".singleSwiper", {
            slidesPerView: 1,
            spaceBetween: 1,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
          });

}, []);
  return (
    <>
    <Helmet>
    <title>Fresh Graduates | HBL People</title>
<meta
name="description"
content="Look forward to a Great future"
/>
    <script>
    window.sr = new scrollReveal();
    </script>
    </Helmet>
     <section className="innerpagebanner graduates">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">FRESH GRADUATES</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Look forward to a Great future</h2>
    </div>
  </div>
</section>
<section className="innersection graduates">
  <div className="container">
    <div className="text-center">
      <h3 className="web" data-sr="enter top over 0.3s and move 65px wait 0.3s">gateway to achieve your dreams</h3>
      <h2 className="web" data-sr="enter top over 0.3s and move 65px wait 0.6s">Jumpstart your Career</h2>
      <p className="mb-3" data-sr="enter top over 0.3s and move 65px wait 0.9s">If you are a recent graduate seeking an environment of growth and learning to propel you in the next phase of your career, HBL's graduate programs offer you the gateway to achieve your dreams.</p>
      <p data-sr="enter top over 0.3s and move 65px wait 1.2s">If you are looking for your first full-time role after graduation or an ACCA/CA Trainee, we understand that the transition from academic to corporate life can seem daunting. At HBL, we offer competitive remuneration and help you develop the skill set you need to get ahead in your career with dedicated learning and mentoring opportunities.</p>
    </div>
  </div>
</section>
<section className="innersection graduates secimgin">
  <div className="container">
    <div className="text-center mb-5">
      <h3 className="web" data-sr="enter top over 0.3s and move 65px wait 0.3s">Graduate Opportunities</h3>
      <h2 className="web" data-sr="enter top over 0.3s and move 65px wait 0.6s">TRANSITION INTO YOUR PROFESSIONAL LIFE</h2>
    </div>
    <div className="row justify-content-center">
      <div className="col-lg-4">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 0.3s"> <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">
          <div className="imgin"> <img src="images/graduates/cash-officer.jpg" alt="hblpeople"/> </div>
          <h4 className="mt-2 mb-1">Cash Officer</h4>
          <p>Our cash officers are one of the most critical resources in the Bank. If you enjoy challenges and want to work in a role that will help you develop a multi-facted skill set and a sustainable banking career; cash officer is  a great place to start at.</p>
          <h5 className="mt-3 mb-1">Eligibility Criteria</h5>
          <ul className="ps-4">
            <li>Qualification: Bachelor’s degree</li>
            <li>Minimum experience: Fresh graduate</li>
          </ul>
          </a> <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener" className="link"> <img src="images/arrowgreen.png" alt="hblpeople"/> Apply for Cash Officer Position</a> </div>
      </div>
      <div className="col-lg-4">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 0.6s"> <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">
          <div className="imgin"> <img src="images/graduates/associate.jpg" alt="hblpeople"/></div>
          <h4 className="mt-2 mb-1">Associate Relationship Manager</h4>
          <p>If you are a go-getter, our Associate Relationship Manager role will give you the opportunity to shine and build on your achievements. In this role, you will be expected to achieve assigned business targets and increase branch business portfolio.</p>
          <h5 className="mt-3 mb-1">Eligibility Criteria</h5>
          <ul className="ps-4">
            <li>Qualification: Bachelor’s degree</li>
            <li>Minimum experience: 0-3 years.</li>
          </ul>
          </a> <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener" className="link"> <img src="images/arrowgreen.png" alt="hblpeople"/> Apply for Associate Relationship Manager</a> </div>
      </div>
      <div className="col-lg-4">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 0.9s"> <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">
          <div className="imgin"> <img src="images/graduates/agri-finance.jpg" alt="hblpeople"/></div>
          <h4 className="mt-2 mb-1">Agri Finance Officer</h4>
          <p>Our Agri Finance Officer role will give you the opportunity to shine within the domain of Rural Banking and Agri Business . You will be working closely with Branch Managers on various business acquisition and recovery related transactions.</p>
          <h5 className="mt-3 mb-1">Eligibility Criteria</h5>
          <ul className="ps-4">
            <li>Qualification: Bachelor’s degree</li>
            <li>Minimum experience: Fresh graduate</li>
          </ul>
          </a> <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener" className="link"> <img src="images/arrowgreen.png" alt="hblpeople"/> Apply for Agri Finance Officer</a> </div>
      </div>
    </div>
  </div>
</section>
<section className="innersection graduates">
  <div className="container">
    <div className="text-center mb-5">
      <h3 className="web" data-sr="enter top over 0.3s and move 65px wait 0.3s">Trainee Programmes</h3>
      <h2 className="web" data-sr="enter top over 0.3s and move 65px wait 0.6s">EXPLORE YOUR PATH TO BANKING</h2>
      <p data-sr="enter top over 0.3s and move 65px wait 0.9s">At HBL we offer numerous opportunities to fresh graduates by offering different trainee programs which will help them enhance their skills and may lead to a promising career path in banking industry. You have an excellent opportunity to climb up the corporate ladder by opting to be part of one of the life changing experiences which you can keep yourself abreast with by following our career page. Many Technology and non-Tech based Trainee programs awaits You!</p>
    </div>
    <div className="row justify-content-center">
      <div className="col-lg-10">
        <div className="row">
          <div className="col-lg-6"> <img src="images/graduates/acca.jpg" alt="" data-sr="enter top over 0.3s and move 65px wait 0.3s"/> </div>
          <div className="col-lg-6">
            <div className="swiper singleSwiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="in inn" data-sr="enter right over 0.3s and move 65px wait 0.6s">
                    <h4 className="mt-3 mb-2">ACCA Trainee Program</h4>
                    <p>A programme designed to thoroughly develop ACCA students through practical experience and on-the-job training related to their field of study. The trainees initially go through rigorous classroom trainings at our Learning &amp; Development facility where they are equipped with essential technical and soft skills. They are rotated through key areas of bank to get an overview of banking functions. </p>
                    <h5 className="mt-4 mb-1">Eligibility Criteria</h5>
                    <ul className="mb-2 liststylenone">
                      <li>Age: 19 to 25 years at the time of joining HBL as a trainee.</li>
                      <li>Qualification: Minimum 7 papers of ACCA should be cleared</li>
                    </ul>
                    <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" className="link" target="_blank" rel="noreferrer noopener"> <img src="images/arrowgreen.png" alt="hblpeople"/> Apply for ACCA Trainee Program</a> </div>
                </div>
                <div className="swiper-slide">
                  <div className="in inn" data-sr="enter right over 0.3s and move 65px wait 0.6s">
                    <h4 className="mt-3 mb-2">Audit Trainee Program</h4>
                    <p>The Audit Trainee program provides an opportunity to our aspiring talent a pathway to learn and perform different skills and techniques under the guidance of experienced auditors. A dedicated training programme to learn technical audit skills and develop the soft skills required to make you a successful auditor. </p>
                    <h5 className="mt-4 mb-1">Eligibility Criteria</h5>
                    <ul className="mb-2 liststylenone">
                      <li>Age: 19 to 25 years at the time of joining HBL as a trainee.</li>
                      <li>Qualification: Minimum 7 papers of ACCA should be cleared</li>
                    </ul>
                    <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" className="link" target="_blank" rel="noreferrer noopener"> <img src="images/arrowgreen.png" alt="hblpeople"/> Apply for Audit Trainee Program</a> </div>
                </div>
                <div className="swiper-slide">
                  <div className="in inn" data-sr="enter right over 0.3s and move 65px wait 0.6s">
                    <h4 className="mt-3 mb-2">Compliance Trainee Program</h4>
                    <p>The Compliance Trainee Program aims at offering a traineeship opportunity to selected candidates to experience and explore the growth and career prospects within Global Compliance Function in the keys areas of Financial Crime Compliance, Governance , Advisory, Regulatory affairs and other related units.</p>
                    <h5 className="mt-4 mb-1">Eligibility Criteria</h5>
                    <ul className="mb-2 liststylenone">
                      <li>Age: 19 to 27 years at the time of joining HBL as a trainee.</li>
                      <li>Qualification: Minimum Bachelors in Banking & Finance, Computer Science, Data Science, Software Engineering, Mathematics.</li>
                    </ul>
                    <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" className="link" target="_blank" rel="noreferrer noopener"> <img src="images/arrowgreen.png" alt="hblpeople"/> Apply for Compliance Trainee Program</a> </div>
                </div>
                <div className="swiper-slide">
                  <div className="in inn" data-sr="enter right over 0.3s and move 65px wait 0.6s">
                    <h4 className="mt-3 mb-2">Tech Trainee Program</h4>
                    <p>A programme for dynamic, disruptive and tech-savvy talent as Technical Trainee in our tech-based functions including Information Technology, Information Security and Innovation and Financial Inclusion Functions. This is an exciting opportunity for graduates to explore the financial services sector from a technology standpoint.</p>
                    <h5 className="mt-4 mb-1">Eligibility Criteria</h5>
                    <ul className="mb-2 liststylenone">
                      <li>Age: 19 to 27 years at the time of joining HBL as a trainee.</li>
                      <li>Qualification: Bachelors in Computer Science, Computer Systems Engineering or related discipline</li>
                    </ul>
                    <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" className="link" target="_blank" rel="noreferrer noopener"> <img src="images/arrowgreen.png" alt="hblpeople"/> Apply for Tech Trainee Program</a> </div>
                </div>
              </div>
              <div className="swiper-button-next"></div>
              <div className="swiper-button-prev"></div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="innersection graduates what-do-section">
  <div className="container">
    <div className="row">
      <div className="col-lg-6">
        <div className="in" data-sr="enter left over 0.3s and move 65px wait 0.3s"> <img src="images/graduates/icon-what-do.png" alt="hblpeople"/>
          <h4>What Do These Programmes Offer</h4>
          <ul>
            <li>Work at the largest private bank in Pakistan</li>
            <li>Continued on the job training as well as blended learning through e-learning &amp; class room trainings</li>
            <li>Supportive, collaborative and progressive work environment</li>
            <li>Competitive compensation and rewards</li>
            <li>Develop a specialized skill set</li>
            <li>Increased exposure to opportunities</li>
			  <li>Mentoring and support from senior professionals</li>
          </ul>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="in" data-sr="enter right over 0.3s and move 65px wait 0.3s"> <img src="images/graduates/icon-application.png" alt="hblpeople"/>
          <h4>Application Process</h4>
          <ul>
            <li><strong>Online Application</strong> - Submit an official application and wait to hear from us.</li>
            <li><strong>Shortlisting & Gamified Assessment</strong> - Show us that you have the required skill set to be a part of our sought after Programme.</li>
            <li><strong>Panel Interview</strong> - We want to know you better. Tell us about your accomplishments and aspirations.</li>
            <li><strong>Offer Letter</strong> - Congratulations! You have earned a place in our progamme.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="innersection graduates futureleader">
  <div className="container">
    <div className="text-center mb-5">
      <h3 className="web" data-sr="enter top over 0.3s and move 65px wait 0.3s">What do we look for?</h3>
      <h2 className="web" data-sr="enter top over 0.3s and move 65px wait 0.6s">THE QUALITIES OF A FUTURE LEADER</h2>
    </div>
    <div className="row row-cols-2 row-cols-md-3 row-cols-lg-6 text-center">
      <div className="col">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 0.3s">
          <div className="inimage"> <img src="images/graduates/icon-communication.png" alt="" className="a"/> <img src="images/graduates/icon-communication-white.png" alt="" className="b"/> </div>
          <h4>Communication </h4>
          <p>Listens, contributes, reflects, adds value</p>
        </div>
      </div>
      <div className="col">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 0.6s">
          <div className="inimage"> <img src="images/graduates/icon-agility.png" alt="" className="a"/> <img src="images/graduates/icon-agility-white.png" alt="" className="b"/> </div>
          <h4>Agility </h4>
          <p>Adapts, learns and grows</p>
        </div>
      </div>
      <div className="col">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 0.9s">
          <div className="inimage"> <img src="images/graduates/initative.png" alt="" className="a"/> <img src="images/graduates/initative-white.png" alt="" className="b"/> </div>
          <h4>Initative </h4>
          <p>Puts ideas into action, takes the initiative and drives for results</p>
        </div>
      </div>
      <div className="col">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 1.2s">
          <div className="inimage"> <img src="images/graduates/icon-team.png" alt="" className="a"/> <img src="images/graduates/icon-team-white.png" alt="" className="b"/> </div>
          <h4>Team Player </h4>
          <p>Collaborates, contributes and encourages others</p>
        </div>
      </div>
      <div className="col">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 1.5s">
          <div className="inimage"> <img src="images/graduates/passion.png" alt="" className="a"/> <img src="images/graduates/passion-white.png" alt="" className="b"/> </div>
          <h4>Passion </h4>
          <p>Embraces new skills, seeks out new approaches</p>
        </div>
      </div>
      <div className="col">
        <div className="in" data-sr="enter top over 0.3s and move 65px wait 1.8s">
          <div className="inimage"> <img src="images/graduates/icon-adapt.png" alt="" className="a"/> <img src="images/graduates/icon-adapt-white.png" alt="" className="b"/> </div>
          <h4>Adapt </h4>
          <p>Who can adapt to the fast paced challenging environment</p>
        </div>
      </div>
    </div>
  </div>
</section> 
    </>
  )
}

export default Graduates
