import React from "react";
import { Helmet } from "react-helmet";
import Footertabagp from "../../components/footertabagp";

const Leagueagpyourcareerpath = () => {
  return (
    <>
      <Helmet>
        <title>Your Career Path | THE LEAGUE</title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
      </Helmet>
      <section className="dev-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 d-none d-lg-block">
              <a
                href="/the-league/accelerate-graduate-programme"
                className="rthome"
              >
                <img src="/images/theleague/innerpage-back-button-arrow.png" /> Accelerate Graduate Programme
              </a>
            </div>
            <div className="col-lg-4">
              <a href="/the-league">
                <img
                  src="/images/theleague/the-league-logo.png"
                  className="img-fluid d-block mx-auto"
                />
              </a>
            </div>
            <div className="col-lg-4 d-none d-lg-block text-end">
              <a href="/the-league/" className="rthome">
                Return to Home <img src="/images/theleague/innerpage-forward-button-arrow.png" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="im-banner text-center">
                <h2>Your Career Path</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>
                The League Accelerate Graduate Program is a fast track
                development framework for experienced Master’s graduates. It is
                designed to instill in the incumbent the knowledge, skills as
                well as the relevant experiences needed to take their career to
                the next level.
              </p>
              <p>
                There are three core aspects to your development here. Use the
                tabs to explore what each of them entails.
              </p>
            </div>
          </div>

          <div className="col mb-5">
            <div className="leagueacc accordion" id="yourroleacc">
              <div className="accordion-item">
                <h2 className="accordion-header" id="ha">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#ca"
                    aria-expanded="false"
                    aria-controls="ca"
                  >
                    Professional Development
                  </button>
                </h2>
                <div
                  id="ca"
                  className="accordion-collapse collapse"
                  aria-labelledby="ha"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      As a part of The Program, Accelerate Graduates will be
                      provided with broad exposure to the operations of the
                      bank, its products and services, and the local banking
                      industry.
                    </p>
                    <p>
                      Within the programme, Accelerate Graduates will be
                      supported by the bank in obtaining the relevant
                      certifications in Banking and Financial Services for
                      Accredited Banking Practitioners.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hb">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cb"
                    aria-expanded="false"
                    aria-controls="cb"
                  >
                    Customized Learning & Development Plan
                  </button>
                </h2>
                <div
                  id="cb"
                  className="accordion-collapse collapse"
                  aria-labelledby="hb"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      In order to bring you up to speed with HBL and the banking
                      industry, all Accelerate Graduates go through a two week
                      training period consisting of classroom/virtual trainings
                      and sessions with senior management. Once the training
                      period is over, Accelerate Graduates will be rotated in
                      relevant departments of the bank to gain in-depth business
                      / function knowledge before they are placed in their
                      department of hiring.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cc"
                    aria-expanded="false"
                    aria-controls="cc"
                  >
                    Mentorship Programme
                  </button>
                </h2>
                <div
                  id="cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="hc"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                      Accelerate Graduates will be assigned a Mentor from senior
                      management at HBL who works with them to create
                      self-awareness, help them take steps towards realizing
                      their true potential and fostering support. The aim of the
                      program is that the Mentor and Mentee form a bond which
                      enables the Graduates to function on a level above their
                      own expectations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex d-lg-none text-center my-3">
            <div className="col">
              <a
                href="/the-league/accelerate-graduate-programme"
                className="rthome"
              >
                <img src="/images/theleague/innerpage-back-button-arrow.png" />
                Accelerate Graduate Programme
              </a>
            </div>
            <div className="col ">
              <a href="/the-league/" className="rthome">
                Return to Home <img src="/images/theleague/innerpage-forward-button-arrow.png" />
              </a>
            </div>
          </div>
        </div>
        <Footertabagp />
      </section>
    </>
  );
};

export default Leagueagpyourcareerpath;
