import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Intheader from "../../../../components/Intheader";
import Intheadermob from "../../../../components/Intheadermob";

const Intpost2 = () => {
  const images = [
    "",
    // "/images/theleague/internship/posts/1/1.jpg",
    // "/images/theleague/internship/posts/1/2.jpg",
    // "/images/theleague/internship/posts/1/3.jpg",
    // "/images/theleague/internship/posts/1/4.jpg",
    // "/images/theleague/internship/posts/1/5.jpg",
    // "/images/theleague/internship/posts/1/6.jpg",
  ];
  return (
    <>
      <Helmet>
        <title>
        Scavenger Hunt for Summer Interns 2017 | THE LEAGUE
        </title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
        <Intheader />
          <h1 className="my-3">Scavenger Hunt for Summer Interns 2017</h1>
          <Lightbox images={images} />
          <Intheadermob />
        </div>
      </section>
    </>
  );
};

export default Intpost2;
