import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functionhumanresources = () => {
  return (
    <>
<Helmet>
<title>Human Resources | HBL People</title>
<meta
name="description"
content="Our People, Our Future!"
/>
</Helmet>
<section className="innerpagebanner human-resources">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Human Resources</h1>
      <h2 style={{maxWidth: '390px'}} data-sr="enter top over 0.3s and move 65px wait 0.6s">Our People, Our Future!</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages human-resources">
  <div className="container d-flex flex-wrap gap-3">
    <p>HR at HBL operates as a strategic partner to our businesses and focuses on driving performance excellence by hiring, developing, and retaining the best talent. With a long-term focus on aligning our people strategy with the overall organizational strategy, we strive to build a flexible, engaging and progressive culture. Structured into nine key roles, HR at HBL contributes through the following units:</p>
<div>
<h4>Talent Acquisition</h4>
<p>Sources, screens and recruits the best talent in the market, while ensuring adherence to inclusive hiring policies.</p>
</div><div>
<h4>Relationship and Talent Management</h4>
<p>Prioritizes employee engagement and development in partnership with business units and line managers to drive business results, promote career growth and maximize employees’ potential.</p>
</div><div>
<h4>Learning and Development:</h4>
<p>Designs and develops need-based interventions to enhance capabilities and skillsets to cater to the dynamic business environment.</p>
</div><div>
<h4>HR Operations</h4>
<p>Ensures seamless execution of daily HR transactions essential to maintaining a workforce.</p>
</div><div>
<h4>Rewards Policies and Financials</h4>
<p>Develops and implements policies and reward structures to motivate and engage employees to consistently drive performance and achieve organization’s goals alongside managing financials and analytics to support and drive decision making at the strategic level.</p>
</div><div>
<h4>Organizational Development Talent and Agility</h4>
<p>Responsible for driving the values, culture and engagement ensuring we have the right talent and introduce agile working methodologies within HBL.</p>
</div><div>
<h4>HR Technology and Data Analytics</h4>
<p>Focuses on digitization of HR processes through design and implementation of tech-related projects and solutions to support evidence-based decision making.</p>
</div><div>
<h4>Employee Relations</h4>
<p>Acts as an arbitrator and oversees the resolution of grievances, misconduct and/or disciplinary violations by employees in a fair and impartial manner.</p>
</div><div>
<h4>International HR</h4>
<p>International HR facilitates the implementation of HBL’s people strategy in its’ international locations through a support model that understands, anticipates and works with the functional lines of business. The underlying premise is to deliver the people agenda that is aligned to the business objectives of each location, while adhering to local laws and market practices.</p>
</div>

  </div>
</section>

<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functionhumanresources
