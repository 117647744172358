import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functioninternalaudit = () => {
  return (
    <>
<Helmet>
<title>Internal Audit | HBL People</title>
<meta
name="description"
content="Best in class Internal Audit Function"
/>
</Helmet>
<section className="innerpagebanner internal-audit">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Internal Audit</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Best in class Internal Audit Function</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages internal-audit">
  <div className="container d-flex flex-wrap gap-3">
    <p>HBL Internal Audit Function through a risk-based approach with an industry leading team of professionally qualified and certified individuals, provides reasonable and objective assurance on the adequacy of design and effectiveness of the systems of internal control in mitigating significant risks and in enhancing the effectiveness of risk management, governance processes and systems in a holistic manner. The end-to-end coverage in accordance with Professional Standards of Institute of Internal Audit (IIA) of areas include head office, branches, international locations, credit risk cycle, technology risks, Islamic banking areas and Bank wide investigations and thematic audits duly supported by dedicated audit digitalization team and monitored by an internal quality assurance mechanism. The staff in Internal Audit gets cross functional exposure of auditing the areas across the Bank.</p>
    <p>HBL internal audit is well positioned to deliver consistent best in class assurance and advisory services to its stakeholders based on the following five pillars:</p>
    <div>
      <h4>Continuous Risk Assessment</h4>
      <p>To continuously benchmark ourselves against the dynamic regulatory requirements stakeholder expectations and industry best practices for provision of value-added assurance and advisory services.</p>
    </div>
    <div>
      <h4>Technology</h4>
      <p>Subject matter IT and digital expertise for robust coverage of core technology and digital areas.</p>
    </div>
    <div>
      <h4>AGILITY</h4>
      <p>Flexible, forward looking and continuously evolving in the dynamic landscape.</p>
    </div>
    <div>
      <h4>Stakeholder Management</h4>
      <p>Continuous engagement with internal and external stakeholders for better insight into emerging risks of the Bank and industry.</p>
    </div>
    <div>
      <h4>People & Culture</h4>
      <p>Based on the principles of diversity and open communication we facilitate ongoing development, progression, fair treatment and performance reward.</p>
    </div>
	  <p style={{color: "#333",fontSize: "24px",fontWeight: "400"}}><em>“HBL's Internal Audit function is truly best-in-class. Risk-based assessment and use of cutting-edge technology, including data analytics and automation helps us to provide insights and practical recommendations to our stakeholders for strengthening their governance and risk management processes. Our aim is to demonstrate excellence in every engagement."</em><span className="d-block" style={{color:"#197056 "}}><em>Wafee Pesnani</em></span></p>
  </div>
</section>
<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functioninternalaudit