import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functionfinance = () => {
  return (
    <>
    <Helmet>
<title>Finance | HBL People</title>
<meta
name="description"
content="Supporting the business in achieving its full potential"
/>
</Helmet>
<section className="innerpagebanner finance">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Finance</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Supporting the business in achieving its full potential </h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages finance">
  <div className="container d-flex flex-wrap gap-3">
    <p data-sr="enter top over 0.3s and move 65px wait 0.3s">The Finance department works in collaboration with all the other functions of the Bank to track, analyze and report the financial information that measures the Bank’s performance to guide the Bank in achieving its strategic objectives. The department also manages the Bank’s tax reporting, compliance and payment obligations and provides advisory to departments on tax matters.</p>
    <p data-sr="enter top over 0.3s and move 65px wait 0.6s">The team strives to maintain the integrity of controls around the financial Reporting process to ensure the accuracy of the financial reporting carried out by them in accordance with the requirements set out under the various laws and regulations applicable on Banks in Pakistan, and other jurisdictions where the Bank operates. The Finance department is the primary interface to the investor community and presents HBL and its performance through regular interactions. </p>
  </div>
</section>

<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functionfinance