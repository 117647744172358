import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Intheader from "../../../../components/Intheader";
import Intheadermob from "../../../../components/Intheadermob";

const Intpost4 = () => {
  const images = [
    "/images/theleague/internship/posts/4/1.jpg",
    "/images/theleague/internship/posts/4/2.jpg",
  ];
  return (
    <>
      <Helmet>
        <title>
        Summer Internship 2017 Orientation Ceremony | THE LEAGUE
        </title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
        <Intheader />
          <h1 className="my-3">Summer Internship 2017 Orientation Ceremony</h1>
          <Lightbox images={images} />
          <Intheadermob />
        </div>
      </section>
    </>
  );
};

export default Intpost4;
