import React from 'react';
//import './JDialog.css'; // Make sure to include appropriate CSS

const JDialog = ({ id, isOpen, onClose , skinClassName = 'demo', animationType = 'flip', children }) => {
  const handleClose = () => {
    if (onClose) {
      onClose(id);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <>
          <div className={`jDialog ${animationType} ${isOpen ? 'show' : ''}`} id={`${id}`}>
          <div className={`jDialog-content ${skinClassName} `}>
          <div className="content">
          <a className="pop-cut" data-dismiss="JDialog" onClick={handleClose}>
                    <img src="/images/theleague/mt/home-popup-close-button.jpg" />
                  </a>
                  
            {children}
          </div>
          </div>
          </div>
    </>
  );
};

export default JDialog;