import React from 'react'

const Intheader = () => {
  return (
    <>
    <div className="row align-items-center">
            <div className="col-lg-4 d-none d-lg-block">
              <a href="/the-league/internship" className="rthome">
                <img src="/images/theleague/innerpage-back-button-arrow.png" /> Internship
              </a>
            </div>
            <div className="col-lg-4">
              <a href="/the-league">
                <img
                  src="/images/theleague/the-league-logo.png"
                  className="img-fluid d-block mx-auto"
                />
              </a>
            </div>
            <div className="col-lg-4 d-none d-lg-block text-end">
              <a href="/the-league/" className="rthome">
                Return to Home <img src="/images/theleague/innerpage-forward-button-arrow.png" />
              </a>
            </div>
          </div>
    </>
  )
}

export default Intheader