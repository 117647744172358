import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functiontreasuryglobalmarkets = () => {
  return (
    <>
<Helmet>
<title>Treasury and Global Markets | HBL People</title>
<meta
name="description"
content="We manage one of the largest balance sheets in the banking industry"
/>
</Helmet>
<section className="innerpagebanner treasury-global-markets">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Treasury and Global Markets</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">We manage one of the largest balance sheets in the banking industry</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages treasury-global-markets">
  <div className="container d-flex flex-wrap gap-3 flex-column">
    <div>
      <h4>ASSET AND LIABILITY MANAGEMENT </h4>
      <p>The Bank’s liquidity is managed by the ALM Desk under guidelines and supervision of the ALCO (Asset & Liability Management Committee). The broad guidelines are to manage the book in line with international best practices and with regulatory standards, while simultaneously ensuring that profitability objectives are met.</p>
    </div>
    <p>The ALM’s functions includes ensuring day-to-day operational liquidity, as well as managing the balance sheet on a strategic basis. In terms of operational liquidity, the ALM Desk functions as the liquidity management center and ensures that all the Bank’s local and foreign currency obligations are met without fail.</p>
    <p>Strategic liquidity management refers to the management and structuring of the balance over the business cycle, with appropriate risk management tools to ensure that the balance sheet is robust enough to meet contingencies or market disruptions. This task includes the proactive management of Interest Rate Risk in the Banking Book (IRRBB), a key tenet of Basel standards, requiring actively accumulating, holding, and laying off interest rate risk in the banking book portfolio to ensure that the Bank is positioned for the expected future direction of interest rates.</p>
    <p>In addition, the Desk works with other businesses to ensure that in all the Bank’s products, Interest Rate and Liquidity Risks are adequately captured and priced accordingly. The Bank’s businesses require benchmark deposit and loan pricing on a daily basis to ensure that the liabilities and assets that are being booked are priced in line with the market and for this the ALM Desk provides reference pricing across the Bank daily for both local and foreign currency.</p>
    <p>As part of its activity, the ALM Desk manages a large asset book (the largest in the Bank) primarily covering Pakistani Rupee Government Fixed Income Securities (Treasury Bills, Pakistan Investment Bonds - Fixed & Floating Rate, and Government Ijara Sukuks). These are invested in for the purpose of meeting statutory liquidity guidelines as required by the central Bank (State Bank of Pakistan) as well as to deploy surplus liquidity generated by the Bank. As part of this activity, the Desk actively participates in the auctions of Government Securities. The activities of the Desk include making markets in the short-dated domestic fixed income and money markets.</p>
    <p>The Bank also maintains a Foreign Currency balance sheet, denominated primarily in US Dollars.</p>
    <h4>GLOBAL MARKETS TRADING</h4>
    <p><strong>FX TRADING</strong> <br/>
      FX Trading manages the overall exposure emanating from the Bank’s activity in the FX markets as well as formulating strategies for the FX swap book. The desk is mandated to provide access to Bank’s clientele to FX markets through the Treasury Sales. The desk also acts as a market maker for the USDPKR spot and forward curves. The desk proactively manages exposures in the FX ready/spot and swap/forward markets with a view to capitalize on market moves. </p>
    <p><strong>RATES TRADING</strong> <br/>
      Rates Trading is primarily mandated to trade in interest rate risk markets associated with fixed-income investments (made in government securities) classified as Held for Trading (HFT), and certain interest rate derivative products. By design, the desk has a Fixed-Income Trading arm and a Derivatives Trading arm. The fixed-Income Trading business primarily caters to trading in PKR-denominated fixed-income securities issued by the Government of Pakistan. The business actively manages the HFT book in securities, with a view to distribute them to corporate, retail and institutional clients. It also acts as the bank’s face to the domestic sovereign fixed-income market and as a market-maker of the same to the interbank clients. It provides the bank’s corporate clientele access to the domestic sovereign fixed-income assets through the Treasury Sales business and is also a joint custodian of the Primary Dealership (“PD”) license with ALM and Sales businesses. </p>
    <p>Derivatives Trading primarily caters to derivatives pricing and warehousing in Rates and FX, including the management of linear interest rate risk associated with Interest Rate Swaps (“IRS”) and Cross Currency Swaps (“CCS”) and non-linear risk associated with FX Options. Management of the market risk associated with Derivatives falls under its ambit. The business provides access to and pricing for structured products to end-users for their hedging needs by working closely with Structuring and Treasury Sales teams. Such products may include, but are not limited to, FX Options, Cross Currency Basis Swaps, Interest Rate Swaps and Forward Rate Agreements or as allowed under the Bank’s own policy and regulatory guidelines. It is also the joint custodian of the Authorized Derivatives Dealer (“ADD”) license with the Structured Products and Sales businesses.</p>
    <p><strong>E-TRADING SOLUTIONS</strong> <br/>
      The e-Trading Solutions desk is a focal element within Treasury & Global Markets, focused primarily on leveraging technology and bringing digital solutions for both internal and external stakeholders. This desk is responsible for executing the core strategies of HBL by digitizing processes and products offered by the treasury space for both domestic and international clients. The launch of HBL Infinity® was a pioneering development in the domestic financial market; adding to our digital portfolio we had successfully launched HBL Symphony(R) catering to our fixed income clients. Through new developments and consistent upgrades, the e-Trading Solutions desk intends to ensure that HBL paves and leads the digital space thereby embedding transparency and ease of doing business for our valued customers. </p>
    <p>Furthermore, we are developing further capabilities in the existing HBL Symphony platform to make the customer experience even better. We remain committed to our motto of through new developments and consistent upgrades, HBL paves and leads the digital space thereby embedding transparency and ease of doing business for our valued customers.</p>
    <div>
      <h4>TREASURY SALES & STRUCTURING</h4>
      <p>Sales and Structuring is the core client-facing business of Treasury & Global Markets. The Sales function provides Foreign Exchange, Investment and Derivative solutions to Corporate, Commercial, SME and Retail customers. Sales & Structuring is present in three geographical locations: Karachi, Lahore & Islamabad allowing the business to provide direct access and coverage to all our client segments across the country. Given the volatile nature of domestic markets, our highly trained and qualified Sales personnel work closely with our customers and the Bank’s coverage teams to ensure end-to-end solutions and the best possible experience. HBL is the only Pakistani Bank that provides online click-and-trade functionality for Foreign Exchange and Fixed Income markets. HBL Infinity® is a real-time Foreign Exchange Trading Platform (the first in the market) which currently provides our Corporate and Commercial customers with executable pricing. Our newly-launched HBL Symphony® platform is a real-time Fixed Income pricing and execution engine allowing our corporate and institutional clients with the speed and flexibility to manage their investments across a wide-range of products. Our client-focus is something we pride ourselves upon and we are cognizant of how our clients need clear and unbiased perspectives on the domestic and international economy to position their businesses accordingly. Sales and Structuring conducts seminars and roadshows for our clients across the country to ensure that they are informed of all developments that could impact their future decisions. We also publish media content on our HBL Website to cover important economic and regulatory developments to help our customers understand risks and opportunities as and when they occur.</p>
    </div>
    <p>The Structured Products Desk offers risk management and hedging solutions to clients and counterparties of the Bank. The Structured Products Desk is tasked with managing, designing and launching products that make use of derivatives as well as vanilla markets and instruments to create a specific set of risk-return profiles for clients. Structured Products aims not only to support the local client-facing businesses but also actively developing a suite of products for our foreign branches so that our customers across the network are able to leverage our expertise to unlock risk mitigation and return enhancement avenues for their businesses.</p>
    <div>
      <h4>INTERNATIONAL TREASURY</h4>
      <p>HBL is currently present in 11 locations outside Pakistan. As part of the strategy and focus on the International Businesses, HBL has implemented a matrix organizational structure whereby the Treasuries in the international locations report into the International Treasury desk in H.O. The objectives of this structure are to ensure that the desired support is available to the international locations from HBL head office and to allow the International Treasury network to benefit from the breadth and depth of cumulative experience at the head office. Acting as a hub for overseas treasuries, International Treasury governs and supervises both liquidity and treasury activities undertaken at HBL’s international locations and subsidiaries. International Treasury also serves the international locations with new products and initiatives that can be integrated in different business environments.</p>
    </div>
    <div>
      <h4>BUSINESS SUPPORT</h4>
      <p>Business Support is a vital area under TGM, responsible for providing support to all departments within Treasury & Global Markets for its smooth functioning to boost the efficiency and relationships of the treasury business.</p>
    </div>
    <p>Support services include IT management, administrative matters, budgeting, project management and other general business services within Treasury & Global Markets to facilitate the productivity of the various Treasury desks. The Business Support desk also provides support to TGM departments and International Center Treasuries in developing Policies, Procedures, SOPs and Product Programs, and to liaise and coordinate on Compliance and Audit issues. </p>
    <div>
      <h4>ISLAMIC TREASURY</h4>
      <p>Islamic Treasury is established as a separate “Branch” within the domain of Treasury and Global Markets to provide access to Shariah compliant Treasury products, as approved by the Bank’s Shariah Board. Islamic Treasury is responsible for managing the assets and liabilities of the Islamic Banking balance sheet, ensuring that the Bank's liquidity is effectively utilized through Shariah-Compliant products. Islamic Treasury acts as the front-end desk for money market and fixed income (Sukuks) transactions.</p>
    </div>
    <p>Additionally, the branch provides customers with Shariah compliant foreign exchange solutions. With the rollout of HBL Infinity® for Islamic Customers, our valued clients can now easily monitor exchange rates in fast moving markets and execute their transactions securely and efficiently. 
      Islamic Treasury deals in all Treasury products as approved by the Shariah Board.</p>
  </div>
</section>
<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functiontreasuryglobalmarkets