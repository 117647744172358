import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Campheader from "../../../../components/Campheader";
import Campheadermob from "../../../../components/Campheadermob";

const Campost37 = () => {
  const images = [
    "/images/theleague/mt/posts/37/1.jpg",
    "/images/theleague/mt/posts/37/2.jpg",
    "/images/theleague/mt/posts/37/3.jpg",
    "/images/theleague/mt/posts/37/4.jpg",
    "/images/theleague/mt/posts/37/5.jpg",
    "/images/theleague/mt/posts/37/6.jpg",
    "/images/theleague/mt/posts/37/7.jpg",
    "/images/theleague/mt/posts/37/8.jpg",
    "/images/theleague/mt/posts/37/9.jpg",
    "/images/theleague/mt/posts/37/10.jpg",
    "/images/theleague/mt/posts/37/11.jpg",
    "/images/theleague/mt/posts/37/12.jpg",
    "/images/theleague/mt/posts/37/13.jpg",
    "/images/theleague/mt/posts/37/14.jpg",
    "/images/theleague/mt/posts/37/15.jpg",
    "/images/theleague/mt/posts/37/16.jpg",
    "/images/theleague/mt/posts/37/17.jpg",
    "/images/theleague/mt/posts/37/18.jpg",
    "/images/theleague/mt/posts/37/19.jpg",
    "/images/theleague/mt/posts/37/20.jpg",
    "/images/theleague/mt/posts/37/21.jpg",
    "/images/theleague/mt/posts/37/22.jpg",
    "/images/theleague/mt/posts/37/23.jpg",
    "/images/theleague/mt/posts/37/24.jpg",
    "/images/theleague/mt/posts/37/25.jpg",
    "/images/theleague/mt/posts/37/26.jpg",
    "/images/theleague/mt/posts/37/27.jpg",
    "/images/theleague/mt/posts/37/28.jpg",
    "/images/theleague/mt/posts/37/29.jpg",
    "/images/theleague/mt/posts/37/30.jpg",
    "/images/theleague/mt/posts/37/31.jpg",
    "/images/theleague/mt/posts/37/32.jpg",
    "/images/theleague/mt/posts/37/33.jpg",
    
  ];
  return (
    <>
      <Helmet>
        <title>IBA Campus Drive 2020 | THE LEAGUE</title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
          <Campheader />
          <h1 className="my-3">IBA Campus Drive 2020</h1>
          <Lightbox images={images} />
          <Campheadermob />
        </div>
      </section>
    </>
  );
};

export default Campost37;
