import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Campheader from "../../../../components/Campheader";
import Campheadermob from "../../../../components/Campheadermob";

const Campost33 = () => {
  const images = [
    "/images/theleague/mt/posts/33/1.jpg",
    "/images/theleague/mt/posts/33/2.jpg",
    "/images/theleague/mt/posts/33/3.jpg",
    "/images/theleague/mt/posts/33/4.jpg",
    "/images/theleague/mt/posts/33/5.jpg",
    "/images/theleague/mt/posts/33/6.jpg",
    "/images/theleague/mt/posts/33/7.jpg",
    "/images/theleague/mt/posts/33/8.jpg",
    "/images/theleague/mt/posts/33/9.jpg",
    "/images/theleague/mt/posts/33/10.jpg",
    "/images/theleague/mt/posts/33/11.jpg",
    "/images/theleague/mt/posts/33/12.jpg",
    "/images/theleague/mt/posts/33/13.jpg",
    "/images/theleague/mt/posts/33/14.jpg",
    "/images/theleague/mt/posts/33/15.jpg",
    "/images/theleague/mt/posts/33/16.jpg",
    "/images/theleague/mt/posts/33/17.jpg",
    "/images/theleague/mt/posts/33/18.jpg",
    "/images/theleague/mt/posts/33/19.jpg",
    "/images/theleague/mt/posts/33/20.jpg",
    "/images/theleague/mt/posts/33/21.jpg",
    "/images/theleague/mt/posts/33/22.jpg",
    "/images/theleague/mt/posts/33/23.jpg",
    "/images/theleague/mt/posts/33/24.jpg",
    "/images/theleague/mt/posts/33/25.jpg",
    "/images/theleague/mt/posts/33/26.jpg",
    "/images/theleague/mt/posts/33/27.jpg",
    "/images/theleague/mt/posts/33/28.jpg",
  ];
  return (
    <>
      <Helmet>
        <title>
        LUMS Campus Drive 2020 | THE LEAGUE
        </title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
          <Campheader />
          <h1 className="my-3">LUMS Campus Drive 2020</h1>
          <Lightbox images={images} />
          <Campheadermob />
        </div>
      </section>
    </>
  );
};

export default Campost33;
