import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Campheader from "../../../../components/Campheader";
import Campheadermob from "../../../../components/Campheadermob";

const Campost32 = () => {
  const images = [
    "/images/theleague/mt/posts/32/1.jpg",
    "/images/theleague/mt/posts/32/2.jpg",
    "/images/theleague/mt/posts/32/3.jpg",
    "/images/theleague/mt/posts/32/4.jpg",
    "/images/theleague/mt/posts/32/5.jpg",
    "/images/theleague/mt/posts/32/6.jpg",
    "/images/theleague/mt/posts/32/7.jpg",
    "/images/theleague/mt/posts/32/8.jpg",
    "/images/theleague/mt/posts/32/9.jpg",
    "/images/theleague/mt/posts/32/10.jpg",
    "/images/theleague/mt/posts/32/11.jpg",
    "/images/theleague/mt/posts/32/12.jpg",
    "/images/theleague/mt/posts/32/13.jpg",
    "/images/theleague/mt/posts/32/14.jpg",
    "/images/theleague/mt/posts/32/15.jpg",
    "/images/theleague/mt/posts/32/16.jpg",
    "/images/theleague/mt/posts/32/17.jpg",
    "/images/theleague/mt/posts/32/18.jpg",
    "/images/theleague/mt/posts/32/19.jpg",
    "/images/theleague/mt/posts/32/20.jpg",
    "/images/theleague/mt/posts/32/21.jpg",
    "/images/theleague/mt/posts/32/22.jpg",
    "/images/theleague/mt/posts/32/23.jpg",
    "/images/theleague/mt/posts/32/24.jpg",
    "/images/theleague/mt/posts/32/25.jpg",
    "/images/theleague/mt/posts/32/26.jpg",
    "/images/theleague/mt/posts/32/27.jpg",
    "/images/theleague/mt/posts/32/28.jpg",
    "/images/theleague/mt/posts/32/29.jpg",
    "/images/theleague/mt/posts/32/30.jpg",
    "/images/theleague/mt/posts/32/31.jpg",
    "/images/theleague/mt/posts/32/32.jpg",
    "/images/theleague/mt/posts/32/33.jpg",
    "/images/theleague/mt/posts/32/34.jpg",
  ];
  return (
    <>
      <Helmet>
        <title>
        LSE Campus Drive 2020 | THE LEAGUE
        </title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
          <Campheader />
          <h1 className="my-3">LSE Campus Drive 2020</h1>
          <Lightbox images={images} />
          <Campheadermob />
        </div>
      </section>
    </>
  );
};

export default Campost32;
