import React from 'react';

console.clear();

const Footer = () => {
  return (<>
<div className="footer-wid">
  <div className="container">
    <div className="row row-cols-1 row-cols-md-3 row-cols-lg-5">
      <div className="col">
        <h2>EXPLORE HBL</h2>
        <ul>
          <li><a target="_blank"  rel="noreferrer noopener" href="https://www.hbl.com/history">History</a></li>
          <li><a target="_blank"  rel="noreferrer noopener" href="https://www.hbl.com/boardofdirector">Board of Directors</a></li>
          <li><a target="_blank"  rel="noreferrer noopener" href="https://www.hbl.com/codeofethics">Code of Ethics</a></li>
          <li><a href="/function">Function</a></li>
        </ul>
      </div>
      <div className="col">
        <h2>Ways to Join Us</h2>
        <ul>
          <li><a href="/experienced-professionals">Experienced Professionals</a></li>
          <li><a href="/fresh-graduates">Fresh Graduates</a></li>
          <li><a href="/the-league">The League</a></li>
        </ul>
      </div>
      <div className="col">
        <h2>Life at HBL</h2>
        <ul>
          <li><a href="/benefits">Benefits and Sustenance</a></li>
          <li><a href="/culture">Our Culture</a></li>
          <li><a href="/impact">Impact Through Innovation</a></li>
          <li><a href="/diversity">Diversity, Equity and Inclusion</a></li>
          <li><a href="/learning">Learning &amp; Development</a></li>
          <li><a href="/footprint">Global Footprint</a></li>
        </ul>
      </div>
      <div className="col">
        <h2>Quick Links</h2>
        <ul>
          <li><a href="https://www.hblpeople.com">Home</a></li>
          <li><a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">Apply Now</a></li>
        </ul>
      </div>
      <div className="col">
        <h2>Connect with Us</h2>
        <ul className="social">
          <li><a href="https://www.facebook.com/HBLBank" target="_blank" rel="noreferrer noopener"><img src="/images/s-fb.png" alt="HBL People" className='a'/><img src="/images/f-hover.png" className='b' alt="HBL People" /></a></li>
          <li><a href="https://twitter.com/HBLPak" target="_blank" rel="noreferrer noopener"><img src="/images/s-tw.png"  className='a' alt="HBL People" /><img src="/images/tw-hover.png" className='b' alt="HBL People" /> </a></li>
          <li><a href="https://www.youtube.com/channel/UCGPhQ5ZcZEwBflu-vqXK23w" target="_blank" rel="noreferrer noopener"><img src="/images/s-y.png"  alt="HBL People"  className='a'/> <img src="/images/y-hover.png" className='b' alt="HBL People" /> </a></li>
          <li><a href="https://www.linkedin.com/company/1869454?trk=vsrp_companies_res_pri_act&amp;trkInfo=VSRPsearchId%3A508418421440402619928%2CVSRPtargetId%3A1869454%2CVSRPcmpt%3Aprimary" target="_blank" rel="noreferrer noopener"><img src="/images/s-in.png"  className='a' alt="HBL People" /> <img src="/images/in-hover.png" className='b' alt="HBL People" /> </a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
<footer>
  <div className="container">
    <div className="row">
      <div className="col-md-6 text-center text-md-start"> <a href="https://www.hbl.com/privacy-protocol" target="_blank"  rel="noreferrer noopener">Privacy policy </a> | <a href="https://www.hbl.com/disclaimer" target="_blank"  rel="noreferrer noopener">Disclaimer</a> </div>
      <div className="col-md-6 text-center text-md-end"> © 2024 HBL All Rights Reserved </div>
    </div>
  </div>
</footer>

    </>)
}

export default Footer