import React from 'react'

const Intheadermob = () => {
  return (
    <>
    <div className="row d-flex d-lg-none text-center my-3">
            <div className="col">
            <a href="/the-league/internship" className="rthome">
                <img src="/images/theleague/innerpage-back-button-arrow.png" /> Internship
              </a>
            </div>
            <div className="col ">
              <a href="/the-league/" className="rthome">
                Return to Home <img src="/images/theleague/innerpage-forward-button-arrow.png" />
              </a>
            </div>
          </div>
    </>
  )
}

export default Intheadermob