import React from 'react';
import { Helmet } from "react-helmet";

const Benefits = () => {
  return (
    <>
    <Helmet>
<title>Benefits and Sustenance | HBL People</title>
<meta
name="description"
content="Perks of being part of THE HBL Family"
/>
</Helmet>
     <section className="innerpagebanner benefits">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top left 0.3s and move 65px wait 1s">Benefits and Sustenance</h1>
      <h2 data-sr="enter top left 0.3s and move 65px wait 1.2s">Perks of being part of THE HBL Family </h2>
    </div>
  </div>
</section>
<section className="innersection benefits">
  <div className="container">
    <div className="text-center">
      <h3 className="web" data-sr="enter top left 0.3s and move 65px wait .8s">REWARDS</h3>
      <h2 className="web" data-sr="enter top left 0.3s and move 65px wait 1s">Empower Your Future with HBL</h2>
      <p data-sr="enter top left 0.3s and move 65px wait .5s">Our People are at heart of everything that we do. HBL’s spending on social and developmental causes is the highest of any Bank in the country. From stepping up to support the people of Pakistan during the global pandemic and floods to efforts for sustainable development; our endeavors underpin our belief in being a “Bank with a Soul”. HBL continues to extend support to an array of public and private-sector projects and organizations, with a focus on community development</p>
    </div>
  </div>
</section>
<section className="innersection benefits">
  <div className="container">
    <div className="text-center">
      <h3 className="web" data-sr="enter top left 0.3s and move 65px wait .8s">EMPLOYEE BENEFITS</h3>
      <h2 className="web" data-sr="enter top left 0.3s and move 65px wait 1s">Secure Your Tomorrow , Today</h2>
      <p data-sr="enter top left 0.3s and move 65px wait .5s">We offer comprehensive employee benefits and value addition to our HBL Family to help you take charge of your Present and Future. </p>
    </div>
  </div>
</section>
<section>
  <div className="containerfluid">
    <div className="row g-0">
      <div className="col-lg-6">
        <div className="hblserviceage">
          <div className="in">
            <h4 className="text-uppercase">HBL Service Age</h4>
            <p className="fw-light">HBL as an industry leader raised its service age to 65 years to support its long-serving staff, who could still contribute a few more years after their retirement and continue to financially support their families. Their experience would serve our clients and also provide mentoring to develop our younger staff.</p>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="makeadifference">
          <div className="in">
            <h4 className="text-uppercase">Make a Difference</h4>
            <p className="fw-light">As responsible individuals we believe in also sharing and giving back to the community to make this world a better place. HBL also has an Employee Volunteering Program that enables HBL family to participate in any mission of their choice to serve the community. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="innersection benefits">
  <div className="container">
    <div className="gridspace text-center mb-5">
      <div className="text-center">
        <h3 className="web" data-sr="enter top left 0.3s and move 65px wait .8s">Empower Your Future with HBL</h3>
        <h2 className="web" data-sr="enter top left 0.3s and move 65px wait 1s">EMPLOYEE BENEFITS</h2>
      </div>
      <p data-sr="enter top left 0.3s and move 65px wait .5s">HBL is an equal opportunity employer and aims to provide a workplace free from unlawful discrimination where employment and advancement opportunities are equitably accessible to all based on ability, merit and qualification.</p>
      <p data-sr="enter top left 0.3s and move 65px wait .8s">Using a systematic compensation and benefits survey, we provide fair, equitable and competitive reward and benefit structure to our employees. Here’s an overview of some of the benefits that HBL offers to its employees: </p>
    </div>
    <div className="benefitstab bottom">
      <div data-sr="enter top left 0.3s and move 65px wait .3s">
        <div className="bgimagea">
          <div>
            <h3>Flexible Working Environment</h3>
          </div>
        </div>
      </div>
      <div data-sr="enter top left 0.3s and move 65px wait .6s">
        <div className="bgimageb">
          <div>
            <h3>Senior Employee Benefits</h3>
          </div>
        </div>
      </div>
      <div data-sr="enter top left 0.3s and move 65px wait .9s">
        <div className="bgimagec">
          <div>
            <h3>Paid Leaves</h3>
          </div>
        </div>
      </div>
      <div data-sr="enter top left 0.3s and move 65px wait 1.2s">
        <div className="bgimaged">
          <div>
            <h3>Career Development Programs</h3>
          </div>
        </div>
      </div>
    </div>
    <div className="benefitstab mcontent my-4">
      <div data-sr="enter left 0.3s and move 65px wait .3s">
        <h5>Scholarships, Facilitation & Development Opportunities</h5>
        <ul>
          <li>Scholarship facility for children</li>
          <li>Retirement benefits</li>
          <li>Subsidized Loan facility for Car, House and solar energy solutions</li>
          <li>Day Care Allowance for Female Staff</li>
          <li>Inhouse ‘HBL Share Sawari’</li>
        </ul>
      </div>
      <div data-sr="enter left 0.3s and move 65px wait .6s">
        <h5>Coaching & Career Progression</h5>
        <ul>
          <li>Career development opportunities</li>
          <li>Employee Recognition Awards</li>
          <li>Club Membership Facility</li>
        </ul>
      </div>
      <div data-sr="enter left 0.3s and move 65px wait .9s">
        <h5>Health & Wellbeing Tools</h5>
        <ul>
          <li>Health and Medical coverage</li>
          <li>Leave Purchase facility</li>
          <li>Mental Health & Well being Programs</li>
          <li>24/7 Digital Medical Consultancy</li>
        </ul>
      </div>
      <div data-sr="enter left 0.3s and move 65px wait 1.2s">
        <h5>Insurance & Paid Leaves</h5>
        <ul>
          <li> Life and Accidental Insurances</li>
          <li>Pay Continuation Plan in case of death</li>
          <li>Maternity, Paternity and other recreation leaves.</li>
        </ul>
      </div>
    </div>
    <div className="benefitstab top">
      <div data-sr="enter bottom left 0.3s and move 65px wait .3s">
        <div className="bgimagee">
          <div>
            <h3>Insurance and Healthcare</h3>
          </div>
        </div>
      </div>
      <div data-sr="enter bottom left 0.3s and move 65px wait .6s">
        <div className="bgimagef">
          <div>
            <h3>Well Being Programs</h3>
          </div>
        </div>
      </div>
      <div data-sr="enter bottom left 0.3s and move 65px wait .9s">
        <div className="bgimageg">
          <div>
            <h3>Scholarship Facilities</h3>
          </div>
        </div>
      </div>
      <div data-sr="enter bottom left 0.3s and move 65px wait 1.2s">
        <div className="bgimage lastdiv">
          <h4>Join HBL today and explore a world of equitable benefits</h4>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="innersection benefits cultureandvalues">
  <div className="container">
    <div className="text-center mb-5">
      <h3 className="web" data-sr="enter top left 0.3s and move 65px wait .8s">CULTURE AND VALUES</h3>
      <h2 className="web" data-sr="enter top left 0.3s and move 65px wait 1s">The Most Inclusive Organization</h2>
      <p data-sr="enter top left 0.3s and move 65px wait .5s">HBL’s Culture and Values make us unique. As an institution, HBL aims to empower its employees through facilities, infrastructure and growth opportunities while employees must comply with certain obligations toward the Bank for a mutually beneficial relationship.</p>
    </div>
    <div className="row">
      <div className="col-lg-4">
        <div className="in"> <img src="images/benefits/peoplecentric.jpg" className="img-fluid" alt=""/>
          <h4 className="mt-2 mb-1">People Centric</h4>
          <p>We celebrate our inclusive yet diverse culture where employees are recognized based on their performance and believe in providing equal opportunity for all without any discrimination to race, religion, disability or gender. Employees’ well-being is essential to the Bank, and we believe in engaging employees to create a sense of belongingness. We encourage employees to retain their uniqueness which enhances one's overall motivation, health, and happiness.</p>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="in"> <img src="images/benefits/pwd.jpg" className="img-fluid" alt=""/>
          <h4 className="mt-2 mb-1">Person with Disabilities (PWD)</h4>
          <p>HBL values Persons with disabilities (PWDs) as part of our culture and has created friendly workspaces for them to facilitate their working. A special one-time grant to purchase assisted devices also exists that facilitates their purchase in case of need. We are working with different organizations to include and empower PWDs as HBL’s customers. This makes HBL one of the first disability-inclusive institutions in the industry.</p>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="in"> <img src="images/benefits/treatingfairly.jpg" className="img-fluid" alt=""/>
          <h4 className="mt-2 mb-1">Treating Fairly</h4>
          <p>HBL acknowledges women as equal partners in economic growth and values their contribution to the workforce. Structured efforts over the past few years have resulted in an increase in the women workforce and customers of the Bank. We provide equal opportunities for advancement and success. In line with our diversity agenda, we also have a flagship Female Employee Returnship Program. This provides employment opportunities for women who had taken career breaks and want to rejoin after a long gap in their professional life. HBL would continue such initiatives to drive the financial inclusion of women in Pakistan</p>
        </div>
      </div>
    </div>
  </div>
</section> 
    </>
  )
}

export default Benefits
