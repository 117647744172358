import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functionremedialstructuredcredit = () => {
  return (
    <>
<Helmet>
<title>Remedial Assets and Structured Credits | HBL People</title>
<meta
name="description"
content="Helping Businesses to Rehab &amp; Recover through Innovation"
/>
</Helmet>
<section className="innerpagebanner remedial-structured-credit">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Remedial Assets and Structured Credits</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Helping Businesses to Rehab &amp; Recover through Innovation</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages remedial-structured-credit">
  <div className="container d-flex flex-wrap gap-3">
    <p> HBL Structured Credits and Remedial Assets Group (SC &amp; RA) comprises of two distinct specialized units – both applying a different approach to achieve a common objective of addressing the stressed/complex portfolio of the Bank. The functions performed by these units are more specifically elaborated below:</p>
    <ul>
      <li>Structured Credits deals with diverse range of portfolio comprising of regular yet complex / sensitive accounts that needs to be proactively managed to timely offset the early signs of portfolio stress, restructuring to rehabilitate the non performing relationships, non-traditional work outs not limited to Debt to Asset Swaps as well as expertise in leading the Syndicate Restructurings. Staff for Structured Credits represents diverse skill set with a good blend of work experience covering Commercial/ Corporate Banking &amp; Risk Management.</li>
      <li>Remedial Assets involves negotiations with clients to settle distressed / litigated exposures. This unit includes a deep understanding of the legal and recovery framework of Pakistan as well as timely liquidation through various methods.</li>
    </ul>
  </div>
</section>

<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functionremedialstructuredcredit
