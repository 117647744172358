import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Campheader from "../../../../components/Campheader";
import Campheadermob from "../../../../components/Campheadermob";

const Campost40 = () => {
  const images = [
    "/images/theleague/mt/posts/40/1.jpg",
    "/images/theleague/mt/posts/40/2.jpg",
    "/images/theleague/mt/posts/40/3.jpg",
    "/images/theleague/mt/posts/40/4.jpg",
    "/images/theleague/mt/posts/40/5.jpg",
    "/images/theleague/mt/posts/40/6.jpg",
    "/images/theleague/mt/posts/40/7.jpg",
    "/images/theleague/mt/posts/40/8.jpg",
    "/images/theleague/mt/posts/40/9.jpg",
    "/images/theleague/mt/posts/40/10.jpg",
    "/images/theleague/mt/posts/40/11.jpg",
    "/images/theleague/mt/posts/40/12.jpg",
    "/images/theleague/mt/posts/40/13.jpg",
    "/images/theleague/mt/posts/40/14.jpg",
    "/images/theleague/mt/posts/40/15.jpg",
    "/images/theleague/mt/posts/40/16.jpg",
    "/images/theleague/mt/posts/40/17.jpg",
    "/images/theleague/mt/posts/40/18.jpg",
    "/images/theleague/mt/posts/40/19.jpg",
    "/images/theleague/mt/posts/40/20.jpg",
    "/images/theleague/mt/posts/40/21.jpg",
    "/images/theleague/mt/posts/40/22.jpg",
    "/images/theleague/mt/posts/40/23.jpg",
    "/images/theleague/mt/posts/40/24.jpg",
    "/images/theleague/mt/posts/40/25.jpg",
    "/images/theleague/mt/posts/40/26.jpg",
    "/images/theleague/mt/posts/40/27.jpg",
    "/images/theleague/mt/posts/40/28.jpg",
    "/images/theleague/mt/posts/40/29.jpg",
    "/images/theleague/mt/posts/40/30.jpg",
    "/images/theleague/mt/posts/40/31.jpg",
    "/images/theleague/mt/posts/40/32.jpg",
    "/images/theleague/mt/posts/40/33.jpg",
    "/images/theleague/mt/posts/40/34.jpg",
    "/images/theleague/mt/posts/40/35.jpg",
    "/images/theleague/mt/posts/40/36.jpg",
    
  ];
  return (
    <>
      <Helmet>
        <title>Cohort 2021 Karachi Get-together | THE LEAGUE</title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
          <Campheader />
          <h1 className="my-3">Cohort 2021 Karachi Get-together</h1>
          <Lightbox images={images} />
          <Campheadermob />
        </div>
      </section>
    </>
  );
};

export default Campost40;
