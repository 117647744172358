import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functionretailbanking = () => {
  return (
    <>
     <Helmet>
     <title>Retail Banking | HBL People</title>
<meta
name="description"
content="Your Catalyst for success"
/>

            </Helmet>
      <section className="innerpagebanner branch-banking">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Retail Banking</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Your Catalyst for success</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages branch-banking">
  <div className="container d-flex flex-wrap gap-3">
    <p>Welcome to HBL's Retail Banking Group, the powerhouse of the Bank, renowned for its expansive reach across Pakistan with over 1,700 branches and 2,300 ATMs. It is one of the core functions driving business. This dynamic group includes the following seven key divisions functions:  </p>
    <ul>
      <li>Affluent Segment & Retail Products</li>
      <li>Mass Affluent</li>
      <li>Growth & Productivity</li>
      <li>National Sales</li>
      <li>Retail Business</li>
      <li>Client Experience & Mass Segment</li>
      <li>Branch Operations & Retail Operational Risk and Islamic Banking</li>
    </ul>
    <div>
      <h4>Affluent Segment & Retail Products</h4>
      <p>As a member of the Retail Products Team, you will find yourself at the forefront of bringing visionary products and propositions to life, from their conceptualization to their triumphant entry into the market, and beyond.  By joining the Bank, you will not only develop invaluable skills and expertise in your chosen field, but also immerse yourself in a dynamic and innovative work environment. Here, you will collaborate with industry experts, exchange ideas, and learn from the brightest minds in the business. </p>
    </div>
    <p>At HBL, Retail Products has 5 sub-functions including HBL Prestige, Premium Strategy, Wealth Management, Women Marketing Program & Non-Resident Pakistani Banking, and Liability Products.</p>
    <div>
      <p><strong>HBL Prestige</strong></p>
      <p>HBL Prestige is a value proposition designed to provide HBL’s High Net Worth clients with a world class banking experience. With Prestige’s expanding footprint nationwide, you will learn to navigate the intricate world of high-net-worth individuals, enhance your skills in relationship management and tailored financial solutions and wealth preservation, backed by accelerated career growth. </p>
    </div>
    <div>
      <p><strong>Liability Products, Wealth Management and Women Marketing Program & Non-Resident Pakistani Banking</strong></p>
      <p>The Liability Product team plays a pivotal role in driving growth, ensuring compliance, and maximizing profitability through the development and management of deposit products, market research, and performance analysis. Furthermore, the Wealth Management team empowers clients with a diverse range of Savings, Protection, and Investment options, equipping them with the tools to strengthen their financial well-being and unlock the full potential for maximum returns. Additionally, HBL's unwavering commitment to financial inclusion and empowerment is exemplified through The Women Market Program team and its innovative product suite Nisa, which tirelessly strives to integrate the vast unbanked female population into the banking system, fostering a transformative environment of inclusivity and empowerment.</p>
      <p>By embracing the opportunity to be a part of these exceptional teams, you will be empowered to ignite transformative change, uplift clients, and wield your expertise to shape the future of finance. </p>
    </div>
    <div>
      <h4>Growth & Productivity </h4>
      <p>The Growth and Productivity team, fosters data-led business decisions, identifies opportunities for growth in the distribution, manages performance management, and leverages state-of-the-art in-house built technology for their processes. The Banks’ Process Improvement function work on improving existing policies, processes, and customer experiences. As part of this team, you will be able to access HBL’s advanced analytics programs that utilize artificial intelligence, spatial analytics, power BI tools, and propensity modelling tools, to enhance sales management initiatives. This unit drives Segment Enablement, Performance and Cost Management, Analytics and Channel Optimization. </p>
    </div>
    <div>
      <h4>National Sales</h4>
      <p>As a part of HBL’s National Sales function, you will be dedicated to driving business development and sales mobilization strategies including but not limited to Business Sales Executive, a dedicated salesforce for Deposits. </p>
    </div>
    <div>
      <h4>Retail Business </h4>
      <p>The Distribution network encompasses 1,700+ branches, 5 clusters, 14 regions and 140 areas, 14 with each cluster managed by a General Manager. The Bank’s teams also consist of Regional Retail Heads, Regional Retail Operations & Risk Heads and Regional Managers for Retail & Retail Operational Risk. As a member of this function, you will be responsible for delivering non-business and business objectives of the Bank, along with driving sales for Deposit, Wealth, Consumer, Trade, SME, and Agriculture Banking products.</p>
    </div>
    <div>
      <h4>Client Experience & Mass Segment</h4>
      <p>The quality of HBL’s services is defined by ‘It’s’s customers’ satisfaction’. The satisfaction in turn is built on customers’ experiences and interactions with the Bank. Delivering great experiences for its customers across all channels is one of the key values of HBL. In an intensely competitive environment with both local and international banks playing an active role, the customer expects superior service from its financial partner. HBL’s ability to offer unrivalled experiences hinges on knowing the precise needs of the customers. </p>
      </div>
      <p>Client Experience ensures that theBank is consistently meeting or exceeding customer expectations by measuring, managing, and improving customer experiences along with Digital Customer Experience, which comprises of several units; Digital Channel Development, Contact Center, Inbound, IVR, Voice, Emails, Webchat, RDA Services, Outbound and Social Media Services to provide exemplary services to  its r customers. As a department, HBL strives  to continuously provide the best-in-class services to its customers through different mediums and ensure that customers avail the best services. </p>
      <p>Join HBL and become a part of its Retail Banking Group, where you will enhance your skills, foster innovation, and shape the future of banking in Pakistan!</p>
    
    <div>
      <h4>Branch Operations & Retail Operational Risk</h4>
      <p>Branch Operations & Retail Operational Risk leads the compliance, risk, control, and regulatory initiatives of the Retail Banking group and ensures that business activities are conducted in compliance with HBL and State Bank’s regulations and policies. They facilitate representation and implementation of all action items from Board and Management Committees. As a member of this team, you will be responsible for ensuring that all business activities are carried out in a compliant manner by establishing the right control environment. With a focus on supporting business teams, you will help to interpret the regulatory and internal control environment for products, processes, and procedures. </p>
    </div>
  </div>
</section>
<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functionretailbanking
