import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Intheader from "../../../../components/Intheader";
import Intheadermob from "../../../../components/Intheadermob";

const Intpost9 = () => {
  const images = [
    "/images/theleague/internship/posts/9/1.jpg",
    "/images/theleague/internship/posts/9/2.jpg",
    "/images/theleague/internship/posts/9/3.jpg",
    "/images/theleague/internship/posts/9/4.jpg",
    "/images/theleague/internship/posts/9/5.jpg",
    "/images/theleague/internship/posts/9/6.jpg",
    "/images/theleague/internship/posts/9/7.jpg",
    "/images/theleague/internship/posts/9/8.jpg",
    "/images/theleague/internship/posts/9/9.jpg",
    "/images/theleague/internship/posts/9/10.jpg",
    "/images/theleague/internship/posts/9/11.jpg",
    "/images/theleague/internship/posts/9/12.jpg",
    "/images/theleague/internship/posts/9/13.jpg",
    "/images/theleague/internship/posts/9/14.jpg",
  ];

  return (
    <>
      <Helmet>
        <title>
          The League Internship Programme 2018: Closing Ceremony | THE LEAGUE
        </title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
          <Intheader />
          <h1 className="my-3">
            The League Internship Programme 2018: Closing Ceremony
          </h1>
          <p>
            Internship Programme ended with a closing ceremony in Karachi,
            Lahore and Islamabad. The progamme gave an opportunity to intern to
            gain invaluable business exposure, develop critical skills and build
            a professional network.
          </p>
          <Lightbox images={images} />
          <Intheadermob />
        </div>
      </section>
    </>
  );
};

export default Intpost9;
