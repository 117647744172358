import React from "react";
import Lightbox from "../../../../components/Lightbox";
import { Helmet } from "react-helmet";
import Intheader from "../../../../components/Intheader";
import Intheadermob from "../../../../components/Intheadermob";

const Intpost7 = () => {
  const images = [
    "/images/theleague/internship/posts/7/1.jpg",
    "/images/theleague/internship/posts/7/2.jpg",
    "/images/theleague/internship/posts/7/3.jpg",
    "/images/theleague/internship/posts/7/4.jpg",
    "/images/theleague/internship/posts/7/5.jpg",
    "/images/theleague/internship/posts/7/6.jpg",
    "/images/theleague/internship/posts/7/7.jpg",
    "/images/theleague/internship/posts/7/8.jpg",
    "/images/theleague/internship/posts/7/9.jpg",
    "/images/theleague/internship/posts/7/10.jpg",
    "/images/theleague/internship/posts/7/11.jpg",
  ];

  return (
    <>
      <Helmet>
        <title>
          The League Internship Programme 2018: Karachi Beach Cleaning | THE
          LEAGUE
        </title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
        <link rel="stylesheet" href="/css/lightbox.css" />
      </Helmet>
      <section className="whyhbl-bg singl-p">
        <div className="container">
          <Intheader />
          <h1 className="my-3">
            The League Internship Programme 2018: Karachi Beach Cleaning
          </h1>
          <p>
            Our Karachi-based interns participated in a beach clean-up activity
            to support our Corporate Social Responsibility initiative in
            collaboration with Heal Aid Foundation and McDonald’s Pakistan. They
            competed amongst each other to meet the goal of collecting maximum
            recyclable waste. You would be amazed to know how much difference
            you can make to your environment by investing just an hour.
          </p>
          <Lightbox images={images} />
          <Intheadermob />
        </div>
      </section>
    </>
  );
};

export default Intpost7;
